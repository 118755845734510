define("ember-svg-jar/inlined/notification-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.366 5.351a5.526 5.526 0 0111.045 0l.062 1.965c.002.071.025.14.064.199l.946 1.396c.681 1.005.059 2.388-1.169 2.507-1.493.145-3.737.331-5.425.331-1.689 0-3.932-.186-5.425-.331C.235 11.299-.386 9.916.295 8.911l.945-1.396.503.341-.503-.341a.367.367 0 00.064-.198l.062-1.966zm5.523-4.102A4.275 4.275 0 002.616 5.39l-.062 1.966c-.01.308-.107.606-.279.861l-.946 1.395a.357.357 0 00.256.562c1.49.144 3.681.325 5.304.325 1.622 0 3.813-.181 5.304-.325a.356.356 0 00.255-.562l-.945-1.396a1.613 1.613 0 01-.279-.86l-.062-1.966a4.275 4.275 0 00-4.273-4.141zm-.965 12.423c.241.21.586.327.965.327s.724-.117.964-.327c.227-.198.411-.52.411-1.048h1.25c0 .856-.316 1.534-.84 1.99-.509.444-1.164.635-1.785.635s-1.276-.191-1.786-.635c-.523-.456-.839-1.134-.839-1.99h1.25c0 .528.184.85.41 1.048z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 13.75 15.25",
      "width": "13.75",
      "height": "15.25"
    }
  };
  _exports.default = _default;
});