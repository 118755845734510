define("ember-svg-jar/inlined/xInCircleThick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(1.87 2)\" stroke=\"#FFF\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\"><ellipse stroke-width=\"2.2\" stroke-linejoin=\"round\" cx=\"11.027\" cy=\"11\" rx=\"11.027\" ry=\"11\"/><g stroke-width=\"1.833\"><path d=\"M14.268 7.76l-6.481 6.48M14.277 14.249L7.778 7.751\"/></g></g>",
    "attrs": {
      "width": "25",
      "height": "26",
      "viewBox": "0 0 25 26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});