define("@clarksource/breakpoints/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "smallest": 375,
    "small": 450,
    "medium": 730,
    "big": 768,
    "large": 1000,
    "largest": 1200
  };
  _exports.default = _default;
});