define("ember-svg-jar/inlined/unfall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Budicon-Shady/Medical/bandage</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M37.107 10.674L14.4 33.383a.741.741 0 01-1.048 0l-8.858-8.857-2.676 2.676a.74.74 0 000 1.048l9.933 9.933a.741.741 0 001.049 0l25.384-25.385a.742.742 0 000-1.048l-1.076-1.076z\" fill=\"#BFD2F5\"/><path d=\"M39.314 10.619L29.381.686a2.341 2.341 0 00-3.31 0L.685 26.07a2.341 2.341 0 000 3.311l9.933 9.933a2.341 2.341 0 003.31 0L39.315 13.93a2.341 2.341 0 000-3.311zm-1.131 2.18L12.799 38.182a.741.741 0 01-1.049 0L1.817 28.25a.74.74 0 010-1.048L27.201 1.817a.741.741 0 011.049 0l9.933 9.933c.289.29.289.759 0 1.048z\" fill=\"#2B6CDE\"/><circle fill=\"#2B6CDE\" transform=\"rotate(-44.998 20 15.475)\" cx=\"20\" cy=\"15.475\" r=\"1.6\"/><circle fill=\"#2B6CDE\" transform=\"rotate(-45.002 24.525 20)\" cx=\"24.525\" cy=\"20\" r=\"1.6\"/><circle fill=\"#2B6CDE\" transform=\"rotate(-45.002 15.475 20)\" cx=\"15.475\" cy=\"20\" r=\"1.6\"/><circle fill=\"#2B6CDE\" transform=\"rotate(-44.998 20 24.526)\" cx=\"20\" cy=\"24.526\" r=\"1.6\"/><path d=\"M39.314 26.07l-4.966-4.966-1.104 1.104 4.966 4.967a.781.781 0 010 1.104l-9.933 9.933a.78.78 0 01-1.104 0l-4.966-4.967-1.104 1.104 4.966 4.967a2.341 2.341 0 003.311 0l9.934-9.933a2.341 2.341 0 000-3.313zM5.652 18.896l1.104-1.104-4.967-4.966a.781.781 0 010-1.104l9.933-9.933a.78.78 0 011.104 0l4.967 4.967 1.104-1.104L13.93.686a2.341 2.341 0 00-3.311 0L.686 10.619a2.341 2.341 0 000 3.31l4.966 4.967z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});