define("ember-svg-jar/inlined/check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.336.26a.75.75 0 010 1.06l-5.5 5.5a.75.75 0 01-1.061 0l-2.5-2.5a.75.75 0 111.06-1.06l1.97 1.97L8.275.26a.75.75 0 011.06 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 10 8",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});