define("ember-svg-jar/inlined/tickCircled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(1 1.143)\" fill=\"none\" fill-rule=\"evenodd\"><ellipse stroke=\"#2B6CDE\" stroke-width=\"1.741\" fill=\"#2B6CDE\" class=\"svg-cta-stroke svg-cta-fill\" cx=\"9.711\" cy=\"9.429\" rx=\"9.711\" ry=\"9.429\"/><path stroke=\"#FFF\" stroke-width=\"2.09\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M5.976 10.854l2.317 2.292 4.966-5.893\"/></g>",
    "attrs": {
      "width": "22",
      "height": "21",
      "viewBox": "0 0 22 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});