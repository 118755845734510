define("ember-svg-jar/inlined/dollarbag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#535353\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M31.38 14.301L34.332 1s-6.908 4.418-9.53 4.418C21.87 5.418 17.666 1 17.666 1l2.954 13.301c1.732-.701 3.532-1.07 5.379-1.07 1.847 0 3.647.369 5.38 1.07z\"/><path d=\"M51 59.373C51 33.889 39.807 13.23 26 13.23S1 33.89 1 59.373h50zM32.07 13.593s3.714-5.749 8.496-5.749M47.136 10.219s-2.409 4.82-15.136 3.58M28.778 34.356a3.334 3.334 0 10-3.334 3.335M25.444 44.363v2.223M25.444 28.796v2.224M22.111 41.027a3.334 3.334 0 103.333-3.336\"/></g>",
    "attrs": {
      "width": "52",
      "height": "61",
      "viewBox": "0 0 52 61",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});