define("ember-svg-jar/inlined/balloon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.003 5.316a.6.6 0 01.678-.51 3.433 3.433 0 012.91 2.906.6.6 0 01-1.188.17 2.233 2.233 0 00-1.89-1.888.6.6 0 01-.51-.678z\"/><path d=\"M6 8.4a6 6 0 0112 0 9.976 9.976 0 01-1.917 5.83c-.95 1.29-2.205 2.27-3.483 2.512v.058a1.2 1.2 0 001.2 1.2h3a2.4 2.4 0 012.4 2.4v.6a.6.6 0 01-1.2 0v-.6a1.2 1.2 0 00-1.2-1.2h-3a2.4 2.4 0 01-2.4-2.4v-.057c-1.278-.242-2.533-1.22-3.483-2.51A9.974 9.974 0 016 8.4zm6-4.8a4.8 4.8 0 00-4.8 4.8c0 1.964.702 3.79 1.683 5.121C9.884 14.88 11.078 15.6 12 15.6c.922 0 2.116-.722 3.117-2.081A8.775 8.775 0 0016.8 8.4 4.8 4.8 0 0012 3.6z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});