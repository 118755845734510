define("ember-svg-jar/inlined/new-world-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M15.5 10a5.5 5.5 0 110 11 5.5 5.5 0 010-11zM10.002 0c5.523 0 10.001 4.478 10.001 10.002 0 .263-.01.524-.03.782a6.518 6.518 0 00-1.476-1.053l.004.177-.008-.318A8.493 8.493 0 0018.266 8l-.014.001h-3.344c.032.335.057.675.073 1.02a6.429 6.429 0 00-1.491.296c-.015-.451-.046-.89-.091-1.316H6.605a18.966 18.966 0 00.135 5h2.758c-.198.475-.341.977-.422 1.5H7.063c.598 2.216 1.602 3.735 2.655 3.97.283.551.643 1.056 1.066 1.502-.258.02-.52.03-.782.03C4.478 20.003 0 15.525 0 10.002 0 4.478 4.478 0 10.002 0zM15.5 12l-.09.007a.5.5 0 00-.402.402L15 12.5V15L12.498 15l-.09.008a.5.5 0 00-.402.402l-.008.09.008.09a.5.5 0 00.402.402l.09.008H15v2.503l.008.09a.5.5 0 00.402.402l.09.008.09-.008a.5.5 0 00.402-.402l.008-.09V16l2.504.001.09-.008a.5.5 0 00.402-.402l.008-.09-.008-.09a.5.5 0 00-.403-.402l-.09-.008H16v-2.5l-.008-.09a.5.5 0 00-.402-.403L15.5 12zm-9.99 2.502H2.787a8.531 8.531 0 004.095 3.41c-.58-.91-1.048-2.076-1.372-3.41zM1.737 8l-.004.017a8.525 8.525 0 00-.233 1.984c0 1.056.193 2.067.545 3h3.173a20.3 20.3 0 01-.218-3c0-.684.032-1.354.095-2.001H1.737zm5.146-5.91l-.023.008a8.531 8.531 0 00-4.608 4.402H5.3c.313-1.752.86-3.278 1.583-4.41zm3.119-.591l-.116.005C8.62 1.621 7.398 3.623 6.83 6.501h6.343C12.588 3.535 11.308 1.5 10.002 1.5zm3.12.59l.106.175c.67 1.112 1.178 2.572 1.475 4.237h3.048a8.531 8.531 0 00-4.63-4.411z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "35",
      "height": "35",
      "viewBox": "0 0 35 35",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});