define("ember-svg-jar/inlined/customer-satisfaction-rate-4", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5zm0-1.5a9 9 0 100-18 9 9 0 000 18z\" fill=\"#0439D7\"/><ellipse cx=\"9.002\" cy=\"8.775\" rx=\"1.5\" ry=\"1.725\" transform=\"rotate(5 9.002 8.775)\" fill=\"#0439D7\"/><ellipse cx=\"14.998\" cy=\"8.774\" rx=\"1.5\" ry=\"1.725\" transform=\"rotate(-5 14.998 8.774)\" fill=\"#0439D7\"/><path d=\"M7.5 13.5c1.173 2.428 2.397 3 4.5 3s3.327-.572 4.5-3\" stroke=\"#0439D7\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});