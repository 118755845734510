define("ember-svg-jar/inlined/rejectOffer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g stroke=\"#D6D6D6\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M43 13.174v40.174C43 55.365 41.34 57 39.294 57H4.706C2.659 57 1 55.365 1 53.348V4.652C1 2.635 2.66 1 4.706 1h25.94L43 13.174z\"/><path d=\"M31 1v8.4a3.6 3.6 0 003.6 3.6H43M11 42h22M11 35h22M11 28h22\"/></g><path fill=\"#FFF\" d=\"M29 43h28v28H29z\"/><g transform=\"translate(35 49)\" stroke=\"#FE0000\" stroke-width=\"2\"><circle cx=\"8\" cy=\"8\" r=\"8\"/><path d=\"M2.4 2.4l11.2 11.2\" stroke-linecap=\"square\"/></g></g>",
    "attrs": {
      "width": "57",
      "height": "71",
      "viewBox": "0 0 57 71",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});