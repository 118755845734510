define("ember-router-scroll/services/router-scroll", ["exports", "@ember/service", "@ember/object", "@ember/utils", "@ember/debug", "@ember/application", "@ember/runloop", "@ember/object/events", "ember-app-scheduler"], function (_exports, _service, _object, _utils, _debug, _application, _runloop, _events, _emberAppScheduler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ATTEMPTS = 0;
  const MAX_ATTEMPTS = 100; // rAF runs every 16ms ideally, so 60x a second

  let requestId;
  let callbackRequestId;

  /**
   * By default, we start checking to see if the document height is >= the last known `y` position
   * we want to scroll to.  This is important for content heavy pages that might try to scrollTo
   * before the content has painted
   *
   * @method tryScrollRecursively
   * @param {Function} fn
   * @param {Object} scrollHash
   * @param {Element} [element]
   * @param {string?} url
   * @void
   */
  function tryScrollRecursively(fn, scrollHash, element, url) {
    let documentHeight;
    // read DOM outside of rAF
    if (element) {
      documentHeight = Math.max(element.scrollHeight, element.offsetHeight, element.clientHeight);
    } else {
      const {
        body,
        documentElement: html
      } = document;
      documentHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    }
    callbackRequestId = window.requestAnimationFrame(() => {
      if (url && url.indexOf('#') > -1) {
        const hashElement = document.getElementById(url.split('#').pop());
        if (hashElement) {
          scrollHash = {
            x: hashElement.offsetLeft,
            y: hashElement.offsetTop
          };
        }
      }
      // write DOM (scrollTo causes reflow)
      if (documentHeight >= scrollHash.y || ATTEMPTS >= MAX_ATTEMPTS) {
        ATTEMPTS = 0;
        fn.call(null, scrollHash.x, scrollHash.y);
      } else {
        ATTEMPTS++;
        tryScrollRecursively(fn, scrollHash, element, url);
      }
    });
  }

  // to prevent scheduleOnce calling multiple times, give it the same ref to this function
  const CALLBACK = function (transition) {
    this.updateScrollPosition(transition);
  };
  let RouterScroll = (_class = class RouterScroll extends _service.default {
    get isFastBoot() {
      const fastboot = (0, _application.getOwner)(this).lookup('service:fastboot');
      return fastboot ? fastboot.get('isFastBoot') : false;
    }

    // ember-app-scheduler properties

    constructor() {
      super(...arguments);

      // https://github.com/ember-app-scheduler/ember-app-scheduler/pull/773
      _initializerDefineProperty(this, "router", _descriptor, this);
      _defineProperty(this, "key", void 0);
      _defineProperty(this, "targetElement", void 0);
      _defineProperty(this, "scrollElement", 'window');
      _defineProperty(this, "isFirstLoad", true);
      _defineProperty(this, "preserveScrollPosition", false);
      _defineProperty(this, "scrollWhenIdle", false);
      _defineProperty(this, "scrollWhenAfterRender", false);
      (0, _emberAppScheduler.setupRouter)(this.router);
    }

    // eslint-disable-next-line ember/classic-decorator-hooks
    init() {
      super.init(...arguments);
      this._loadConfig();
      (0, _object.set)(this, 'scrollMap', {
        default: {
          x: 0,
          y: 0
        }
      });
      (0, _events.addListener)(this.router, 'routeWillChange', this._routeWillChange);
      (0, _events.addListener)(this.router, 'routeDidChange', this._routeDidChange);
    }
    willDestroy() {
      (0, _events.removeListener)(this.router, 'routeWillChange', this._routeWillChange);
      (0, _events.removeListener)(this.router, 'routeDidChange', this._routeDidChange);
      if (requestId) {
        window.cancelAnimationFrame(requestId);
      }
      if (callbackRequestId) {
        window.cancelAnimationFrame(callbackRequestId);
      }
      super.willDestroy(...arguments);
    }

    /**
     * Updates the scroll position
     * it will be a single transition
     * @method updateScrollPosition
     * @param {transition|transition[]} transition If before Ember 3.6, this will be an array of transitions, otherwise
     */
    updateScrollPosition(transition) {
      if (this.isFirstLoad) {
        this.unsetFirstLoad();
      }
      let scrollPosition = this.position;

      // If `preserveScrollPosition` was not set on the controller, attempt fallback to `preserveScrollPosition` which was set on the router service.
      let preserveScrollPosition = (transition.router.currentRouteInfos || []).some(routeInfo => routeInfo.route.controller.preserveScrollPosition) || this.preserveScrollPosition;
      if (!preserveScrollPosition) {
        const {
          scrollElement,
          targetElement,
          currentURL
        } = this;
        if (targetElement || 'window' === scrollElement) {
          tryScrollRecursively(window.scrollTo, scrollPosition, null, currentURL);
        } else if ('#' === scrollElement.charAt(0)) {
          const element = document.getElementById(scrollElement.substring(1));
          if (element) {
            let fn = (x, y) => {
              element.scrollLeft = x;
              element.scrollTop = y;
            };
            tryScrollRecursively(fn, scrollPosition, element, currentURL);
          }
        }
      }
      (0, _events.sendEvent)(this, 'didScroll', transition);
    }
    _routeWillChange() {
      if (this.isFastBoot) {
        return;
      }
      this.update();
    }
    _routeDidChange(transition) {
      if (this.isFastBoot) {
        return;
      }
      const scrollWhenIdle = this.scrollWhenIdle;
      const scrollWhenAfterRender = this.scrollWhenAfterRender;
      if (!scrollWhenIdle && !scrollWhenAfterRender) {
        // out of the option, this happens on the tightest schedule
        (0, _runloop.scheduleOnce)('render', this, CALLBACK, transition);
      } else if (scrollWhenAfterRender && !scrollWhenIdle) {
        // out of the option, this happens on the second tightest schedule
        (0, _runloop.scheduleOnce)('afterRender', this, CALLBACK, transition);
      } else {
        (0, _emberAppScheduler.whenRouteIdle)().then(() => {
          this.updateScrollPosition(transition);
        });
      }
    }
    unsetFirstLoad() {
      (0, _object.set)(this, 'isFirstLoad', false);
    }
    update() {
      if (this.isFastBoot || this.isFirstLoad) {
        return;
      }
      const scrollElement = this.scrollElement;
      const targetElement = this.targetElement;
      const scrollMap = this.scrollMap;
      const key = this.key;
      let x;
      let y;
      if (targetElement) {
        let element = document.querySelector(targetElement);
        if (element) {
          x = element.offsetLeft;
          y = element.offsetTop;

          // if we are looking to where to transition to next, we need to set the default to the position
          // of the targetElement on screen
          (0, _object.set)(scrollMap, 'default', {
            x,
            y
          });
        }
      } else if ('window' === scrollElement) {
        x = window.scrollX;
        y = window.scrollY;
      } else if ('#' === scrollElement.charAt(0)) {
        let element = document.getElementById(scrollElement.substring(1));
        if (element) {
          x = element.scrollLeft;
          y = element.scrollTop;
        }
      }

      // only a `key` present after first load
      if (key && 'number' === (0, _utils.typeOf)(x) && 'number' === (0, _utils.typeOf)(y)) {
        (0, _object.set)(scrollMap, key, {
          x,
          y
        });
      }
    }
    _loadConfig() {
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      if (config && config.routerScroll) {
        const scrollElement = config.routerScroll.scrollElement;
        const targetElement = config.routerScroll.targetElement;
        (false && !(!(scrollElement && targetElement)) && (0, _debug.assert)('You defined both scrollElement and targetElement in your config. We currently only support definining one of them', !(scrollElement && targetElement)));
        if ('string' === (0, _utils.typeOf)(scrollElement)) {
          (0, _object.set)(this, 'scrollElement', scrollElement);
        }
        if ('string' === (0, _utils.typeOf)(targetElement)) {
          (0, _object.set)(this, 'targetElement', targetElement);
        }
        const {
          scrollWhenIdle = false,
          scrollWhenAfterRender = false
        } = config.routerScroll;
        (0, _object.set)(this, 'scrollWhenIdle', scrollWhenIdle);
        (0, _object.set)(this, 'scrollWhenAfterRender', scrollWhenAfterRender);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_routeWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_routeDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_routeDidChange"), _class.prototype)), _class);
  Object.defineProperty(RouterScroll.prototype, 'position', {
    configurable: true,
    get() {
      var _window$history$state;
      const scrollMap = this.scrollMap;
      const stateUuid = (_window$history$state = window.history.state) === null || _window$history$state === void 0 ? void 0 : _window$history$state.uuid;
      (0, _object.set)(this, 'key', stateUuid);
      const key = this.key || '-1';
      return (0, _object.get)(scrollMap, key) || scrollMap.default;
    }
  });
  var _default = RouterScroll;
  _exports.default = _default;
});