define("ember-svg-jar/inlined/family", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 6a3 3 0 100-6 3 3 0 000 6zm0-1.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM4.25 6a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zm0-1.5a.75.75 0 110-1.5.75.75 0 010 1.5zM20 3.75a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-1.5 0a.75.75 0 10-1.5 0 .75.75 0 001.5 0zM7.229 7.689c.21-.12.454-.189.713-.189h6.116a1.435 1.435 0 01.806.246c.384.26.636.698.636 1.196V13.5a4.5 4.5 0 01-9 0V8.942c0-.537.293-1.005.729-1.253zM8 13.5a3 3 0 006 0V9H8v4.5zM5.375 8.942c0-.534.163-1.03.443-1.442H2A1.5 1.5 0 00.5 9v3.75a3.75 3.75 0 005.495 3.32 5.584 5.584 0 01-.507-1.441A2.25 2.25 0 012 12.75V9h3.375v-.058zM17.75 16.5c-.63 0-1.224-.155-1.745-.43.23-.449.403-.932.507-1.441A2.25 2.25 0 0020 12.75V9h-3.375v-.058c0-.534-.163-1.03-.443-1.442H20A1.5 1.5 0 0121.5 9v3.75a3.75 3.75 0 01-3.75 3.75z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "22",
      "height": "18",
      "viewBox": "0 0 22 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});