define("ember-svg-jar/inlined/push-prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"24\" cy=\"24\" r=\"24\" fill=\"#0439D7\"/><path d=\"M42 37h25M42 46h12M60 46h7M88 40c0 18.225-14.775 33-33 33H23.813l8.776-8.777C26.077 58.195 22 49.573 22 40 22 21.775 36.775 7 55 7\" stroke=\"#01051C\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><circle cx=\"79.5\" cy=\"15.5\" r=\"14\" stroke=\"#01051C\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M79 10v11\" stroke=\"#01051C\" stroke-width=\"3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "95",
      "height": "75",
      "viewBox": "0 0 95 75",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});