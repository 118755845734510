define("ember-svg-jar/inlined/customer-satisfaction-rate-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5zm0-1.5a9 9 0 100-18 9 9 0 000 18z\" fill=\"#0439D7\"/><circle cx=\"9\" cy=\"9\" r=\"1.5\" fill=\"#0439D7\"/><circle cx=\"15\" cy=\"9\" r=\"1.5\" fill=\"#0439D7\"/><path d=\"M9.75 15L12 15 14.25 15\" stroke=\"#0439D7\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});