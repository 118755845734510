define("ember-svg-jar/inlined/add-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M46.105 15.219L31.78.895C31.109.224 30.214 0 29.319 0H3.357C1.567 0 0 1.567 0 3.357v49.238c0 1.79 1.567 3.357 3.357 3.357h40.286c1.79 0 3.357-1.566 3.357-3.357V17.681c0-1.12-.448-1.791-.895-2.462zm-2.91.224H32.452c-.671 0-1.119-.448-1.119-1.12V3.582l11.862 11.862zm1.567 36.928c0 .672-.448 1.12-1.12 1.12H3.358c-.671 0-1.119-.448-1.119-1.12V3.133c0-.671.448-1.119 1.12-1.119h25.737v12.31c0 1.79 1.567 3.357 3.357 3.357h12.31v34.69zm-23.5-12.757l12.757-12.757a1.082 1.082 0 011.567 0 1.081 1.081 0 010 1.566L22.157 41.852c-.224.224-.448.224-.895.224-.224 0-.448 0-.672-.224l-6.714-5.595c-.447-.448-.447-1.119-.224-1.566.448-.448 1.12-.448 1.567-.225l6.043 5.148z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "47",
      "height": "56",
      "viewBox": "0 0 47 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});