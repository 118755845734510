define("ember-svg-jar/inlined/styleguideArrowRight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.4 8L4.3 1.9c-.4-.5-.4-1.2.1-1.6S5.6-.1 6 .4l5.7 6.9c.4.4.4 1 0 1.5L6 15.6c-.4.5-1.1.6-1.6.1s-.6-1.1-.1-1.6L9.4 8z\" fill=\"#fff\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});