define("ember-svg-jar/inlined/documentsStacked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(1 1)\" stroke=\"#4679D9\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><rect y=\"3\" width=\"16\" height=\"20\" rx=\"2\"/><path d=\"M3 2.002h0A2 2 0 014.994 0h12.012C18.107 0 19 .898 19 1.99v16.02c0 1.099-.886 1.99-2.005 1.99\"/></g>",
    "attrs": {
      "width": "21",
      "height": "25",
      "viewBox": "0 0 21 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});