define("ember-svg-jar/inlined/placeholderProduct", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M24.188 20.53v12.29c0 1.405-.88 2.543-1.937 2.543H2.937C1.867 35.363 1 34.21 1 32.827V7.537C1 6.136 1.866 5 2.935 5h13.603M5.969 19.833h3.313M5.969 24.833H18.39M5.969 29.833h9.938\" stroke=\"#717582\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M24.5 16.125H20V12.75h4.5v3.375zm-.563-5.625h-3.375L20 .375h4.5L23.937 10.5z\" fill=\"#F6A823\" class=\"svg-highlight-fill\"/></g>",
    "attrs": {
      "width": "26",
      "height": "37",
      "viewBox": "0 0 26 37",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});