define("ember-svg-jar/inlined/plusDocument", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#FFF\" stroke-width=\"3\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M61 47.387V71c0 2.761-2.234 5-5 5H20c-2.762 0-5-2.243-5-4.992V19.992A5 5 0 0120 15h28.637\" stroke-linecap=\"round\"/><path d=\"M7.735 63H7.01C4.243 63 2 60.757 2 58.008V6.992h0A4.993 4.993 0 017 2h36c2.762 0 5 2.24 5 5.008v1.348\" stroke-linecap=\"round\"/><path d=\"M24 44.405h19.5M24 34.405h8.5M24 54.405h27.5M24 64.25h20.186\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M61.5 41.278h0c6.904 0 12.5-5.608 12.5-12.526s-5.596-12.526-12.5-12.526S49 21.834 49 28.752s5.596 12.526 12.5 12.526h0z\"/><g stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M61.5 25v7.864M65.5 28.864h-8\"/></g></g>",
    "attrs": {
      "class": "plus-document",
      "width": "76",
      "height": "78",
      "viewBox": "0 0 76 78",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});