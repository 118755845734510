define("ember-svg-jar/inlined/notification-icon-close", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.707.293a1 1 0 00-1.414 0L5 3.586 1.707.293A1 1 0 00.293 1.707L3.586 5 .293 8.293a1 1 0 001.414 1.414L5 6.414l3.293 3.293a1 1 0 001.414-1.414L6.414 5l3.293-3.293a1 1 0 000-1.414z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});