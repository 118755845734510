define("ember-file-upload/components/file-dropzone", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/application", "ember-file-upload/system/data-transfer", "ember-file-upload/system/parse-html", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _service, _application, _dataTransfer, _parseHtml, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    {{drag-listener
      dragenter=this.didEnterDropzone
      dragleave=this.didLeaveDropzone
      dragover=this.didDragOver
      drop=this.didDrop
    }}
    {{update-queue
      this.queue
      multiple=@multiple
      disabled=@disabled
      onFileAdd=@onFileAdd
    }}
  >
    {{yield
      (hash supported=this.supported active=this.active)
      this.queue
    }}
  </div>
  
  */
  {
    "id": "1KR9ebpm",
    "block": "[[[11,0],[17,1],[4,[38,0],null,[[\"dragenter\",\"dragleave\",\"dragover\",\"drop\"],[[30,0,[\"didEnterDropzone\"]],[30,0,[\"didLeaveDropzone\"]],[30,0,[\"didDragOver\"]],[30,0,[\"didDrop\"]]]]],[4,[38,1],[[30,0,[\"queue\"]]],[[\"multiple\",\"disabled\",\"onFileAdd\"],[[30,2],[30,3],[30,4]]]],[12],[1,\"\\n  \"],[18,5,[[28,[37,3],null,[[\"supported\",\"active\"],[[30,0,[\"supported\"]],[30,0,[\"active\"]]]]],[30,0,[\"queue\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@multiple\",\"@disabled\",\"@onFileAdd\",\"&default\"],false,[\"drag-listener\",\"update-queue\",\"yield\",\"hash\"]]",
    "moduleName": "ember-file-upload/components/file-dropzone.hbs",
    "isStrictMode": false
  });
  let supported = function () {
    return typeof window !== 'undefined' && window.document && 'draggable' in document.createElement('span');
  }();

  /**
    Whether multiple files can be selected when uploading.
    @argument multiple
    @type {boolean}
    */

  /**
    The name of the queue to upload the file to.
  
    @argument name
    @type {string}
    @required
    */

  /**
    If set, disables input and prevents files from being added to the queue
  
    @argument disabled
    @type {boolean}
    @default false
    */

  /**
    `onFileAdd` is called when a file is added to the upload queue.
  
    When multiple files are added, this function
    is called once for every file.
  
    @argument onFileAdd
    @type {function(file: File)}
    @required
    */

  /**
    `onDragEnter` is called when files have entered
    the dropzone.
  
    @argument onDragEnter
    @type {function(files: File[], dataTransfer: DataTransfer)}
    */

  /**
    `onDragLeave` is called when files have left
    the dropzone.
  
    @argument onDragLeave
    @type {function(files: File[], dataTransfer: DataTransfer)}
    */

  /**
    `onDrop` is called when file have been dropped on the dropzone.
  
    Optionally restrict which files are added to the upload queue by
    returning an array of File objects.
  
    @argument onDrop
    @type {function(files: File[], dataTransfer: DataTransfer)}
    */

  /**
    Whether users can upload content
    from websites by dragging images from
    another webpage and dropping it into
    your app. The default is `false` to
    prevent cross-site scripting issues.
  
    @argument allowUploadsFromWebsites
    @type {boolean}
    @default false
    */

  /**
    This is the type of cursor that should
    be shown when a drag event happens.
  
    Corresponds to `dropEffect`.
  
    This is one of the following:
  
    - `copy`
    - `move`
    - `link`
  
    @argument cursor
    @type {string}
    @default null
    */

  /**
    `FileDropzone` is a component that will allow users to upload files by
     drag and drop.
  
    ```hbs
    <FileDropzone @name="photos" as |dropzone queue|>
      {{#if dropzone.active}}
        Drop to upload
      {{else if queue.files.length}}
        Uploading {{queue.files.length}} files. ({{queue.progress}}%)
      {{else}}
        <h4>Upload Images</h4>
        <p>
          {{#if dropzone.supported}}
            Drag and drop images onto this area to upload them or
          {{/if}}
          <FileUpload
            @name="photos"
            @accept="image/*"
            @multiple=true
            @onFileAdd={{perform this.uploadImage}}
          >
            <a tabindex="0">Add an Image.</a>
          </FileUpload>
        </p>
      {{/if}}
    </FileDropzone>
    ```
  
    ```js
    import Component from '@glimmer/component';
    import { task } from 'ember-concurrency';
  
    export default class ExampleComponent extends Component {
      @task({ maxConcurrency: 3, enqueue: true })
      *uploadImage(file) {
        const response = yield file.upload(url, options);
        ...
      }
    }
    ```
  
    @class FileDropzoneComponent
    @type Ember.Component
    @yield {Hash} dropzone
    @yield {boolean} dropzone.supported
    @yield {boolean} dropzone.active
    @yield {Queue} queue
   */
  let FileDropzoneComponent = (_class = class FileDropzoneComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fileQueue", _descriptor, this);
      _initializerDefineProperty(this, "supported", _descriptor2, this);
      _initializerDefineProperty(this, "active", _descriptor3, this);
      _initializerDefineProperty(this, "dataTransfer", _descriptor4, this);
    }
    get queue() {
      if (!this.args.name) return null;
      return this.fileQueue.find(this.args.name) || this.fileQueue.create(this.args.name);
    }
    get files() {
      var _this$dataTransfer;
      return (_this$dataTransfer = this.dataTransfer) === null || _this$dataTransfer === void 0 ? void 0 : _this$dataTransfer.files;
    }
    isAllowed() {
      const {
        environment
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return environment === 'test' || this.dataTransfer.source === 'os' || this.args.allowUploadsFromWebsites;
    }
    didEnterDropzone(evt) {
      this.dataTransfer = new _dataTransfer.default({
        queue: this.queue,
        source: evt.source,
        dataTransfer: evt.dataTransfer,
        itemDetails: evt.itemDetails
      });
      if (this.isAllowed()) {
        var _this$args$onDragEnte, _this$args;
        evt.dataTransfer.dropEffect = this.args.cursor;
        this.active = true;
        (_this$args$onDragEnte = (_this$args = this.args).onDragEnter) === null || _this$args$onDragEnte === void 0 ? void 0 : _this$args$onDragEnte.call(_this$args, this.files, this.dataTransfer);
      }
    }
    didLeaveDropzone(evt) {
      this.dataTransfer.dataTransfer = evt.dataTransfer;
      if (this.isAllowed()) {
        var _this$args$onDragLeav, _this$args2;
        if (evt.dataTransfer) {
          evt.dataTransfer.dropEffect = this.args.cursor;
        }
        (_this$args$onDragLeav = (_this$args2 = this.args).onDragLeave) === null || _this$args$onDragLeav === void 0 ? void 0 : _this$args$onDragLeav.call(_this$args2, this.files, this.dataTransfer);
        this.dataTransfer = null;
        if (this.isDestroyed) {
          return;
        }
        this.active = false;
      }
    }
    didDragOver(evt) {
      this.dataTransfer.dataTransfer = evt.dataTransfer;
      if (this.isAllowed()) {
        evt.dataTransfer.dropEffect = this.args.cursor;
      }
    }
    didDrop(evt) {
      var _this$args$onDrop, _this$args$onDrop2, _this$args3;
      this.dataTransfer.dataTransfer = evt.dataTransfer;
      if (!this.isAllowed()) {
        evt.dataTransfer.dropEffect = this.args.cursor;
        this.dataTransfer = null;
        return;
      }

      // Testing support for dragging and dropping images
      // from other browser windows
      let url;
      let html = this.dataTransfer.getData('text/html');
      if (html) {
        let parsedHtml = (0, _parseHtml.default)(html);
        let img = parsedHtml.getElementsByTagName('img')[0];
        if (img) {
          url = img.src;
        }
      }
      if (url == null) {
        url = this.dataTransfer.getData('text/uri-list');
      }
      if (url) {
        var image = new Image();
        var [filename] = url.split('/').slice(-1);
        image.crossOrigin = 'anonymous';
        image.onload = () => {
          var canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);
          if (canvas.toBlob) {
            canvas.toBlob(blob => {
              let [file] = this.queue._addFiles([blob], 'web');
              file.name = filename;
            });
          } else {
            let binStr = atob(canvas.toDataURL().split(',')[1]);
            let len = binStr.length;
            let arr = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }
            let blob = new Blob([arr], {
              type: 'image/png'
            });
            blob.name = filename;
            let [file] = this.queue._addFiles([blob], 'web');
            file.name = filename;
          }
        };
        /* eslint-disable no-console */
        image.onerror = function (e) {
          console.log(e);
        };
        /* eslint-enable no-console */
        image.src = url;
      }
      const files = (_this$args$onDrop = (_this$args$onDrop2 = (_this$args3 = this.args).onDrop) === null || _this$args$onDrop2 === void 0 ? void 0 : _this$args$onDrop2.call(_this$args3, this.files, this.dataTransfer)) !== null && _this$args$onDrop !== void 0 ? _this$args$onDrop : this.files;

      // Add files to upload queue.
      this.active = false;
      this.queue._addFiles(files, 'drag-and-drop');
      this.dataTransfer = null;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fileQueue", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "supported", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return supported;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "active", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "dataTransfer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didEnterDropzone", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didEnterDropzone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didLeaveDropzone", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didLeaveDropzone"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didDragOver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didDragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "didDrop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didDrop"), _class.prototype)), _class);
  _exports.default = FileDropzoneComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FileDropzoneComponent);
});