define("ember-svg-jar/inlined/broken-glass", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"broken-glass_svg__a\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"20\" height=\"32\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0h19.392v32H0V0z\" fill=\"#fff\"/></mask><g mask=\"url(#broken-glass_svg__a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.958 3.103l-1.745 13.964a1.463 1.463 0 01-1.455 1.26H4.77a1.464 1.464 0 01-1.454-1.26L1.57 3.055c-.145-.776.534-1.648 1.455-1.648h3.976l.581.581-3.345 3.345 2.957 3.54-1.017 3.732 2.521-3.732-1.989-3.54 3.347-3.345-.436-.655h6.98c1.115.074 1.455 1.091 1.357 1.77zM16.504 0H2.929C1.182 0-.175 1.55.019 3.297L1.763 17.31c.194 1.455 1.406 2.57 2.91 2.57h2.812c.824 0 1.454.63 1.454 1.454v8.242c0 .534-.436.97-.97.97H3.122a.746.746 0 00-.728.727c0 .388.34.728.728.728H16.31c.388 0 .727-.34.727-.728a.744.744 0 00-.727-.727h-4.897a.972.972 0 01-.97-.97v-8.242c0-.824.63-1.454 1.455-1.454h2.811c1.456 0 2.716-1.115 2.91-2.57l1.746-14.012A2.898 2.898 0 0016.504 0z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});