define("ember-svg-jar/inlined/icon-optimisation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M5.28 17.187A8.54 8.54 0 0010 18.6a8.54 8.54 0 004.634-1.357.696.696 0 01.964.212.701.701 0 01-.211.967A9.932 9.932 0 0110 20a9.933 9.933 0 01-5.487-1.643.701.701 0 01-.2-.97.696.696 0 01.967-.2zm14.525-9.2c.13.648.195 1.31.195 1.981 0 2.686-1.058 5.206-2.909 7.073a.696.696 0 01-.986.003.701.701 0 01-.003-.99 8.615 8.615 0 002.503-6.086c0-.578-.057-1.149-.168-1.706a.698.698 0 111.368-.275zM.917 7.447l.094.011a.7.7 0 01.549.823 8.704 8.704 0 00-.165 1.687c0 2.301.9 4.457 2.48 6.063a.701.701 0 01-.007.99.696.696 0 01-.987-.007A10.016 10.016 0 010 9.968c0-.663.064-1.318.19-1.96a.699.699 0 01.634-.561h.093zm13.734-.978c.353 0 .645.263.691.605l.007.095v3.733a.699.699 0 01-1.39.094l-.006-.094v-2.07l-3.672 3.286a.696.696 0 01-.826.077l-.093-.068-1.47-1.262-3.036 2.448a.696.696 0 01-.981-.107.702.702 0 01.03-.913l.077-.07L7.47 9.41a.696.696 0 01.801-.051l.09.065 1.446 1.242 3.126-2.798H10.93a.699.699 0 01-.691-.604l-.006-.095a.7.7 0 01.603-.694l.094-.006h3.721zM11.097.002l.095.005.14.018a10.014 10.014 0 017.863 5.993.697.697 0 11-1.282.552 8.618 8.618 0 00-6.765-5.158l-.12-.015A.7.7 0 0111.004.01l.092-.008zM9.603.62a.7.7 0 01-.612.776l-.123.015a8.617 8.617 0 00-6.739 5.064.697.697 0 11-1.275-.567A10.013 10.013 0 018.686.023c.071-.01.071-.01.143-.018.383-.045.73.23.774.614z\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});