define("ember-svg-jar/inlined/rocket-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.41 9.908a3.333 3.333 0 114.714 4.714 3.333 3.333 0 01-4.714-4.714zm3.3 1.414a1.333 1.333 0 10-1.886 1.885 1.333 1.333 0 001.885-1.885zm7.968-5.56a3.667 3.667 0 00-2.415-2.413l-.882-.274a9 9 0 00-9.032 2.232l-1.328 1.328a4.663 4.663 0 00-6.092.435L7.273 8.727a1 1 0 000 1.414l2.12 2.121-.239.24a2.333 2.333 0 000 3.3l.66.66-1.86 1.062a1 1 0 00-.212 1.575l5.186 5.186a1 1 0 001.575-.21l1.064-1.86.663.662a2.333 2.333 0 003.3 0l.235-.235 2.12 2.12a1 1 0 001.414 0l1.656-1.658a4.663 4.663 0 00.437-6.09l1.33-1.33a9 9 0 002.231-9.035l-.275-.886zm-3.007-.503c.524.163.934.573 1.097 1.097l.276.886a7 7 0 01-1.735 7.028l-7.194 7.193a.334.334 0 01-.471 0l-7.076-7.075a.333.333 0 010-.472l7.195-7.195a7 7 0 017.025-1.736l.883.274zm-1.719 13.195a2.663 2.663 0 01-.41 3.236l-.95.95-1.413-1.413 2.773-2.773zm-13.609-9.97a2.663 2.663 0 013.238-.41l-2.773 2.774-1.414-1.414.95-.95zm3.758 12.265l-.685 1.196-3.333-3.333 1.198-.683 2.82 2.82zM8.92 24.526a1 1 0 00-1.414-1.415l-3.3 3.3a1 1 0 001.414 1.415l3.3-3.3zm-2.593-4.007a1 1 0 010 1.414l-1.414 1.414a1 1 0 01-1.414-1.414l1.414-1.414a1 1 0 011.414 0zm5.183 6.602a1 1 0 10-1.414-1.414l-1.412 1.411a1 1 0 101.415 1.415l1.41-1.412z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});