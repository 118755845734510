define("ember-svg-jar/inlined/arrowLeft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.736 15.125a1.142 1.142 0 11-1.757 1.462L.268 9.729a1.142 1.142 0 010-1.462l5.71-6.857A1.143 1.143 0 017.737 2.87L2.633 8.998l5.103 6.127z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 8 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});