define("ember-svg-jar/inlined/vkbvitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.747 6.045c0-.486.094-.941.283-1.367.188-.425.44-.799.757-1.121a3.496 3.496 0 012.497-1.03c.486 0 .942.09 1.368.273a3.56 3.56 0 011.877 1.878c.183.426.274.881.274 1.367 0 .487-.091.943-.274 1.368a3.543 3.543 0 01-1.877 1.878 3.438 3.438 0 01-1.368.274 3.496 3.496 0 01-2.497-1.03 3.752 3.752 0 01-.757-1.122 3.342 3.342 0 01-.283-1.368zM5.709 26.502v-1.404h16.209v-2.115H5.709v-7.766c0-.486.092-.942.274-1.368a3.537 3.537 0 011.878-1.878 3.436 3.436 0 011.367-.274h14.13a3.503 3.503 0 012.498 1.03c.316.323.565.697.748 1.122.182.426.273.882.273 1.368v1.403H10.632v2.115h16.245v7.767c0 .486-.091.942-.273 1.368-.183.426-.432.8-.748 1.12-.316.323-.69.575-1.12.758-.433.182-.892.273-1.378.273H9.228c-.486 0-.942-.091-1.367-.273a3.543 3.543 0 01-1.878-1.878 3.438 3.438 0 01-.274-1.368z\" fill=\"red\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});