define("ember-svg-jar/inlined/rightArrow.vkb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M436.267 290.634c-5.523 0-10 4.472-10 9.99 0 5.517 4.477 9.989 10 9.989 5.522 0 10-4.472 10-9.989 0-5.518-4.478-9.99-10-9.99zm0 1.827c4.514 0 8.172 3.655 8.172 8.163 0 4.507-3.658 8.162-8.172 8.162-4.514 0-8.173-3.655-8.173-8.162 0-4.508 3.659-8.163 8.173-8.163zm-2.108 4.523l2.49 3.668-2.49 3.676h2.49l2.391-3.676-2.391-3.668h-2.49z\" fill=\"#FFF\" class=\"right-arrow\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "isolation:isolate",
      "viewBox": "426.267 290.634 20 19.98",
      "width": "20",
      "height": "19.98"
    }
  };
  _exports.default = _default;
});