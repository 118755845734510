define("ember-svg-jar/inlined/ambulance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.668 6.19H8.34V4.861c0-.265-.177-.443-.443-.443H6.125c-.266 0-.443.178-.443.443V6.19H4.353c-.266 0-.443.177-.443.443v1.772c0 .265.177.443.443.443h1.329v1.328c0 .266.177.443.443.443h1.771c.266 0 .443-.177.443-.443V8.848h1.33c.265 0 .442-.178.442-.443V6.633c0-.266-.177-.443-.443-.443zm-.443 1.772H7.454v1.771h-.886V7.962H4.796v-.886h1.772V5.304h.886v1.772h1.771v.886zm12.402.62l-1.771-.62-1.507-4.43a1.332 1.332 0 00-1.24-.885h-4.34v-1.33c0-.265-.178-.442-.443-.442h-1.772c-.266 0-.443.177-.443.443v1.329H1.695c-.708 0-1.328.62-1.328 1.328v9.745c0 .708.62 1.329 1.328 1.329h1.33c0 1.506 1.15 2.657 2.657 2.657 1.506 0 2.657-1.151 2.657-2.657h5.316c0 1.506 1.151 2.657 2.657 2.657s2.658-1.151 2.658-2.657h2.214c.709 0 1.33-.62 1.33-1.33V9.823c0-.62-.355-1.063-.887-1.24zm-7.972-5.05h3.454c.177 0 .355.09.443.266l1.418 4.164h-3.898c-.709 0-1.329-.62-1.329-1.329v-3.1h-.088zm-2.658-1.771h.886v.886h-.886V1.76zM5.682 16.82a1.777 1.777 0 01-1.772-1.772c0-.975.797-1.772 1.772-1.772.974 0 1.772.797 1.772 1.772 0 .974-.798 1.771-1.772 1.771zm10.63 0a1.777 1.777 0 01-1.772-1.772c0-.975.798-1.772 1.772-1.772s1.772.797 1.772 1.772c0 .974-.797 1.771-1.772 1.771zm5.315-3.101c0 .266-.177.443-.443.443h-2.392a2.58 2.58 0 00-2.48-1.772 2.58 2.58 0 00-2.48 1.772h-5.67a2.58 2.58 0 00-2.48-1.772 2.58 2.58 0 00-2.48 1.772H1.694c-.265 0-.442-.177-.442-.443V3.975c0-.265.177-.443.442-.443H12.77v3.101c0 1.24.974 2.215 2.214 2.215h4.695l1.595.531a.487.487 0 01.266.443v3.898h.088z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 23 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});