define("ember-svg-jar/inlined/gift-voucher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.083 1.667a2.708 2.708 0 012.283 4.167h2.092c.576 0 1.042.466 1.042 1.041v2.917c0 .503-.358.924-.833 1.02v4.813a2.709 2.709 0 01-2.555 2.704l-.154.004H6.042a2.708 2.708 0 01-2.704-2.554l-.005-.154v-4.813a1.042 1.042 0 01-.833-1.02V6.875c0-.575.466-1.042 1.042-1.042h2.092A2.708 2.708 0 0110 2.645a2.7 2.7 0 012.083-.977zm-2.708 9.165H4.583v4.793c0 .765.59 1.393 1.34 1.453l.119.005h3.333v-6.25zm6.042 0h-4.792v6.251h3.333c.765 0 1.393-.589 1.454-1.338l.005-.12v-4.793zM9.375 7.083H3.75v2.5h5.625v-2.5zm6.875 2.5v-2.5h-5.625v2.5h5.625zm-4.167-6.666c-.805 0-1.458.653-1.458 1.458v1.457h1.478l.1-.003a1.459 1.459 0 00-.12-2.912zm-4.166 0a1.458 1.458 0 00-.12 2.912l.1.003h1.478V4.375l-.005-.12a1.458 1.458 0 00-1.453-1.338z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});