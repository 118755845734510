define("ember-svg-jar/inlined/icon-contract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M17.143 1.429H2.857c-.789 0-1.428.64-1.428 1.428v14.286c0 .789.64 1.428 1.428 1.428h14.286c.789 0 1.428-.64 1.428-1.428V2.857c0-.789-.64-1.428-1.428-1.428zM2.857 0A2.857 2.857 0 000 2.857v14.286A2.857 2.857 0 002.857 20h14.286A2.857 2.857 0 0020 17.143V2.857A2.857 2.857 0 0017.143 0H2.857z\"/><path d=\"M4.286 5c0-.394.32-.714.714-.714h10a.714.714 0 110 1.428H5A.714.714 0 014.286 5zm0 4.286c0-.395.32-.715.714-.715h10A.714.714 0 1115 10H5a.714.714 0 01-.714-.714zM0 13.571c0-.394.32-.714.714-.714h6.429c.394 0 .714.32.714.714a2.143 2.143 0 104.286 0c0-.394.32-.714.714-.714h6.429a.714.714 0 110 1.429H13.5a3.573 3.573 0 01-7 0H.714A.714.714 0 010 13.57z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});