define("ember-svg-jar/inlined/thumbsUp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#FFF\" stroke-width=\"3\" fill=\"#4679D9\" class=\"svg-brand-primary-fill\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M7.59 21.362H4.53c-.847 0-1.53.684-1.53 1.53v27.536c0 .846.683 1.53 1.53 1.53l3.06-.032c.846 0 1.529-.684 1.529-1.53V22.863c0-.845-.683-1.5-1.53-1.5h0zM51.782 22.409c-.212-.386-.596-.706-1.141-.95a1.586 1.586 0 00-.43-.123c-1.3-.166-7.436-1.5-18.163-1.5 1.389-4.32 1.547-5.038 1.547-9.182 0-4.59-2.823-7.599-4.592-7.641-.838 0-1.52.674-1.528 1.51-.069 6.035-2.993 13.653-10.868 16.896-.829.262-1.386.986-1.386 1.808l.013 24.141c0 1.291 1.409 1.53 1.409 1.53.436.129.804.27 7.743 1.944.118.027 4.67 1.116 9.259 1.116h9.13c1.72 0 2.533-.574 3.038-1.527.062-.122 6.119-12.413 6.158-27.282 0-.258-.063-.514-.189-.74h0z\"/></g>",
    "attrs": {
      "width": "49",
      "height": "49",
      "viewBox": "0 0 55 55",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});