define("ember-svg-jar/inlined/welt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Group 14</title><g fill=\"#231F20\" fill-rule=\"evenodd\"><path d=\"M49.856 1.672v3.305h4.56v11.29h4.007V4.978h4.55V1.672zM41.406 16.268V1.672h4.007v11.29h6.622v3.306zM28.867 6.832c.428-1.843 1.582-2.777 3.429-2.777 1.743 0 2.89.96 3.316 2.776l.02.084h-6.784l.02-.083zm7.698 4.503a6.112 6.112 0 01-3.79 1.311c-2.103 0-3.46-.995-3.92-2.879l-.02-.084h10.921l.01-.058c.048-.327.08-.863.08-1.312C39.83 3.768 36.809.714 32.329.714c-4.68 0-7.822 3.079-7.822 7.659 0 4.528 3.227 7.57 8.03 7.57 2.726 0 4.787-.672 6.485-2.116l.05-.042-2.456-2.49-.05.04zM15.137 16.268L12.22 7.036l-2.916 9.232H4.522L.095 1.672H4.23l2.866 10.252 3.01-10.252h4.285l3.01 10.273 2.866-10.273h4.078L19.92 16.268z\"/></g>",
    "attrs": {
      "width": "63",
      "height": "17",
      "viewBox": "0 0 63 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});