define("ember-svg-jar/inlined/hospital-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.877 1.815a14.69 14.69 0 01-12.873 25.81 14.705 14.705 0 1012.873-25.81z\" fill=\"#BFD2F5\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.918 14.08h3.2a.64.64 0 01.64.64v2.56a.64.64 0 01-.64.64h-3.2v3.2a.64.64 0 01-.64.64h-2.56a.64.64 0 01-.64-.64v-3.2h-3.2a.64.64 0 01-.64-.64v-2.56a.64.64 0 01.64-.64h3.2v-3.2a.64.64 0 01.64-.64h2.56a.64.64 0 01.64.64v3.2zm-1.28 2.56h3.84v-1.28h-3.84v-3.84h-1.28v3.84h-3.84v1.28h3.84v3.84h1.28v-3.84z\" fill=\"#2B6CDE\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 16C0 7.163 7.163 0 16 0a16 16 0 0116 16c0 8.837-7.163 16-16 16S0 24.837 0 16zm1.28 0c0 8.13 6.59 14.72 14.72 14.72 8.126-.01 14.71-6.594 14.72-14.72 0-8.13-6.59-14.72-14.72-14.72S1.28 7.87 1.28 16z\" fill=\"#2B6CDE\"/>",
    "attrs": {
      "viewBox": "0 0 33 33",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});