define("ember-svg-jar/inlined/list-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.02 2.18h-1.26V.92c0-.252-.168-.42-.42-.42s-.42.168-.42.42v1.26H7.56V.92c0-.252-.168-.42-.42-.42s-.42.168-.42.42v1.26H3.36V.92c0-.252-.168-.42-.42-.42s-.42.168-.42.42v1.26H1.26C.588 2.18 0 2.768 0 3.44v16.8c0 .672.588 1.26 1.26 1.26h11.76c.672 0 1.26-.588 1.26-1.26V3.44c0-.672-.588-1.26-1.26-1.26zm.42 18.06c0 .252-.168.42-.42.42H1.26c-.252 0-.42-.168-.42-.42V5.54h12.6v14.7zm0-15.54H.84V3.44c0-.252.168-.42.42-.42h11.76c.252 0 .42.168.42.42V4.7zm-8.4 4.62c0-.252.168-.42.42-.42h5.04c.252 0 .42.168.42.42s-.168.42-.42.42H5.46c-.252 0-.42-.168-.42-.42zm-1.68 0c0-.252.168-.42.42-.42s.42.168.42.42-.168.42-.42.42-.42-.168-.42-.42zm1.68 3.36c0-.252.168-.42.42-.42h5.04c.252 0 .42.168.42.42s-.168.42-.42.42H5.46c-.252 0-.42-.168-.42-.42zm-1.68 0c0-.252.168-.42.42-.42s.42.168.42.42-.168.42-.42.42-.42-.168-.42-.42zm1.68 3.36c0-.252.168-.42.42-.42h5.04c.252 0 .42.168.42.42s-.168.42-.42.42H5.46c-.252 0-.42-.168-.42-.42zm-1.68 0c0-.252.168-.42.42-.42s.42.168.42.42-.168.42-.42.42-.42-.168-.42-.42z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "15",
      "height": "22",
      "viewBox": "0 0 15 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});