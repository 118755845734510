define("ember-lifeline/utils/disposable", ["exports", "@ember/debug", "@ember/destroyable"], function (_exports, _debug, _destroyable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.registerDisposable = registerDisposable;
  _exports.runDisposables = runDisposables;
  /**
   * Registers a new disposable function to run for an instance. Will
   * handle lazily creating a new array to store the disposables per
   * instance if one does not exist. Will additionally patch an object's
   * `destroy` hook to ensure the destroyables are run/destroyed when
   * the object is destroyed.
   *
   * @function registerDisposable
   * @public
   * @param {IDestroyable} obj the instance to store the disposable for
   * @param {Function} dispose a function that disposes of instance resources
   */
  function registerDisposable(obj, dispose) {
    (false && !(typeof obj === 'object') && (0, _debug.assert)('Called `registerDisposable` where `obj` is not an object', typeof obj === 'object'));
    (false && !(typeof dispose === 'function') && (0, _debug.assert)('Called `registerDisposable` where `dispose` is not a function', typeof dispose === 'function'));
    (false && !(!obj.isDestroying) && (0, _debug.assert)('Called `registerDisposable` on a destroyed object', !obj.isDestroying));
    (false && !(false) && (0, _debug.deprecate)('ember-lifeline registerDisposable is deprecated. Please import registerDestructor from @ember/destroyable', false, {
      id: 'ember-lifeline-deprecated-register-disposable',
      until: '7.0.0'
    }));
    (0, _destroyable.registerDestructor)(obj, dispose);
  }

  /**
   * Runs all disposables for a given instance.
   *
   * @function runDisposables
   * @public
   * @param {IDestroyable} obj the instance to run the disposables for
   */
  function runDisposables() {
    (false && !(false) && (0, _debug.deprecate)('ember-lifeline runDisposables is deprecated. Explicitly invoking disposables is no longer required.', false, {
      id: 'ember-lifeline-deprecated-run-disposable',
      until: '7.0.0'
    }));
  }
});