define("ember-svg-jar/inlined/group-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.025 14.991l6.08-3.012a.256.256 0 00.116-.34L8.398 5.76l-3.18-1.144L.91 6.377a.255.255 0 00-.13.347l3.912 8.15c.06.126.21.179.334.117z\" fill=\"#F5F6FA\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.723 5.906a.767.767 0 00-.394 1.041l3.913 8.15a.745.745 0 001.003.35l6.08-3.012a.767.767 0 00.345-1.019L8.758 5.35l-3.55-1.278L.722 5.906zm.622.84L4.983 5.26l1.116 2.204 2.069-1.025 2.494 5.196-5.631 2.79-3.686-7.677zm5.762-.913l-.566.281-.3-.593.866.312z\" fill=\"#575970\"/><path d=\"M6.976 14.596l8.126-2.212a.255.255 0 00.163-.35l-3.919-8.851L9.339 2.16 3.986 3.186a.254.254 0 00-.195.315l2.88 10.915a.25.25 0 00.305.18z\" fill=\"#F5F6FA\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.894 2.687a.762.762 0 00-.586.946l2.88 10.915a.749.749 0 00.918.538l8.125-2.211a.765.765 0 00.49-1.049L11.73 2.81 9.412 1.63 3.894 2.686zm.45.947l4.922-.943 1.697.865 3.726 8.415-7.6 2.068L4.343 3.634z\" fill=\"#575970\"/><path d=\"M8.227 15.365h10.3c.194 0 .35-.16.35-.355V4.698L15.66.635H8.227c-.193 0-.35.16-.35.356V15.01c0 .196.157.355.35.355z\" fill=\"#F5F6FA\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.96 0H8.227a.983.983 0 00-.975.99v14.02c0 .547.437.99.975.99h10.3a.983.983 0 00.975-.99V4.474L15.96 0zm.156 2.223l1.58 1.996-1.826.25.246-2.246zm2.136 3.201l-2.691.368a.882.882 0 01-.986-.979l.387-3.543h-6.46v13.46h9.75V5.424z\" fill=\"#575970\"/>",
    "attrs": {
      "width": "20",
      "height": "16",
      "viewBox": "0 0 20 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});