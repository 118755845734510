define("ember-svg-jar/inlined/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#2B6CDE\" class=\"video-icon svg-brand-primary-fill\" fill-rule=\"evenodd\"><path d=\"M22.5 4.288v6.426L30 15V0zM18.75 0H1.875A1.873 1.873 0 000 1.875v11.25C0 14.162.838 15 1.875 15H18.75a1.873 1.873 0 001.875-1.875V1.875A1.874 1.874 0 0018.75 0z\"/></g>",
    "attrs": {
      "width": "30",
      "height": "15",
      "viewBox": "0 0 30 15"
    }
  };
  _exports.default = _default;
});