define("ember-svg-jar/inlined/groovitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><linearGradient x1=\"32.817%\" y1=\"26.925%\" x2=\"-1.382%\" y2=\"70.516%\" id=\"groovitar_svg__d\"><stop stop-color=\"#FFF\" offset=\"0%\"/><stop stop-color=\"#BD7431\" offset=\"100%\"/></linearGradient><circle id=\"groovitar_svg__a\" cx=\"190\" cy=\"190\" r=\"190\"/><path id=\"groovitar_svg__c\" d=\"M47.811.267L91.414.244l-69.57 191.754-21.75-60.207z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"groovitar_svg__b\" fill=\"#fff\"><use xlink:href=\"#groovitar_svg__a\"/></mask><use fill=\"#003154\" xlink:href=\"#groovitar_svg__a\"/><g mask=\"url(#groovitar_svg__b)\"><path d=\"M118.8 285.111h43.767L113.86 151.583H70l48.8 133.528zm77.59 0l-48.8-133.528h43.859l26.87 73.523 47.905-131.083L310 94l-69.844 191.111h-43.767z\" fill=\"#FF4405\"/><use fill=\"url(#groovitar_svg__d)\" style=\"mix-blend-mode:multiply\" opacity=\".9\" xlink:href=\"#groovitar_svg__c\" transform=\"translate(218.444 94)\"/></g></g>",
    "attrs": {
      "width": "380",
      "height": "380",
      "viewBox": "0 0 380 380",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});