define("ember-svg-jar/inlined/pen", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.75 15.5c1.308 0 1.818.583 2.205 1.875l.068.237c.183.657.292.854.513.945.259.107.431.092.703-.048l.147-.082c.053-.032.11-.069.176-.112l.663-.451c.616-.405 1.17-.673 1.843-.841a.75.75 0 01.364 1.455 4.03 4.03 0 00-1.146.49l-.298.19-.48.328a5.45 5.45 0 01-.583.357c-.643.33-1.27.386-1.96.1-.746-.306-1.046-.779-1.327-1.72l-.156-.543c-.181-.589-.305-.68-.732-.68-.31 0-.63.155-1.069.523l-.184.161-.921.875c-1.408 1.324-2.609 1.967-4.328 1.967-1.686 0-3.144-.254-4.368-.769l2.947-.804c.447.048.921.073 1.421.073 1.183 0 2.032-.415 3.087-1.363l.258-.239.532-.51c.236-.227.414-.39.592-.54.684-.573 1.305-.874 2.033-.874zm4.28-13.53a3.579 3.579 0 010 5.06l-.288.29c1.151 1.4 1.11 2.886.039 3.96L14.78 13.28a.75.75 0 01-1.06-1.062l1.999-1.998c.485-.487.54-1.09-.04-1.839L7.062 17a2.25 2.25 0 01-1 .58L.948 18.974a.75.75 0 01-.92-.921l1.394-5.116a2.25 2.25 0 01.58-.999L11.97 1.97a3.578 3.578 0 015.061 0zm-4 1.06L3.062 13a.75.75 0 00-.193.333l-1.05 3.85 3.85-1.05A.75.75 0 006 15.937L15.97 5.97a2.078 2.078 0 10-2.939-2.94z\" fill=\"#203151\"/>",
    "attrs": {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});