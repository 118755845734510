define("ember-svg-jar/inlined/search-shield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.6 4.8a8.8 8.8 0 016.763 14.431l6.603 6.603a.8.8 0 01-1.021 1.224l-.11-.092-6.604-6.603a8.78 8.78 0 01-3.235 1.707c.003-.111.004-.223.004-.335V20.39A7.2 7.2 0 106.4 13.48l-.007.007a7.401 7.401 0 01-1.522 1.241A8.8 8.8 0 0113.6 4.8z\"/><path d=\"M11.2 16.555a7.415 7.415 0 01-1.6-.932 9.685 9.685 0 01-1.146-1.027.622.622 0 00-.906 0c-1.59 1.656-3.35 2.47-5.308 2.47a.654.654 0 00-.64.667v4.002l.007.425c.042 1.275.278 2.422.704 3.44A7.699 7.699 0 004.8 28.8c.838.64 1.838 1.163 2.999 1.566a.616.616 0 00.404 0c4.097-1.423 6.198-4.34 6.198-8.63v-4.003l-.013-.134a.647.647 0 00-.627-.532 6.494 6.494 0 01-2.56-.511z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});