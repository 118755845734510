define("ember-svg-jar/inlined/retirementIconSmall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#FFF\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\"><path d=\"M7.528 2.317a11.992 11.992 0 00-3.013 2.198c-4.687 4.686-4.687 12.284 0 16.97\" stroke-width=\"1.5\"/><path d=\"M21.485 21.485c4.687-4.686 4.687-12.284 0-16.97C17.708.737 12.04.005 7.528 2.317\" stroke-width=\"2\" stroke-dasharray=\"1,4\"/><path d=\"M8 11h10m-7 4h4\" stroke-width=\"1.5\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "26",
      "height": "23",
      "viewBox": "0 0 26 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});