define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#delete_svg__clip0_561_784)\"><rect width=\"40\" height=\"40\" rx=\"20\" fill=\"#F9D6DC\"/><path d=\"M18 13h4a2 2 0 10-4 0zm-1.5 0a3.5 3.5 0 117 0h5.75a.75.75 0 010 1.5h-1.32L26.76 26.611A3.75 3.75 0 0123.026 30h-6.052a3.75 3.75 0 01-3.733-3.389L12.07 14.5H10.75a.75.75 0 010-1.5h5.75zm2 4.75a.75.75 0 00-1.5 0v7.5a.75.75 0 001.5 0v-7.5zm3.75-.75a.75.75 0 00-.75.75v7.5a.75.75 0 001.5 0v-7.5a.75.75 0 00-.75-.75z\" fill=\"#A90E1C\"/></g><defs><clipPath id=\"delete_svg__clip0_561_784\"><rect width=\"40\" height=\"40\" rx=\"20\" fill=\"#fff\"/></clipPath></defs>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});