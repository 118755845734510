define("ember-svg-jar/inlined/email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.153 1.727l8.241 4.552c.277.153.635.226.996.226s.72-.073.996-.226l8.241-4.552C20.164 1.43 20.672.5 19.687.5H1.093c-.985 0-.478.93.059 1.227zm18.702 2.26l-8.47 4.552c-.372.2-.634.226-.995.226s-.622-.026-.996-.226L.925 3.987c-.429-.23-.425.04-.425.248v8.289c0 .473.614 1.083 1.09 1.083h17.6c.477 0 1.09-.61 1.09-1.083v-8.29c0-.208.004-.478-.425-.247z\" fill=\"#2B6CDE\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "21",
      "height": "14",
      "viewBox": "0 0 21 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});