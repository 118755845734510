define("ember-svg-jar/inlined/questionMarkAlt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 14A6 6 0 108 2a6 6 0 000 12zm0 1A7 7 0 108 1a7 7 0 000 14z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 10.5a.5.5 0 01.5.5v.333a.5.5 0 11-1 0V11a.5.5 0 01.5-.5zM8 5a1.5 1.5 0 00-1.5 1.5.5.5 0 01-1 0 2.5 2.5 0 113.7 2.194 1.89 1.89 0 00-.523.402c-.125.146-.177.28-.177.404a.5.5 0 01-1 0c0-.428.186-.784.418-1.055a2.88 2.88 0 01.8-.628A1.5 1.5 0 008 5z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});