define("ember-svg-jar/inlined/bad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M18.947.67l6.277 6.17a2.34 2.34 0 01.7 1.647l.076 8.8a2.338 2.338 0 01-.67 1.66l-6.17 6.277a2.338 2.338 0 01-1.647.7l-8.8.076a2.338 2.338 0 01-1.66-.67L.776 19.16a2.338 2.338 0 01-.7-1.647L0 8.713a2.338 2.338 0 01.67-1.66L6.84.776a2.338 2.338 0 011.647-.7l8.8-.076a2.338 2.338 0 011.66.67z\" class=\"svg-negative-fill\" opacity=\".2\"/><path d=\"M10.41 4.4l5.33.047a2.144 2.144 0 011.51.64l3.735 3.802c.399.405.62.953.615 1.521l-.047 5.33a2.144 2.144 0 01-.64 1.51l-3.802 3.735c-.405.399-.953.62-1.521.615l-5.33-.047a2.144 2.144 0 01-1.51-.64L5.015 17.11a2.144 2.144 0 01-.615-1.52l.047-5.33a2.144 2.144 0 01.64-1.51L8.89 5.015c.405-.399.953-.62 1.521-.615z\" stroke=\"#FFF\" stroke-width=\"2.2\" class=\"svg-negative-fill\"/><path d=\"M13.226 15.145c-.484 0-.823-.387-.839-.855l-.145-5.742c-.016-.629.37-1.048.984-1.048.613 0 .984.42.984 1.048l-.145 5.742c-.017.468-.355.855-.84.855zm.016 3.355c-.694 0-1.242-.532-1.242-1.21 0-.677.548-1.225 1.242-1.225.677 0 1.21.548 1.21 1.225 0 .678-.533 1.21-1.21 1.21z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "viewBox": "0 0 26 26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});