define("ember-svg-jar/inlined/meet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4 12.938v2.121L3.059 16H.939l3.06-3.062zm16.002-2.503v2.122L16.56 16h-.566v-1.557l4.008-4.008zM6.75 10h6.495a1.75 1.75 0 011.744 1.607l.006.143V16h-1.5v-4.25a.25.25 0 00-.193-.243l-.057-.007H6.75a.25.25 0 00-.244.193l-.006.057V16H5v-4.25a1.75 1.75 0 011.606-1.744L6.75 10zM6.02 6.416c.06.579.243 1.12.523 1.6L0 14.56v-2.122L6.02 6.416zm13.98-.484v2.123l-4.007 4.01v-.315l-.004-.168a2.733 2.733 0 00-.387-1.247l4.399-4.403zM10.056 0L0 10.06V7.939L7.936 0h2.12zm9.946 1.432v2.123l-5.667 5.67a2.732 2.732 0 00-.86-.216L13.245 9h-.6c.342-.301.632-.66.855-1.062l6.502-6.506zM10 3a3 3 0 110 6 3 3 0 010-6zm0 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM5.559 0l-5.56 5.56V3.438L3.439 0h2.12zm13.497 0L13.91 5.149a3.98 3.98 0 00-.652-1.47L16.935 0h2.122zm-4.498 0l-2.543 2.544a3.975 3.975 0 00-1.6-.522L12.438 0h2.122z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "21",
      "height": "16",
      "viewBox": "0 0 21 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});