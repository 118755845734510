define("ember-svg-jar/inlined/shieldWithUpArrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M16.135 3.552C14.884 3.024 13.005 1.884 12 1 9.064 3.58 5.592 4.75 1.06 4.75 1.06 11.468-.478 21.092 12 25c9.282-2.907 10.808-8.977 10.981-14.661.006-.184.058-1.125.06-1.308\"/><path d=\"M8.608 23.495c6.094-3.208 7.226-8.338 7.374-13.187.005-.184.057-1.125.06-1.308m7.021-.01h4.438L19.511 1l-7.99 7.99h4.514\"/></g>",
    "attrs": {
      "width": "29",
      "height": "26",
      "viewBox": "0 0 29 26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});