define("@clarksource/breakpoints/media-queries", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "below-smallest": "only screen and (max-width: 374.98px)",
    "above-smallest": "only screen and (min-width: 375px)",
    "below-small": "only screen and (max-width: 449.98px)",
    "above-small": "only screen and (min-width: 450px)",
    "below-medium": "only screen and (max-width: 729.98px)",
    "above-medium": "only screen and (min-width: 730px)",
    "below-big": "only screen and (max-width: 767.98px)",
    "above-big": "only screen and (min-width: 768px)",
    "below-large": "only screen and (max-width: 999.98px)",
    "above-large": "only screen and (min-width: 1000px)",
    "below-largest": "only screen and (max-width: 1199.98px)",
    "above-largest": "only screen and (min-width: 1200px)"
  };
  _exports.default = _default;
});