define("ember-svg-jar/inlined/plusInCircle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke-width=\"2\" stroke=\"currentColor\" class=\"plus-in-circle svg-cta-stroke\" fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(1 1)\" class=\"plus-in-circle svg-brand-primary-stroke\"><circle cx=\"11\" cy=\"11\" r=\"11\"/><path d=\"M11 6.77v8.46M15.23 11H6.77\" stroke-linecap=\"round\"/></g></g>",
    "attrs": {
      "width": "62",
      "height": "62",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});