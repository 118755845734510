define("ember-svg-jar/inlined/tick_mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"none\" stroke=\"#fff\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"19\" d=\"M88.75 148.26l35.34 35.34 77.28-77.28\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "40 40 210 210"
    }
  };
  _exports.default = _default;
});