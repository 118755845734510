define("ember-svg-jar/inlined/icon-chevron-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M247.275 451.541a36.516 36.516 0 11-56.162 46.668L8.641 279.111a36.516 36.516 0 010-46.704L191.113 13.309a36.516 36.516 0 0156.162 46.668L84.23 255.814l163.045 195.728z\"/>",
    "attrs": {
      "viewBox": "0 0 256 512",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});