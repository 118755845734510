define("ember-svg-jar/inlined/messenger-outofoffice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>time-queue</title><mask id=\"messenger-outofoffice_svg__a\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"32\" height=\"32\"><path fill=\"#C4C4C4\" d=\"M0 0h32v32H0z\"/></mask><g mask=\"url(#messenger-outofoffice_svg__a)\" fill=\"#0439D7\"><path d=\"M16 9.4a.6.6 0 00-.6.6v7.2a.6.6 0 00.6.6h7.2a.6.6 0 000-1.2h-6.6V10a.6.6 0 00-.6-.6z\"/><path d=\"M16 1a14.894 14.894 0 00-13.8 9.14V2.8a.6.6 0 00-1.2 0v8.4a.6.6 0 00.6.6H10a.6.6 0 100-1.2H3.314a13.787 13.787 0 112.333 14.524.6.6 0 00-.9.795A15 15 0 1016 1z\"/></g>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});