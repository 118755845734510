define("ember-svg-jar/inlined/offer-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.953 20.712l5.593 5.657-7.92 8.011a.043.043 0 01-.074-.023l-.881-4.668-4.616-.891a.044.044 0 01-.023-.075l7.92-8.01zM28.292 20.712l-5.593 5.657 7.92 8.011a.043.043 0 00.074-.023l.88-4.668 4.617-.891a.044.044 0 00.022-.075l-7.92-8.01z\" fill=\"#fff\" stroke=\"#0439D7\" stroke-width=\"1.75\"/><path d=\"M21.12 31.5a.908.908 0 01-.333-.063c-6.877-2.548-10.211-5.695-10.211-9.627V9.757a.81.81 0 01.32-.64.909.909 0 01.728-.17c1.094.213 2.559.226 4.02.032 2.433-.323 4.179-1.093 4.84-1.717a.916.916 0 01.973-.2c.124.05.23.122.316.213.672.622 2.41 1.383 4.827 1.704 1.46.194 2.927.181 4.02-.031a.911.911 0 01.727.169.81.81 0 01.322.64V21.81c0 3.934-3.338 7.082-10.204 9.624a.929.929 0 01-.344.066z\" fill=\"#fff\" stroke=\"#0439D7\" stroke-width=\"1.75\"/><path d=\"M21.122 8.75v21\" stroke=\"#0439D7\" stroke-width=\"1.75\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});