define("ember-svg-jar/inlined/icon-nav-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 4.854L9.775 9.305a2.25 2.25 0 01-1.769 1.23l-3.238.354 2.761 2.768c.53.532.76 1.293.613 2.03l-.785 3.926 3.55-1.972a2.25 2.25 0 012.186 0l3.55 1.973-.785-3.927a2.25 2.25 0 01.613-2.03l2.761-2.768-3.238-.353a2.25 2.25 0 01-1.768-1.23L12 4.853zm.67-2.012a.75.75 0 00-1.34 0L8.432 8.634a.75.75 0 01-.59.41l-4.79.524a.75.75 0 00-.45 1.275l3.864 3.873a.75.75 0 01.204.677L5.568 20.91a.75.75 0 001.1.802l4.968-2.76a.75.75 0 01.728 0l4.968 2.76a.75.75 0 001.1-.802l-1.103-5.517a.75.75 0 01.204-.677l3.864-3.873a.75.75 0 00-.45-1.275l-4.79-.524a.75.75 0 01-.59-.41l-2.896-5.792z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});