define("ember-svg-jar/inlined/arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#F6A823\" class=\"svg-highlight-stroke\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M8.029 1L12 5 8 9M11.864 5h-10\"/></g>",
    "attrs": {
      "width": "13",
      "height": "11",
      "viewBox": "0 0 13 11",
      "class": "arrow-icon",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});