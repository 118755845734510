define("ember-power-calendar/components/power-calendar", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "@ember/object/internals", "@ember/service", "ember-concurrency", "ember-power-calendar/templates/components/power-calendar", "@ember/debug", "ember-power-calendar-utils"], function (_exports, _component, _component2, _object, _internals, _service, _emberConcurrency, _powerCalendar, _debug, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class2, _class3, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let _class = (_dec = (0, _component.layout)(_powerCalendar.default), _dec2 = (0, _component.tagName)(''), _dec3 = (0, _service.inject)('power-calendar'), _dec4 = (0, _object.computed)('onSelect', 'onCenterChange'), _dec5 = (0, _object.computed)('center'), _dec6 = (0, _object.computed)('_publicAPI'), _dec7 = (0, _object.computed)('selected', 'currentCenter', 'locale', 'powerCalendarService.locale', 'changeCenterTask.isRunning', 'publicActions'), _dec8 = (0, _object.computed)('tag'), _dec9 = (0, _emberConcurrency.task)(function* (newCenter, calendar, e) {
    (false && !(typeof this.onCenterChange === 'function') && (0, _debug.assert)('You attempted to move the center of a calendar that doesn\'t receive an `@onCenterChange` action.', typeof this.onCenterChange === 'function'));
    let value = (0, _emberPowerCalendarUtils.normalizeCalendarValue)({
      date: newCenter
    });
    yield this.onCenterChange(value, calendar, e);
  }), _dec(_class2 = _dec2(_class2 = (_class3 = class _class3 extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "powerCalendarService", _descriptor, this);
      _defineProperty(this, "navComponent", 'power-calendar/nav');
      _defineProperty(this, "daysComponent", 'power-calendar/days');
      _defineProperty(this, "center", null);
      _defineProperty(this, "_calendarType", 'single');
      // Tasks
      _initializerDefineProperty(this, "changeCenterTask", _descriptor2, this);
    }
    // Lifecycle hooks
    init() {
      super.init(...arguments);
      this.registerCalendar();
      if (this.onInit) {
        this.onInit(this.publicAPI);
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.unregisterCalendar();
    }

    // CPs
    get publicActions() {
      var _this = this;
      let actions = {};
      if (this.onSelect) {
        actions.select = function () {
          return _this.select(...arguments);
        };
      }
      if (this.onCenterChange) {
        let changeCenter = (newCenter, calendar, e) => {
          return this.changeCenterTask.perform(newCenter, calendar, e);
        };
        actions.changeCenter = changeCenter;
        actions.moveCenter = (step, unit, calendar, e) => {
          let newCenter = (0, _emberPowerCalendarUtils.add)(this.currentCenter, step, unit);
          return changeCenter(newCenter, calendar, e);
        };
      }
      return actions;
    }
    get selected() {
      return undefined;
    }
    set selected(v) {
      return (0, _emberPowerCalendarUtils.normalizeDate)(v);
    }
    get currentCenter() {
      let center = this.center;
      if (!center) {
        center = this.selected || this.powerCalendarService.getDate();
      }
      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }
    get publicAPI() {
      return this._publicAPI;
    }
    get _publicAPI() {
      return {
        uniqueId: (0, _internals.guidFor)(this),
        type: this._calendarType,
        selected: this.selected,
        loading: this.changeCenterTask.isRunning,
        center: this.currentCenter,
        locale: this.locale || this.powerCalendarService.locale,
        actions: this.publicActions
      };
    }
    get tagWithDefault() {
      if (this.tag === undefined || this.tag === null) {
        return 'div';
      }
      return this.tag;
    }

    // Actions
    select(day, calendar, e) {
      if (this.onSelect) {
        this.onSelect(day, calendar, e);
      }
    }
    // Methods
    registerCalendar() {
      if (window) {
        window.__powerCalendars = window.__powerCalendars || {}; // TODO: weakmap??
        window.__powerCalendars[this.publicAPI.uniqueId] = this;
      }
    }
    unregisterCalendar() {
      if (window) {
        delete window.__powerCalendars[(0, _internals.guidFor)(this)];
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class3.prototype, "powerCalendarService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class3.prototype, "publicActions", [_dec4], Object.getOwnPropertyDescriptor(_class3.prototype, "publicActions"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "selected", [_object.computed], Object.getOwnPropertyDescriptor(_class3.prototype, "selected"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "currentCenter", [_dec5], Object.getOwnPropertyDescriptor(_class3.prototype, "currentCenter"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "publicAPI", [_dec6], Object.getOwnPropertyDescriptor(_class3.prototype, "publicAPI"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "_publicAPI", [_dec7], Object.getOwnPropertyDescriptor(_class3.prototype, "_publicAPI"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "tagWithDefault", [_dec8], Object.getOwnPropertyDescriptor(_class3.prototype, "tagWithDefault"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "select"), _class3.prototype), _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "changeCenterTask", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3)) || _class2) || _class2);
  _exports.default = _class;
});