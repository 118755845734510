define("ember-svg-jar/inlined/phoneWhite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.884 12.593c-.37-.263-.738-.175-1.015.087l-.462.437-1.477 1.4c-.461.437-1.2.437-1.753 0L6.73 9.881c-.277-.175-.369-.525-.369-.875s.092-.612.37-.874l1.291-1.225.277-.262c.277-.262.277-.7.093-.962L4.423.262C4.333.175 4.148 0 3.87 0c-.184 0-.461 0-.646.175L.917 1.924c-.923.7-1.108 1.836-.739 2.886 3.046 7.695 9.6 13.467 17.906 16.09.923.263 2.03 0 2.676-.7.462-.524 1.57-1.836 1.754-2.01.092-.088.185-.263.277-.35.184-.175.184-.35.184-.613.093-.262-.092-.437-.276-.524l-5.815-4.11z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "23",
      "height": "21",
      "viewBox": "0 0 23 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});