define("ember-svg-jar/inlined/warning-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18.819.564a4.5 4.5 0 015.95 1.48l.167.276 15.517 28.017a4.5 4.5 0 01-3.628 6.67l-.308.01H5.485a4.5 4.5 0 01-4.082-6.393l.146-.287L17.062 2.321A4.5 4.5 0 0118.82.564zm19.01 31.227L22.311 3.774a1.5 1.5 0 00-2.51-.176l-.114.176L4.173 31.791a1.5 1.5 0 001.123 2.214l.19.012h31.03a1.5 1.5 0 001.395-2.055l-.082-.171L22.311 3.774 37.83 31.791zm-16.828-4.787a1.997 1.997 0 110 3.994 1.997 1.997 0 010-3.994zm-.009-15.005a1.5 1.5 0 011.488 1.295l.014.204.007 9.003a1.5 1.5 0 01-2.986.206l-.014-.204-.007-9.003c-.001-.828.67-1.5 1.498-1.5z\" fill=\"#D51E50\"/>",
    "attrs": {
      "width": "42",
      "height": "38",
      "viewBox": "0 0 42 38",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});