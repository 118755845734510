define("ember-file-upload/system/data-transfer", ["exports", "@ember/array"], function (_exports, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const getDataSupport = {};
  class DataTransfer {
    constructor(_ref) {
      let {
        queue,
        source,
        dataTransfer,
        itemDetails
      } = _ref;
      _defineProperty(this, "dataTransfer", null);
      _defineProperty(this, "itemDetails", null);
      _defineProperty(this, "queue", null);
      _defineProperty(this, "source", null);
      this.queue = queue;
      this.source = source;
      this.dataTransfer = dataTransfer;
      this.itemDetails = itemDetails;
    }
    getData(type) {
      let dataTransfer = this.dataTransfer;
      if (getDataSupport[type] == null) {
        try {
          let data = dataTransfer.getData(type);
          getDataSupport[type] = true;
          return data;
        } catch (e) {
          getDataSupport[type] = false;
        }
      } else if (getDataSupport[type]) {
        return dataTransfer.getData(type);
      }
    }
    get files() {
      var _this$dataTransfer, _this$dataTransfer2, _this$queue;
      let fileList = ((_this$dataTransfer = this.dataTransfer) === null || _this$dataTransfer === void 0 ? void 0 : _this$dataTransfer.files) || null;
      let itemList = ((_this$dataTransfer2 = this.dataTransfer) === null || _this$dataTransfer2 === void 0 ? void 0 : _this$dataTransfer2.items) || null;
      let itemDetails = this.itemDetails;
      if (fileList == null && itemList || itemList != null && fileList != null && itemList.length > fileList.length) {
        fileList = itemList;
      }
      if (fileList == null && itemDetails || itemDetails != null && fileList != null && itemDetails.length > fileList.length) {
        fileList = itemDetails;
      }
      if (fileList == null) {
        return null;
      }
      let files = (0, _array.A)();
      if (!((_this$queue = this.queue) !== null && _this$queue !== void 0 && _this$queue.multiple) && fileList.length > 1) {
        files.push(fileList[0]);
      } else {
        for (let i = 0, len = fileList.length; i < len; i++) {
          files.push(fileList[i]);
        }
      }
      return files;
    }
  }
  _exports.default = DataTransfer;
});