define("ember-svg-jar/inlined/gav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"nonzero\"><path d=\"M7.054 1.007C3.294 1.2 2 2.857 2 6.037v10.108s1.86-1.614 5.382-1.614H23.41c3.159 0 6.318-1.042 6.318-4.75V5.593c0-3.266-1.18-4.586-4.937-4.586H7.851c-.273 0-.546-.015-.797 0z\" fill=\"#7F7F7F\"/><path d=\"M29.74 24.83c0 3.394-1.499 5.042-5.862 5.042H6.936C3.179 29.872 2 28.532 2 25.267v-4.199c0-3.708 3.165-4.75 6.323-4.75H24.34c3.52 0 5.4-1.604 5.4-1.604v10.117z\" fill=\"#CDCDCD\"/></g>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});