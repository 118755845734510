define("ember-svg-jar/inlined/notification-icon-failure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 21a9 9 0 100-18 9 9 0 000 18z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.634 8.234a.8.8 0 011.132 1.132L13.13 12l2.635 2.634a.8.8 0 01-1.132 1.132L12 13.13l-2.634 2.635a.8.8 0 01-1.132-1.132L10.87 12 8.234 9.366a.8.8 0 011.132-1.132L12 10.87l2.634-2.635z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});