define("ember-svg-jar/inlined/question-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 12c0 6.628 5.373 12 12 12s12-5.372 12-12S18.627 0 12 0 0 5.372 0 12zm21.807 0c0 5.415-4.39 9.805-9.807 9.805-5.417 0-9.807-4.39-9.807-9.805S6.583 2.195 12 2.195c5.417 0 9.807 4.39 9.807 9.805zm-11.525 2.244v-.605c0-.523.111-.978.334-1.364.223-.387.632-.798 1.225-1.234.571-.409.948-.741 1.13-.997.182-.256.274-.542.274-.858 0-.354-.131-.624-.392-.809-.261-.185-.626-.278-1.094-.278-.816 0-1.747.267-2.792.801l-.89-1.79a7.77 7.77 0 013.862-1.02c1.12 0 2.012.269 2.673.808.661.54.992 1.258.992 2.157 0 .6-.136 1.117-.409 1.553-.272.435-.789.926-1.55 1.47-.523.387-.853.681-.992.883-.139.201-.208.466-.208.793v.49h-2.163zM10.388 16c-.245.235-.368.58-.368 1.038 0 .441.126.783.376 1.026.25.242.604.363 1.061.363.446 0 .794-.124 1.045-.372.25-.247.375-.587.375-1.017 0-.447-.123-.79-.371-1.03-.248-.239-.597-.359-1.049-.359-.468 0-.824.117-1.07.351z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});