define("ember-svg-jar/inlined/checkboxChecked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path d=\"M4 0h16a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4a4 4 0 014-4z\" id=\"checkboxChecked_svg__a\"/><path d=\"M11.654.311a.932.932 0 011.392.002c.384.416.383 1.09-.002 1.505L5.755 9.69a.932.932 0 01-1.384.006L.295 5.367A1.127 1.127 0 01.282 3.86a.932.932 0 011.391-.014l3.382 3.59 6.6-7.126z\" id=\"checkboxChecked_svg__c\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"checkboxChecked_svg__b\" fill=\"#fff\"><use xlink:href=\"#checkboxChecked_svg__a\"/></mask><use fill=\"#2B6CDE\" fill-rule=\"nonzero\" xlink:href=\"#checkboxChecked_svg__a\"/><g mask=\"url(#checkboxChecked_svg__b)\" fill=\"#2B6CDE\"><path d=\"M0 0h25v24H0z\"/></g><g mask=\"url(#checkboxChecked_svg__b)\"><g transform=\"translate(5.333 7)\"><mask id=\"checkboxChecked_svg__d\" fill=\"#fff\"><use xlink:href=\"#checkboxChecked_svg__c\"/></mask><use stroke=\"#2B6CDE\" stroke-width=\".2\" fill=\"#979797\" fill-rule=\"nonzero\" xlink:href=\"#checkboxChecked_svg__c\"/><g mask=\"url(#checkboxChecked_svg__d)\" fill=\"#FFF\" stroke=\"#DFDFDF\"><path d=\"M-6.167-8.667h26v26h-26z\"/></g></g></g></g>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});