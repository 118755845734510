define("ember-svg-jar/inlined/devkvitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"devkvitar_svg__a\" d=\"M0 64.81h260V0H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#063\" fill-rule=\"nonzero\" cx=\"190\" cy=\"190\" r=\"190\"/><g transform=\"translate(60 158)\"><mask id=\"devkvitar_svg__b\" fill=\"#fff\"><use xlink:href=\"#devkvitar_svg__a\"/></mask><path d=\"M19.082 53.083l3.387.035h2.962c2.177-.078 4.44-.078 6.615-.668 7.231-1.658 8.97-6.475 9.751-11.537.436-3.322.611-5.707.611-8.95 0-3.314-.09-6.639-.698-9.956-.435-2.74-1.39-5.568-3.22-7.557-3.39-3.651-8.704-3.571-12.45-3.571h-6.958v42.204zM0 64.808V0h29.058c7.026 0 18.054.096 24.431 6.974 6.202 6.797 6.746 18.636 6.746 25.244 0 27.71-14.51 32.686-33.472 32.59H0zm118.182.002H73.196V0h42.763v10.997h-24.75v13.808h23.94V35.57H91.203v16.45h26.979v12.79m53.54 0h-27.026L126.569 0h19.29l10.06 40.94c1.19 4.68 1.471 5.96 2.475 11.656 1.231-6.05 1.64-7.09 2.739-11.194L172.194 0h17.66l-18.132 64.81m65.973-41.12L260 64.81h-18.821L226.45 37.267l-7.923 9.669v17.872h-18.762V0h18.762v26.892L238.803 0h18.424l-19.532 23.69\" fill=\"#FFF\" mask=\"url(#devkvitar_svg__b)\"/></g></g>",
    "attrs": {
      "width": "380",
      "height": "380",
      "viewBox": "0 0 380 380",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});