define("ember-svg-jar/inlined/phoneIcon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12.158 9.594c-.266-.2-.532-.133-.731.067l-.333.333-1.063 1.066c-.332.333-.864.333-1.263 0L4.848 7.53c-.2-.133-.267-.4-.267-.666 0-.267.067-.467.266-.667l.93-.932.2-.2c.2-.2.2-.533.066-.733L3.186.2c-.067-.067-.2-.2-.4-.2-.132 0-.331 0-.464.133L.66 1.466c-.665.533-.797 1.399-.532 2.199 2.194 5.863 6.912 10.26 12.894 12.259.664.2 1.462 0 1.927-.533.333-.4 1.13-1.4 1.263-1.532.066-.067.133-.2.2-.267.132-.133.132-.267.132-.466.067-.2-.066-.334-.2-.4l-4.186-3.132z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "17",
      "height": "16",
      "viewBox": "0 0 17 16"
    }
  };
  _exports.default = _default;
});