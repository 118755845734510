define("ember-svg-jar/inlined/icon-nav-concierge-reward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.75 9.75a.75.75 0 000 1.5h.801a5.48 5.48 0 000 1.5H6.75a.75.75 0 000 1.5h1.237c.805 1.763 2.528 3 4.559 3 1.045 0 2.053-.278 2.897-.894a.75.75 0 00-.886-1.212c-.547.4-1.232.606-2.011.606-1.147 0-2.185-.584-2.838-1.5H12a.75.75 0 000-1.5H9.07a3.997 3.997 0 010-1.5H12a.75.75 0 000-1.5H9.708c.653-.916 1.69-1.5 2.838-1.5.779 0 1.464.205 2.011.606a.75.75 0 00.886-1.212c-.844-.616-1.852-.894-2.897-.894-2.031 0-3.754 1.237-4.56 3H6.75z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zM21 12a9 9 0 11-18 0 9 9 0 0118 0z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});