define("ember-cli-resolve-asset/-private/asset-map-path", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Retrieves the (hashed) asset map URL from the `<link>` tag that was injected
   * into the `<head>` by `index.js`.
   */
  var _default = (() => {
    const link = document.getElementById('ember-cli-resolve-asset-asset-map-path');
    if (link) return link.getAttribute('href');
    return null;
  })();
  _exports.default = _default;
});