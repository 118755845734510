define("ember-svg-jar/inlined/puzzle-piece", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.753 3.208a3.601 3.601 0 00-3.32 3.103l-.01.09-4.02.001-.232.011a2.4 2.4 0 00-2.168 2.39l-.001 3.622-.092.01a3.6 3.6 0 00-3.11 3.567l.008.247a3.601 3.601 0 003.103 3.32l.09.009.002 3.624.01.232a2.4 2.4 0 002.39 2.168h3.223l.01.088a3.6 3.6 0 003.567 3.11l.246-.008a3.601 3.601 0 003.32-3.104l.009-.086h4.025a2.4 2.4 0 002.4-2.4l-.002-5.204L24.4 18l-.196-.009c-1-.082-1.803-.948-1.803-1.991l.009-.197c.082-1 .948-1.803 1.99-1.803l2.802-.002.002-5.195-.011-.232a2.4 2.4 0 00-2.39-2.169h-3.226l-.009-.09A3.6 3.6 0 0018 3.2l-.247.008zM18 4.8a2 2 0 012 2V8l4.802.001.145.013a.8.8 0 01.656.787l-.002 3.596-1.202.002a3.6 3.6 0 00-3.587 3.306l-.011.262c-.001 1.91 1.443 3.469 3.305 3.62l.262.011 1.234-.002v3.605a.8.8 0 01-.8.8H19.2l.004 1.2a2 2 0 01-4-.002L15.199 24l-4.796.001a.8.8 0 01-.8-.8L9.6 18l-1.203.002a2 2 0 01.002-4L9.6 14l.002-5.197a.8.8 0 01.8-.8L16 8V6.8a2 2 0 012-2z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});