define("ember-svg-jar/inlined/rente-screen-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#01051C\" d=\"M53.146 1.346h-47.6a4.194 4.194 0 00-4.2 4.2v61.6c0 2.324 1.876 4.2 4.2 4.2h47.6c2.324 0 4.2-1.876 4.2-4.2v-61.6c0-2.324-1.876-4.2-4.2-4.2zm1.4 65.8c0 .784-.616 1.4-1.4 1.4h-47.6c-.784 0-1.4-.616-1.4-1.4v-61.6c0-.784.616-1.4 1.4-1.4h47.6c.784 0 1.4.616 1.4 1.4v61.6zm-5.6-53.2c0 .784-.616 1.4-1.4 1.4h-36.4c-.784 0-1.4-.616-1.4-1.4 0-.784.616-1.4 1.4-1.4h36.4c.784 0 1.4.616 1.4 1.4zm-30.8 15.4c0 1.54-1.26 2.8-2.8 2.8s-2.8-1.26-2.8-2.8 1.26-2.8 2.8-2.8 2.8 1.26 2.8 2.8zm14 0c0 1.54-1.26 2.8-2.8 2.8s-2.8-1.26-2.8-2.8 1.26-2.8 2.8-2.8 2.8 1.26 2.8 2.8zm14 0c0 1.54-1.26 2.8-2.8 2.8s-2.8-1.26-2.8-2.8 1.26-2.8 2.8-2.8 2.8 1.26 2.8 2.8zm-28 14c0 1.54-1.26 2.8-2.8 2.8s-2.8-1.26-2.8-2.8 1.26-2.8 2.8-2.8 2.8 1.26 2.8 2.8zm14 0c0 1.54-1.26 2.8-2.8 2.8s-2.8-1.26-2.8-2.8 1.26-2.8 2.8-2.8 2.8 1.26 2.8 2.8zm14 0c0 1.54-1.26 2.8-2.8 2.8s-2.8-1.26-2.8-2.8 1.26-2.8 2.8-2.8 2.8 1.26 2.8 2.8zm-28 14c0 1.54-1.26 2.8-2.8 2.8s-2.8-1.26-2.8-2.8 1.26-2.8 2.8-2.8 2.8 1.26 2.8 2.8zm14 0c0 1.54-1.26 2.8-2.8 2.8s-2.8-1.26-2.8-2.8 1.26-2.8 2.8-2.8 2.8 1.26 2.8 2.8zm14 0c0 1.54-1.26 2.8-2.8 2.8s-2.8-1.26-2.8-2.8 1.26-2.8 2.8-2.8 2.8 1.26 2.8 2.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "81.515",
      "height": "101.894",
      "viewBox": "-11.236 -14.045 81.515 101.894"
    }
  };
  _exports.default = _default;
});