define("ember-svg-jar/inlined/appointmentConfirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g stroke-width=\"4\" stroke=\"#2B6CDE\" class=\"svg-brand-primary-stroke\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M71.526 48.36l5.8 5.523 12.428-14.2\"/><path d=\"M82.677 67.193c-.9-.676-1.798-.45-2.472.225l-1.124 1.125-3.595 3.601c-1.123 1.126-2.921 1.126-4.27 0l-.66-.594-11.041-9.935-1.556-1.4c-.674-.45-.899-1.35-.899-2.25 0-.9.225-1.576.9-2.251l3.145-3.151.674-.676c.674-.675.674-1.8.225-2.475l-9.662-13.955c-.225-.225-.674-.675-1.348-.675-.45 0-1.124 0-1.573.45l-5.618 4.501c-2.247 1.8-2.696 4.727-1.798 7.428 7.416 19.807 23.37 34.662 43.593 41.414 2.247.675 4.943 0 6.516-1.8 1.124-1.351 3.82-4.727 4.27-5.177.224-.225.449-.676.673-.9.45-.45.45-.9.45-1.576.225-.675-.225-1.126-.674-1.35l-14.156-10.58z\"/></g><g stroke=\"#D6D6D6\" stroke-width=\"2.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M88.986 22.054l-6.013 3.236 1.148-6.853-4.864-4.854 6.724-1.001 3.005-6.235 3.006 6.235 6.724 1-4.864 4.855L95 25.29zM56.585 101.84l-4.422 5.267-1.946-6.653-5.146-4.526 6.5-1.992 4.422-5.267 1.946 6.654 5.146 4.525zM29.19 28.036c3.39 0 6.138-2.813 6.138-6.283s-2.748-6.283-6.139-6.283c-3.39 0-6.138 2.813-6.138 6.283s2.748 6.283 6.138 6.283h0zM83.834 107.981h0L79.817 135l-13.3-5.236 10.547-22.13M21.748 66.586L1.96 72.104l5.627 9.746zM39.562 78h0l-13.849 7.937-6.138 20.943L39.59 84.41M25.678 47.402l-14.323-9.424 8.185 12.565zM111.672 84.68l17.391 1.048-17.391 8.377zM108.439 63.904l31.492-20.146 2.046 15.707-33.134 12.347M101.138 40.74l14.74-15.088 5.528 8.487-15.661 10.373zM50.636 18.759h0l-2.998-15.78 13.3-1.048-2.593 16.366M105.707 119.446c3.39 0 6.138-2.813 6.138-6.283s-2.748-6.283-6.138-6.283c-3.39 0-6.138 2.813-6.138 6.283s2.748 6.283 6.138 6.283h0z\"/></g></g>",
    "attrs": {
      "width": "144",
      "height": "137",
      "viewBox": "0 0 144 137",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});