define("ember-file-upload/components/file-upload", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "ember-file-upload/services/file-queue", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _service, _object, _fileQueue, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <label
    for={{this.for}}
    class="file-upload"
    ...attributes
    {{did-insert this.attachListeners}}
  >
    <input
      id={{this.for}}
      type="file"
      accept={{@accept}}
      capture={{@capture}}
      multiple={{@multiple}}
      disabled={{@disabled}}
      hidden
      {{this.queue.selectFile filter=@filter filesSelected=@filesSelected}}
    />
  
    {{yield this.queue}}
  </label>
  
  
  */
  {
    "id": "gOtRkZuJ",
    "block": "[[[11,\"label\"],[16,\"for\",[30,0,[\"for\"]]],[24,0,\"file-upload\"],[17,1],[4,[38,0],[[30,0,[\"attachListeners\"]]],null],[12],[1,\"\\n  \"],[11,\"input\"],[16,1,[30,0,[\"for\"]]],[16,\"accept\",[30,2]],[16,\"capture\",[30,3]],[16,\"multiple\",[30,4]],[16,\"disabled\",[30,5]],[24,\"hidden\",\"\"],[24,4,\"file\"],[4,[30,0,[\"queue\",\"selectFile\"]],null,[[\"filter\",\"filesSelected\"],[[30,6],[30,7]]]],[12],[13],[1,\"\\n\\n  \"],[18,8,[[30,0,[\"queue\"]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"&attrs\",\"@accept\",\"@capture\",\"@multiple\",\"@disabled\",\"@filter\",\"@filesSelected\",\"&default\"],false,[\"did-insert\",\"yield\"]]",
    "moduleName": "ember-file-upload/components/file-upload.hbs",
    "isStrictMode": false
  });
  /**
   * `FileUpload` is a component that will users to upload files using
   * their browser's file chooser.
   *
   * @example
   *
   * old-fashioned usage:
   *
   * ```hbs
   * <FileUpload
   *   @name="photos"
   *   @accept="image/*"
   *   @multiple=true
   *   @onFileAdd={{perform this.uploadImage}}
   *   as |queue|
   * >
   *   <a tabindex="0">Add an image.</a>
   *   {{#if queue.files.length}}
   *     Uploading {{queue.files.length}} files. ({{queue.progress}}%)
   *   {{/if}}
   * </FileUpload>
   * ```
   *
   * ```js
   * import Component from '@glimmer/component';
   * import { task } from 'ember-concurrency';
  
   * export default class ExampleComponent extends Component {
   *   @task({ maxConcurrency: 3, enqueue: true })
   *   *uploadImage(file) {
   *     const response = yield file.upload(url, options);
   *     ...
   *   }
   * }
   * ```
   *
   * @deprecated use `{{file-queue}}` helper with `{{queue.selectFile}}` modifier
   */
  let FileUploadComponent = (_class = class FileUploadComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fileQueue", _descriptor, this);
    }
    get queue() {
      if (this.args.queue) {
        return this.args.queue;
      }
      if (this.args.name) {
        return this.fileQueue.findOrCreate(this.args.name);
      }
      return this.fileQueue.findOrCreate(_fileQueue.DEFAULT_QUEUE);
    }
    get for() {
      var _this$args$for;
      return (_this$args$for = this.args.for) !== null && _this$args$for !== void 0 ? _this$args$for : "file-input-".concat((0, _internals.guidFor)(this));
    }
    attachListeners() {
      this.queue.addListener(this);
    }
    fileAdded(file) {
      var _this$args$onFileAdd, _this$args;
      (_this$args$onFileAdd = (_this$args = this.args).onFileAdd) === null || _this$args$onFileAdd === void 0 ? void 0 : _this$args$onFileAdd.call(_this$args, file);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fileQueue", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "attachListeners", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "attachListeners"), _class.prototype)), _class);
  _exports.default = FileUploadComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FileUploadComponent);
});