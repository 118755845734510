define("ember-lifeline-decorators/utils/private-alias", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = privateAlias;
  // eslint-disable-next-line @typescript-eslint/ban-types
  function privateAlias(target, key, desc, makeValue) {
    const privateKey = "__".concat(String(key), "-").concat(Math.random().toString(36).slice(2));
    Object.defineProperty(target, privateKey, {
      ...desc,
      configurable: false,
      enumerable: false,
      writable: false
    });
    return {
      ...desc,
      value: makeValue(privateKey)
    };
  }
});