define("ember-svg-jar/inlined/heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M21.736 3.793c.178.615.267 1.25.264 1.887 0 6.517-11.5 13.601-11.5 13.601a42.288 42.288 0 01-8.307-6.968C5.132 17.45 12.5 22.041 12.5 22.041S24 14.957 24 8.44c.124-1.798-.723-3.536-2.264-4.647z\" fill=\"#DADDEC\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.505 5.081C13.633 3.865 15.803 2 18.5 2 22.388 2 25 4.588 25 8.44c0 6.87-11.278 14.007-11.92 14.414l-.013.008-.57.298-.568-.364a3.047 3.047 0 00-.025-.015C11.143 22.294 0 15.191 0 8.44 0 4.588 2.612 2 6.5 2c2.725 0 4.884 1.863 6.005 3.081zM24 8.44c0-3.22-2-5.52-5.5-5.52-3.417 0-6 3.68-6 3.68s-2.542-3.68-6-3.68C3 2.92 1 5.22 1 8.44c0 6.44 11.5 13.601 11.5 13.601S24 14.957 24 8.44z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});