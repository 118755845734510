define("ember-svg-jar/inlined/icon-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M45.92 2.24h-3.36v45.92a1.12 1.12 0 01-1.12 1.12H8.96v3.36c0 .618.501 1.12 1.12 1.12h35.84a1.12 1.12 0 001.12-1.12V3.36a1.12 1.12 0 00-1.12-1.12z\" fill=\"#DADDEC\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M45.92 0H10.08a3.36 3.36 0 00-3.36 3.36v49.28A3.36 3.36 0 0010.08 56h35.84a3.36 3.36 0 003.36-3.36V3.36A3.36 3.36 0 0045.92 0zm1.12 52.64a1.12 1.12 0 01-1.12 1.12H10.08a1.12 1.12 0 01-1.12-1.12V3.36c0-.619.501-1.12 1.12-1.12h35.84c.619 0 1.12.501 1.12 1.12v49.28z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.219 14.875H29.53c.604 0 1.094-.392 1.094-.875s-.49-.875-1.094-.875H14.22c-.604 0-1.094.392-1.094.875s.49.875 1.094.875zM39.207 22.75H14.168c-.576 0-1.043.392-1.043.875s.467.875 1.043.875h25.039c.576 0 1.043-.392 1.043-.875s-.467-.875-1.043-.875zM39.207 31.5H14.168c-.576 0-1.043.392-1.043.875s.467.875 1.043.875h25.039c.576 0 1.043-.392 1.043-.875s-.467-.875-1.043-.875zM39.207 40.25H14.168c-.576 0-1.043.392-1.043.875s.467.875 1.043.875h25.039c.576 0 1.043-.392 1.043-.875s-.467-.875-1.043-.875z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});