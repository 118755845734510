define("ember-svg-jar/inlined/private-pension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M10.12 2.448C11.195 1.364 12.556 1 13.5 1h.023l.022.002c2.239.204 3.573 1.309 4.298 2.665 1.084.157 2.128.421 3.117.78a22.35 22.35 0 012.282-1.157c1.002-.427 2.159-.79 3.258-.79h.548l-.51 5.614A11.443 11.443 0 0128.9 12.01a.498.498 0 01.257.016l1.5.5A.5.5 0 0131 13v3.5a.5.5 0 01-.342.474l-1.335.445c-.508 2.723-2.079 5.123-4.323 6.897V27.5c0 .707-.178 1.265-.531 1.666-.353.4-.822.579-1.275.642-.877.122-1.852-.167-2.352-.334a1.94 1.94 0 01-.884-.567 1.862 1.862 0 01-.396-.823 3.835 3.835 0 01-.075-.828c0-.084.002-.17.004-.252a16.164 16.164 0 01-3.99.496 16.18 16.18 0 01-4-.498v.498c0 1.015-.547 1.663-1.189 2.033C9.695 29.89 8.977 30 8.5 30 6.992 30 6 28.743 6 27.5v-3.184C3.241 22.135 1.5 19.01 1.5 15.5c0-1.652.41-3.219 1.136-4.642l-1.413-.942a.5.5 0 01.554-.832l1.354.902c1.29-2.056 3.255-3.755 5.565-4.894.28-1.142.793-2.007 1.424-2.644zM8.5 6.324c-3.568 1.99-6 5.382-6 9.176 0 6.003 5.742 11 13 11s13-4.997 13-11c0-5.21-4.318-9.658-10.213-10.747a7.656 7.656 0 01.206 3.33l-.07.417H8.538l-.035-.462A10.72 10.72 0 018.5 6.324zM7 25.036V27.5c0 .757.608 1.5 1.5 1.5.356 0 .888-.089 1.313-.333.4-.23.687-.582.687-1.167v-.79A14.822 14.822 0 017 25.036zm13.498 1.675l-.004.177v.002c-.003.128-.006.248-.006.366 0 .238.012.439.05.61a.867.867 0 00.18.39c.081.096.212.194.44.27.5.166 1.275.378 1.898.291.297-.041.515-.144.663-.311.146-.167.281-.463.281-1.006v-2.464a14.827 14.827 0 01-3.502 1.675zm8.97-10.394L30 16.14v-2.78l-.778-.26a10.434 10.434 0 01.246 3.217zm-3.852-9.114l.333-3.668c-.75.09-1.55.35-2.316.675-.538.23-1.046.485-1.49.725a14.073 14.073 0 013.473 2.268zM9.473 7.5h8.087c.084-.906-.005-2.1-.488-3.139-.544-1.167-1.596-2.172-3.594-2.36-.722.005-1.798.294-2.648 1.151-.79.797-1.436 2.138-1.357 4.348zM23 11.5a.5.5 0 100 1 .5.5 0 000-1zm-1.5.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});