define("ember-svg-jar/inlined/icon-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.315 17.02c-1.276-.702-4.01-2.183-5.557-2.86a9 9 0 001.956-5.76c0-3.728-2.11-5.4-4.714-5.4-2.604 0-4.714 1.672-4.714 5.4a9.004 9.004 0 001.95 5.754c-1.555.673-4.282 2.16-5.555 2.865-.42.234-.682.693-.681 1.192v.644c-.002.717.5 1.326 1.176 1.426A62.1 62.1 0 0012 21a62.1 62.1 0 007.824-.72c.676-.099 1.178-.708 1.176-1.425v-.641c.001-.5-.263-.96-.685-1.193z\" stroke=\"#17203D\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});