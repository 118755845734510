define("ember-svg-jar/inlined/info-mark-outlined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 9a9 9 0 1018 0A9 9 0 000 9zm16.5-.002A7.5 7.5 0 019 16.497 7.5 7.5 0 119 1.5a7.5 7.5 0 017.5 7.498zM9.05 4.94c.439 0 .818.364.818.828 0 .455-.38.819-.817.819a.823.823 0 110-1.646zm-.826 7.644c0 .45.333.795.77.795a.772.772 0 00.784-.795v-4.39a.76.76 0 00-.772-.784.763.763 0 00-.782.783v4.391z\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "#203151",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});