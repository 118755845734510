define("ember-svg-jar/inlined/loupe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.312 17.372a9 9 0 111.06-1.06l4.908 4.908a.75.75 0 11-1.06 1.06l-4.908-4.908zM18 10.5a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});