define("ember-svg-jar/inlined/graphic-risk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.749 0h4.554l.1.014.099.028.06.026c.08.034.153.085.219.15l.04.044.044.057.054.09.039.09.019.064.014.064.009.095v4.532a.75.75 0 01-1.493.102l-.007-.102V2.559l-6.44 6.44a.75.75 0 01-.976.073L10 9 6.97 6.09l-5.69 5.689a.75.75 0 01-1.133-.977l.073-.084 6.22-6.22a.75.75 0 01.976-.072l.084.072 3.03 2.91L16.438 1.5h-2.69a.75.75 0 01-.742-.648L12.999.75a.75.75 0 01.648-.743L13.75 0zM.75 15a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 01.75 15zm5.75-3.25a.75.75 0 00-1.5 0v7.5a.75.75 0 001.5 0v-7.5zM10.75 13a.75.75 0 01.75.75v5.5a.75.75 0 01-1.5 0v-5.5a.75.75 0 01.75-.75zm5.75-4.25a.75.75 0 00-1.5 0v10.5a.75.75 0 001.5 0V8.75z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "35",
      "height": "35",
      "viewBox": "0 0 35 35",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});