define("ember-svg-jar/inlined/no-paper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.717 5l-3.4.457a.25.25 0 01-.282-.275L11.5 1m3.217 4v10.15a.35.35 0 01-.35.35h-10.3a.35.35 0 01-.35-.35V1.35a.35.35 0 01.35-.35H11.5m3.217 4L11.5 1\" stroke=\"#575970\" stroke-width=\"1.25\"/><path d=\"M2 1.5l14 15\" stroke=\"#F5F6FA\" stroke-width=\"3.5\" stroke-linecap=\"round\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.574 1.043a.625.625 0 00-.03.883l14 15a.625.625 0 10.913-.852l-14-15a.625.625 0 00-.883-.03z\" fill=\"#575970\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});