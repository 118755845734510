define("ember-svg-jar/inlined/thumbs-up-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" fill=\"#F5F6FA\"/><path d=\"M20.76 9.802c.205-.52.801-1.058 1.559-.847 1.524.425 2.102 2.045 2.26 3.562.135 1.296-.001 2.765-.297 4.054h1.857a3.214 3.214 0 013.15 3.854l-1.052 5.182a5.786 5.786 0 01-7.26 4.412l-6.591-1.884a3.214 3.214 0 01-2.287-2.562l-.357-2.14c-.189-1.132.468-2.166 1.413-2.644 4.092-2.073 5.662-6.057 7.381-10.421l.223-.566zm1.226.418a.207.207 0 00-.031.055l-.27.687c-1.67 4.249-3.408 8.674-7.95 10.974-.525.265-.807.794-.725 1.286l.357 2.14a1.929 1.929 0 001.372 1.537l6.591 1.883a4.5 4.5 0 005.647-3.43l1.052-5.183a1.929 1.929 0 00-1.89-2.313h-2.692a.643.643 0 01-.616-.83c.401-1.32.614-2.99.47-4.376-.15-1.434-.632-2.242-1.298-2.447a.16.16 0 00-.017.017z\" fill=\"#203151\"/><rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#CFD3DA\"/>",
    "attrs": {
      "width": "41",
      "height": "40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});