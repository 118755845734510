define("ember-svg-jar/inlined/newPlusDocument", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#FFF\" stroke-width=\"3\" fill=\"none\" fill-rule=\"evenodd\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" d=\"M2.013 3.507l25.984-.908 14.687-.513.481 13.774 1.2 34.333-40.672 1.42z\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" d=\"M10.462 10.047l27.602 4.372 15.602 2.47-2.218 14.004-5.529 34.904-43.203-6.842z\"/><g transform=\"translate(16.373 24.296)\"><ellipse cx=\"12.596\" cy=\"12.511\" rx=\"12.596\" ry=\"12.511\"/><g stroke-linecap=\"round\"><path d=\"M18.254 12.522l-11.38-.039M12.545 6.812l.038 11.38\"/></g></g></g>",
    "attrs": {
      "width": "59",
      "height": "71",
      "viewBox": "0 0 59 71",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});