define("ember-svg-jar/inlined/residential-building-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M15.659 1.634a.5.5 0 01.67-.01l4 3.5a.5.5 0 01-.658.752l-3.66-3.202L1.34 16.366a.5.5 0 01-.682-.732l15-14zm10.297 9.038a.5.5 0 01.706-.049l5.166 4.5a.5.5 0 11-.656.754l-5.167-4.5a.5.5 0 01-.049-.705z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M28 12v16c0 .766-.119 1.326-.459 1.666-.338.338-.781.335-1.005.334H6c-.729 0-1.261-.26-1.596-.695C4.087 28.895 4 28.396 4 28V13h1v15c0 .27.063.522.196.695.115.15.333.305.804.305h20.5c.29 0 .316-.023.334-.041.035-.035.166-.225.166-.959V12h1z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M16.632 19.718a.702.702 0 00-.132.393V29h-1v-8.889c0-.27.08-.66.335-.996.271-.36.712-.615 1.332-.615h4.666c.27 0 .667.073 1.012.31.372.255.655.683.655 1.301V29a.5.5 0 01-1 0v-8.889c0-.27-.106-.398-.22-.476a.851.851 0 00-.447-.135h-4.666c-.314 0-.457.115-.535.218zM7.5 19a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H8a.5.5 0 01-.5-.5v-4zm1 .5v3h3v-3h-3zM25.696 2.04a.5.5 0 01.3.515l-.46 4.154 1.688.844a.5.5 0 01.146.783l-5 5.5a.5.5 0 01-.866-.398l.456-3.649-1.684-.842a.5.5 0 01-.16-.767l5-6a.5.5 0 01.58-.14zm-4.408 6.295l1.436.718a.5.5 0 01.272.509l-.304 2.434 3.494-3.844-1.41-.705a.5.5 0 01-.273-.502l.316-2.847-3.53 4.237z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});