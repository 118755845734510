define("ember-svg-jar/inlined/retirementInfo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#FFF\" opacity=\".4\" cx=\"8\" cy=\"8\" r=\"8\"/><circle fill=\"#17203D\" cx=\"8\" cy=\"4\" r=\"1\"/><rect fill=\"#17203D\" x=\"7\" y=\"6\" width=\"2\" height=\"7\" rx=\"1\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});