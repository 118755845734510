define("ember-svg-jar/inlined/email-tick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M54.76 17.478H4.44a4.472 4.472 0 00-3.14 1.279 4.33 4.33 0 00-1.3 3.09v34.957c0 2.413 1.988 4.37 4.44 4.37h50.32c2.452 0 4.44-1.957 4.44-4.37V21.848a4.33 4.33 0 00-1.3-3.091 4.472 4.472 0 00-3.14-1.279zm0 2.913a1.47 1.47 0 011.48 1.457v2.776L31.197 40.415a3 3 0 01-3.194 0L2.96 24.624v-2.776a1.47 1.47 0 011.48-1.457h50.32zm1.48 36.413c0 .804-.663 1.456-1.48 1.456H4.44c-.817 0-1.48-.652-1.48-1.456v-28.72l23.446 14.784a6.002 6.002 0 006.388 0L56.24 28.083v28.72zM73.566.426a1.497 1.497 0 00-2.092 0L60.589 11.14l-4.882-3.52a1.497 1.497 0 00-2.086.187 1.441 1.441 0 00.19 2.052l5.92 4.37a1.497 1.497 0 001.994-.09l11.84-11.652a1.44 1.44 0 00.001-2.06z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "74",
      "height": "62",
      "viewBox": "0 0 74 62",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});