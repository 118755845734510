define("ember-svg-jar/inlined/icon-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.754 14a2.249 2.249 0 012.249 2.249v.575c0 .894-.32 1.76-.901 2.439C17.532 21.096 15.145 22 12 22c-3.146 0-5.532-.905-7.098-2.74a3.75 3.75 0 01-.898-2.434v-.578a2.249 2.249 0 012.248-2.25h11.502zm0 1.5H6.252a.749.749 0 00-.748.749v.578c0 .535.19 1.053.539 1.46 1.253 1.468 3.219 2.214 5.957 2.214s4.706-.746 5.962-2.214a2.25 2.25 0 00.541-1.463v-.575a.749.749 0 00-.749-.75zM12 2.005a5 5 0 110 10 5 5 0 010-10zm0 1.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});