define("ember-cli-resolve-asset/-private/asset-map-loader", ["exports", "fetch", "ember-cli-resolve-asset/-private/asset-map-path"], function (_exports, _fetch, _assetMapPath) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assetMap = void 0;
  _exports.load = load;
  let promise;
  const assetMap = {
    assets: null,
    prepend: null,
    enabled: null
  };
  _exports.assetMap = assetMap;
  async function fetchAssetMap() {
    if (_assetMapPath.default) {
      const response = await (0, _fetch.default)(_assetMapPath.default);
      const json = await response.json();
      assetMap.assets = false /* DEBUG */ ? Object.freeze(json.assets) : json.assets;
      assetMap.prepend = json.prepend;
      assetMap.enabled = true;
    } else {
      assetMap.enabled = false;
    }
    return assetMap;
  }

  // Not using `async` here so that `load` can return the same `Promise` instance
  // with each invocation.
  /* async */function load() {
    if (promise) return promise;
    promise = fetchAssetMap();
    return promise;
  }
});