// @Legecy
// need to move the window functions into services, they ARE used in the client
// element in viewport is also mixed with fixedElementScroll so we may need to merge
// these funcs into a shared service
// the animate numbers is used in the new quality score modals in the client
// track is used to track events, I am sure we use it both in rails and ember should
// also be moved into the existing tracking service, I think we already did most of the qwork for this
// date picker functionlaity also needs to be moved into services
// in general this needs to be picked appart and moved into services, but 90% of
// this is used in the client, they should have been put in modules in the first
// place

var datePickerClickEvent, datePickerConvertFormat, isTouchDevice, runDatePicker;

isTouchDevice = function () {
  return !!document.createTouch;
};

// Function to get a url parameter
window.getParameterByName = function (name) {
  var regex, results;
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  results = regex.exec(location.search);
  if (results === null) {
    return '';
  } else {
    return decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
};

datePickerClickEvent = function ($picker, android) {
  var $inputs;
  $inputs = $('input');
  return $picker.on('click focusin', function () {
    var $currentInput, index;
    if (!$picker.hasClass('picked')) {
      $picker.attr('placeholder', '');
      if ($picker.attr('id') === 'mandate_birthdate') {
        $picker.val('1980-01-01');
      }
      // get previous input and focus on it
      $currentInput = $('input:focus');
      index = $inputs.index($currentInput);
      $inputs.eq(index - 1).focus();
      $picker.addClass('picked');
      if (!android) {
        $picker.attr('type', 'date');
      }
      return $picker.focus();
    }
  });
};

datePickerConvertFormat = function ($picker) {
  var parts, pickerVal;
  pickerVal = $picker.val();
  parts = pickerVal.split('.');
  $picker.val([parts[2], parts[1], parts[0]].join('-'));
  $picker.attr('type', 'date');
  return $picker.attr('placeholder', '');
};

// @TODO remove: https://clarkteam.atlassian.net/browse/JCLARK-74029
// This can be called on window load of for example from a window event fired from ember
runDatePicker = function () {
  var $datepickers, $picker, android, defaultDate, i, iOS, pickerVal, results1;
  // on touch devices use the default date picker, otherwise init the datepicker lib
  $datepickers = $('.datepicker-input');
  if ($datepickers.length) {
    if (!isTouchDevice()) {
      // show all of the date picker icons (if not touch screen)
      $('span.datepicker').addClass('datepicker--icon-visible');
    }
    i = 0;
    android = $('.body--mobile-android').length;
    iOS = $('.body--mobile-ios').length;
    results1 = [];
    while (i < $datepickers.length) {
      $picker = $($datepickers[i]);
      pickerVal = $picker.val();
      if (isTouchDevice()) {
        if ($picker.attr('id') === 'mandate_birthdate') {
          // iOS only logic for date pickers
          if (iOS) {
            if (pickerVal !== '') {
              datePickerConvertFormat($picker, false);
            } else {
              datePickerClickEvent($picker);
            }
          }
        } else {
          //            since for now its text, commenting this out for a quick fix
          //            $picker.attr('type', 'date')
          $picker.attr('type', 'date');
        }
      } else {
        defaultDate =
          $picker.attr('id') === 'mandate_birthdate' ? '01.01.1980' : null;
        $picker.datepicker({
          startView: 2,
          weekStart: 1,
          language: 'de-DE',
          date: defaultDate,
          days: [
            'Sonntag',
            'Montag',
            'Dienstag',
            'Mittwoch',
            'Donnerstag',
            'Freitag',
            'Samstag'
          ],
          daysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          daysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
          months: [
            'Januar',
            'Februar',
            'März',
            'April',
            'Mai',
            'Juni',
            'Juli',
            'August',
            'September',
            'Oktober',
            'November',
            'Dezember'
          ],
          monthsShort: [
            'Jan',
            'Feb',
            'Mär',
            'Apr',
            'Mai',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dez'
          ],
          format: 'dd.mm.yyyy',
          autoHide: true,
          trigger:
            ".datepicker[data-picker-for='" + $picker.attr('data-picker') + "']"
        });
      }
      results1.push(i++);
    }
    return results1;
  }
};

// Event that can be fired from ember
window.setupDatePicker = function () {
  // Removed timeout as part of JCLARK-80425
  return runDatePicker();
};

window.setupDatePickerImmediately = function () {
  return runDatePicker();
};

// Function to apply native facebook handling
window.check_native_facebook = function () {
  if ($('.body--mobile-android').length || $('.body--mobile-ios').length) {
    // let facebook logins be handeled through native app
    return $('.btn--facebook').click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      return clark.nativeapp.requestFacebookLogin();
    });
  }
};

$(function () {
  // Let the app know the user has authenticated
  if (getParameterByName('userAuthenticationComplete') === 'true') {
    window.clark.runFunctionWhenReady('userAuthenticationComplete');
  }
  // On load function for date picker
  runDatePicker();
  // Click events on custom raido buttons
  $('.custom-radio span').click(function () {
    return $(this).next().click();
  });

  // check for native faceboor register
  window.check_native_facebook();
});
