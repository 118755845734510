define("ember-svg-jar/inlined/icon-nav-legal-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.25 3H4.5v18h15V3h-.75V1.5h.75A1.5 1.5 0 0121 3v18a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 21V3a1.5 1.5 0 011.5-1.5h3.75V3z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.25 10.5l-3.75-3-3.75 3V3a1.5 1.5 0 011.5-1.5h4.5a1.5 1.5 0 011.5 1.5v7.5zm-4.687-4.171l-1.313 1.05V3h4.5v4.38l-1.313-1.051a1.5 1.5 0 00-1.874 0z\" fill=\"#0439D7\"/><path d=\"M8.25 12.75a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5zM7.5 16.5a.75.75 0 01.75-.75h6a.75.75 0 010 1.5h-6a.75.75 0 01-.75-.75z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});