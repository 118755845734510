define("ember-svg-jar/inlined/congrats", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path d=\"M29.8 29.02L2.58 89.828a2.4 2.4 0 003.084 3.208l62.913-25.216a2.4 2.4 0 00.801-3.928l-35.692-35.59a2.4 2.4 0 00-3.886.72z\" stroke=\"#2b6cde\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"3.6\"/><path d=\"M33.02 39.295L5.8 100.101a2.4 2.4 0 003.084 3.209l62.913-25.217a2.4 2.4 0 00.801-3.927l-35.692-35.59a2.4 2.4 0 00-3.885.72z\" fill=\"#2b6cde\" opacity=\".245\"/><path d=\"M58.29 40.311l22.17-21.755\" stroke=\"#2b6cde\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"3\"/><path d=\"M52.604 7.81c6.505 6.303 5.606 14.122-2.697 23.458\" stroke=\"#d6d6d6\" stroke-linecap=\"round\" stroke-linejoin=\"bevel\" stroke-width=\"3\"/><path d=\"M88.96 44.574c-6.416-6.395-14.218-5.36-23.407 3.105\" stroke=\"#2b6cde\" stroke-linecap=\"round\" stroke-linejoin=\"bevel\" stroke-width=\"3\"/><g stroke-width=\"2.4\" transform=\"translate(2 3)\"><ellipse cx=\"78.448\" cy=\"53.506\" rx=\"2.994\" ry=\"3.006\" stroke=\"#2b6cde\"/><ellipse cx=\"65.273\" cy=\"3.006\" rx=\"2.994\" ry=\"3.006\" stroke=\"#2b6cde\"/><path d=\"M95.215 29.158L91.343 31.2l.74-4.328-3.133-3.065 4.33-.632 1.935-3.938 1.936 3.938 4.329.632-3.132 3.065.739 4.328z\" stroke=\"#d6d6d6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M34.7 10.902L32.37 13.39l-.548-3.37-3.077-1.459 3.024-1.565.43-3.388 2.418 2.404 3.343-.636-1.53 3.05 1.635 2.996z\" stroke=\"#2b6cde\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M79.047 66.131c5.445 3.354 9.465 3.354 12.057 0\" stroke=\"#d6d6d6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g><path d=\"M18.723 54.182l6.036 30.712\" stroke=\"#2b6cde\" stroke-width=\"3\"/><path d=\"M34.5 56.5l5 22\" stroke=\"#2b6cde\" stroke-dasharray=\"1\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"3\"/></g>",
    "attrs": {
      "viewBox": "0 0 105 105",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});