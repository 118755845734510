define("ember-svg-jar/inlined/tickCheck", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2 9.333L6.773 14 17 2\" stroke-width=\"2.863\" stroke=\"#2B6CDE\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "class": "svg-cta-path-stroke",
      "width": "19",
      "height": "16",
      "viewBox": "0 0 19 16"
    }
  };
  _exports.default = _default;
});