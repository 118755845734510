define("ember-svg-jar/inlined/thumbs-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.6 11.4h-.8a.8.8 0 01.8-.8v.8zM8.2 17v.8a.8.8 0 01-.731-.475L8.2 17zM5 9.8l-.731.325A.8.8 0 014.2 9.8H5zM5 1h-.8A.8.8 0 015 .2V1zm9.024 0l.009.8h-.009V1zm1.6 1.36l-.79.122-.001-.002.791-.12zm1.104 7.2l.79-.12-.79.12zm-1.6 1.84v-.8h.01l-.01.8zM5 .2a.8.8 0 110 1.6V.2zM5 9a.8.8 0 110 1.6V9zm6.4 2.4v3.2H9.8v-3.2h1.6zm0 3.2a3.2 3.2 0 01-3.2 3.2v-1.6a1.6 1.6 0 001.6-1.6h1.6zm-3.931 2.725l-3.2-7.2 1.462-.65 3.2 7.2-1.462.65zM4.2 9.8V1h1.6v8.8H4.2zM5 .2h9.024v1.6H5V.2zm9.015 0a2.4 2.4 0 012.4 2.04l-1.582.24a.8.8 0 00-.8-.68L14.015.2zm2.4 2.04l1.104 7.2-1.582.242-1.104-7.2 1.582-.243zm1.104 7.2a2.4 2.4 0 01-.562 1.935l-1.207-1.05a.8.8 0 00.187-.645l1.582-.24zm-.562 1.935a2.4 2.4 0 01-1.838.825l.018-1.6a.8.8 0 00.613-.275l1.207 1.05zm-1.829.825H10.6v-1.6h4.528v1.6zM5 1.8H2.6V.2H5v1.6zm-2.4 0a.8.8 0 00-.8.8H.2A2.4 2.4 0 012.6.2v1.6zm-.8.8v5.6H.2V2.6h1.6zm0 5.6a.8.8 0 00.8.8v1.6A2.4 2.4 0 01.2 8.2h1.6zm.8.8H5v1.6H2.6V9z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});