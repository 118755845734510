define("ember-svg-jar/inlined/fileWithCheckmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path fill=\"#BFD2F5\" d=\"M46.955 18.424L57.525 29h-9.533c-.55 0-.997-.446-1-.996l-.037-9.58zM26 59a1 1 0 01-1-1V17h-3a1 1 0 00-1 1v44a1 1 0 001 1h36a1 1 0 001-1v-3H26z\"/><path fill=\"#2B6CDE\" d=\"M60.122 28.769l-12.884-12.89A3.001 3.001 0 0045.116 15H22a3 3 0 00-3 3v44a3 3 0 003 3h36a3 3 0 003-3V30.889a3 3 0 00-.878-2.12zM57.526 29h-9.534c-.55 0-.997-.446-1-.996l-.037-9.58L57.525 29zM59 62a1 1 0 01-1 1H22a1 1 0 01-1-1V18a1 1 0 011-1h22.95l.042 11.012a3.005 3.005 0 003 2.988H59v31z\"/><path fill=\"#2B6CDE\" d=\"M32.64 46.231a1 1 0 10-1.281 1.538l6 5a1 1 0 001.347-.062l12-12a1 1 0 00-1.414-1.414L37.938 50.647l-5.298-4.416z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 80 80"
    }
  };
  _exports.default = _default;
});