define("jquery/index", ["exports", "@ember/-internals/views"], function (_exports, _views) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _views.jQuery;
  _exports.default = _default;
});
