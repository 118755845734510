define("ember-svg-jar/inlined/questionMark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M9.945 18.87c4.94 0 8.945-4 8.945-8.935C18.89 5 14.885 1 9.945 1S1 5 1 9.935c0 4.935 4.005 8.935 8.945 8.935z\" stroke=\"#497CD6\" class=\"question-mark svg-brand-primary-fill\" stroke-width=\"2\" fill=\"#2B6CDE\"/><path d=\"M8.808 11.969v-.496c0-.428.092-.801.275-1.118.183-.317.517-.654 1.004-1.011.469-.335.778-.607.928-.817.15-.21.224-.444.224-.703 0-.29-.107-.511-.322-.663-.214-.152-.513-.228-.897-.228-.67 0-1.433.219-2.29.656L7 6.123a6.38 6.38 0 013.167-.837c.92 0 1.651.22 2.193.663.543.442.814 1.03.814 1.768 0 .49-.111.915-.335 1.272-.223.357-.647.759-1.272 1.205-.429.317-.7.558-.814.723-.113.166-.17.382-.17.65v.402H8.808zm-.214 2.29c0-.375.1-.659.301-.85.201-.192.493-.288.877-.288.37 0 .658.098.86.294.204.197.306.478.306.844 0 .353-.103.63-.309.834-.205.203-.49.304-.857.304-.375 0-.665-.099-.87-.298-.206-.198-.308-.478-.308-.84z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});