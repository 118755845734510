define("ember-svg-jar/inlined/clark-circle-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"32\" cy=\"32\" r=\"32\" fill=\"#0439D7\"/><path d=\"M10.323 32c0-2.885 2.093-5.161 4.786-5.161a4.46 4.46 0 013.588 1.787c.155.208.207.345.207.497a.53.53 0 01-.518.529.539.539 0 01-.458-.24c-.725-.986-1.672-1.483-2.804-1.483-2.094 0-3.67 1.755-3.67 4.071s1.576 4.071 3.67 4.071c1.132 0 2.079-.513 2.804-1.482a.548.548 0 01.458-.24.53.53 0 01.518.528c0 .152-.059.289-.207.497-.806 1.122-2.153 1.787-3.588 1.787-2.693-.008-4.786-2.276-4.786-5.161zM20.91 36.52v-8.904c0-.345.221-.617.547-.617.31 0 .547.273.547.617v8.471h3.92c.297 0 .504.225.504.53 0 .288-.207.528-.503.528h-4.439c-.34 0-.577-.272-.577-.625z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M45.211 37.153a.52.52 0 00.518-.529c0-.144-.067-.296-.192-.44l-2.42-3.559c1.132-.392 1.828-1.394 1.828-2.725 0-1.723-1.184-2.885-2.945-2.885h-2.678c-.318 0-.554.265-.554.601v8.928c0 .329.229.601.547.601.31 0 .547-.264.547-.6v-3.752h2.094l2.782 4.04c.155.216.303.32.473.32zm-3.233-5.41H39.87v-3.67h2.108c1.147 0 1.857.721 1.857 1.843 0 1.13-.695 1.828-1.857 1.828zM54.207 37.153c.28 0 .503-.24.503-.529 0-.176-.06-.296-.207-.432l-4.091-4.553 3.654-3.799c.17-.168.237-.304.237-.472a.517.517 0 00-.503-.53c-.14 0-.252.073-.422.241l-3.995 4.128c-.118.136-.185.272-.185.424 0 .153.074.289.17.41l4.402 4.872c.156.168.296.24.437.24zm-5.304-.617v-9.072c0-.353-.237-.625-.548-.617-.325 0-.547.272-.547.617v9.072c0 .345.229.617.547.617.31 0 .548-.272.548-.617z\" fill=\"#fff\"/><path d=\"M36.645 36.208l-3.537-8.96c-.11-.273-.28-.41-.517-.41s-.407.137-.518.41L28.536 36.2c-.059.152-.08.272-.08.376 0 .305.236.577.532.577.236 0 .421-.152.518-.392l.673-1.74.022-.064.407-1.057 1.982-5.29 1.924 5.297.422 1.058.74 1.804c.11.24.28.392.532.392.281 0 .533-.272.533-.577a.923.923 0 00-.096-.376z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});