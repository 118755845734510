define("ember-svg-jar/inlined/pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Group 7</title><g fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" fill-rule=\"evenodd\"><path d=\"M26.728 12.34a.554.554 0 00-.03-.148c-.006-.018-.01-.036-.018-.053a.556.556 0 00-.108-.16v-.002l-3.179-3.21V5.625c0-.012-.006-.023-.007-.035a.559.559 0 00-.028-.145c-.007-.018-.011-.036-.02-.053a.556.556 0 00-.108-.165L18.217.165a.552.552 0 00-.164-.11c-.016-.008-.033-.013-.05-.019a.542.542 0 00-.147-.03c-.011 0-.022-.006-.033-.006H3.9a.56.56 0 00-.557.563v8.204l-3.179 3.21v.001a.556.556 0 00-.108.16c-.008.018-.012.036-.018.054a.554.554 0 00-.03.148c0 .012-.007.023-.007.035v10.688c0 .31.25.562.557.562h2.785v2.813c0 .31.25.562.557.562h18.937a.56.56 0 00.557-.563v-2.812h2.785a.56.56 0 00.557-.563V12.376c0-.012-.006-.023-.007-.035zm-1.895-.527h-1.44v-1.455l1.44 1.454zm-3.342-6.75h-3.11V1.92l3.11 3.143zM4.456 1.124h12.81v4.5c0 .31.25.563.557.563h4.456v5.625H4.456V1.124zm-1.114 9.233v1.454h-1.44l1.44-1.454zm18.937 15.517H4.456v-2.25h17.823v2.25zm3.342-3.375H1.114v-9.563h24.507V22.5z\"/><path d=\"M10.167 15.03a1.567 1.567 0 00-.61-.33 2.456 2.456 0 00-.663-.095H7.038v6.77h1.117v-2.716h.795c.234 0 .461-.041.682-.124.221-.083.417-.21.587-.383.17-.172.308-.387.412-.645.104-.259.156-.56.156-.904 0-.382-.058-.701-.175-.956a1.791 1.791 0 00-.445-.617zm-.715 2.41a.843.843 0 01-.653.282h-.644v-2.151h.644c.265 0 .483.082.653.248.17.166.256.43.256.794 0 .363-.085.639-.256.827zM17.112 21.375h1.146v-2.907h1.714v-.985h-1.724v-1.846h1.866v-1.032h-3.002zM13.41 14.605h-1.685v6.77H13.4c.864 0 1.52-.295 1.964-.884.445-.59.668-1.42.668-2.492 0-1.07-.223-1.904-.668-2.5-.445-.596-1.096-.894-1.955-.894zm1.378 4.432a2.303 2.303 0 01-.308.741c-.133.198-.29.346-.473.444a1.234 1.234 0 01-.597.149h-.54v-4.762h.54c.215 0 .413.05.597.148.183.099.34.247.473.445.133.197.235.446.308.746.072.3.109.65.109 1.051 0 .396-.037.741-.11 1.038z\"/></g>",
    "attrs": {
      "width": "27",
      "height": "27",
      "viewBox": "0 0 27 27",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});