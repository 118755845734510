define("ember-file-upload/modifiers/drag-listener", ["exports", "ember-modifier", "ember-file-upload/system/drag-listener"], function (_exports, _emberModifier, _dragListener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class DragListenerModifier extends _emberModifier.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "dragListener", new _dragListener.default());
    }
    didReceiveArguments() {
      const {
        dragenter,
        dragleave,
        dragover,
        drop
      } = this.args.named;
      this.dragListener.removeEventListeners(this.element);
      this.dragListener.addEventListeners(this.element, {
        dragenter,
        dragleave,
        dragover,
        drop
      });
    }
    willRemove() {
      this.dragListener.removeEventListeners(this.element);
    }
  }
  _exports.default = DragListenerModifier;
});