define("ember-svg-jar/inlined/icon-retirement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M21.632 17.932a.798.798 0 01.099 1.124c-.214.255-.44.5-.677.738a.797.797 0 01-1.128-.002.797.797 0 01-.001-1.127c.204-.205.4-.417.584-.637a.797.797 0 011.123-.096zM10.556 1.158a.795.795 0 01-.482 1.019 9.809 9.809 0 00-.8.324l-.08.032a.792.792 0 01-.225.17 9.84 9.84 0 00-2.492 1.813c-3.872 3.873-3.849 10.177.056 14.082a.797.797 0 11-1.126 1.125C.88 15.197.853 7.883 5.348 3.387a11.427 11.427 0 012.894-2.105.801.801 0 01.11-.047.808.808 0 01.255-.183c.304-.14.614-.266.929-.378a.8.8 0 011.02.484zm12.794 13.01a.8.8 0 01.518 1.003c-.101.319-.217.633-.346.941a.8.8 0 01-1.471-.619c.111-.265.21-.535.298-.81a.795.795 0 011-.515zm-8.188-1.167a.598.598 0 010 1.198h-3.57a.598.598 0 010-1.198h3.57zm9.218-2.235c.026.333.038.668.035 1.002a.794.794 0 01-.805.788.8.8 0 01-.79-.807 10.03 10.03 0 00-.03-.865.794.794 0 01.734-.854.8.8 0 01.856.736zM17.52 9.03a.598.598 0 010 1.198H8.59a.598.598 0 010-1.198h8.928zm5.69-2.538c.149.3.285.606.408.918a.795.795 0 01-.45 1.032.8.8 0 01-1.034-.452 9.968 9.968 0 00-.352-.792.796.796 0 111.428-.706zm-2.692-3.528a11.897 11.897 0 01.724.702.797.797 0 11-1.156 1.095 10.273 10.273 0 00-.626-.607.797.797 0 111.058-1.19zM16.72.704c.315.113.626.24.93.38a.795.795 0 11-.662 1.449c-.262-.122-.53-.23-.801-.328a.8.8 0 01-.485-1.02.795.795 0 011.018-.48zM13.351.042a.8.8 0 01.762.834.794.794 0 01-.83.76 10.024 10.024 0 00-.866-.004.8.8 0 01-.83-.764.794.794 0 01.761-.829c.335-.013.67-.012 1.003.003z\"/>",
    "attrs": {
      "width": "26",
      "height": "20",
      "viewBox": "0 0 26 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});