define("ember-svg-jar/inlined/notification-icon-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M12 21a9 9 0 110-18 9 9 0 010 18z\" fill=\"currentColor\"/><path d=\"M12.05 14.564c.439 0 .818.364.818.828 0 .455-.38.819-.817.819a.823.823 0 110-1.646zM12 14c-.438 0-.777.033-.777-.417l-.395-4.77c0-.45.73-.404 1.178-.404.438 0 1.197-.046 1.197.404l-.425 4.77c0 .45-.329.417-.778.417z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});