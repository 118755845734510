define("ember-svg-jar/inlined/noinsurance-ico", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Icon / No Insurance Fallback Desktop Copy</title><g fill=\"none\" fill-rule=\"evenodd\" stroke=\"#A8AAB2\"><path d=\"M30 3v24\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M29.999 28a.931.931 0 01-.348-.067C22.478 25.23 19 21.89 19 17.717V4.925c0-.263.123-.512.335-.679a.935.935 0 01.758-.179c1.141.225 2.67.239 4.194.033 2.537-.343 4.358-1.16 5.048-1.822a.94.94 0 011.344.013c.7.66 2.514 1.468 5.034 1.809 1.524.206 3.053.193 4.193-.033a.938.938 0 01.759.18.864.864 0 01.335.678v12.792c0 4.175-3.482 7.516-10.642 10.213a.956.956 0 01-.36.07z\" stroke-width=\"2.401\"/></g>",
    "attrs": {
      "width": "60",
      "height": "30",
      "viewBox": "0 0 60 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});