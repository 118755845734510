define("ember-svg-jar/inlined/privat-rente", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Budicon-Shady/Medical/hospital-sign</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M24.845 2.269A18.363 18.363 0 018.755 34.53 18.381 18.381 0 1024.845 2.27z\" fill=\"#BFD2F5\"/><path d=\"M26.4 17.6h-4v-4a.8.8 0 00-.8-.8h-3.2a.8.8 0 00-.8.8v4h-4a.8.8 0 00-.8.8v3.2a.8.8 0 00.8.8h4v4a.8.8 0 00.8.8h3.2a.8.8 0 00.8-.8v-4h4a.8.8 0 00.8-.8v-3.2a.8.8 0 00-.8-.8zm-.8 3.2h-4.8v4.8h-1.6v-4.8h-4.8v-1.6h4.8v-4.8h1.6v4.8h4.8v1.6z\" fill=\"#2B6CDE\"/><path d=\"M20 0C8.954 0 0 8.954 0 20s8.954 20 20 20 20-8.954 20-20A20 20 0 0020 0zm0 38.4C9.838 38.4 1.6 30.162 1.6 20 1.6 9.838 9.838 1.6 20 1.6c10.162 0 18.4 8.238 18.4 18.4-.012 10.157-8.243 18.388-18.4 18.4z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});