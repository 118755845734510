define("ember-svg-jar/inlined/feedback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path d=\"M9.6 19.4V45a6.4 6.4 0 006.4 6.4h26.667l8.533 6.4h7.467l-12.8-9.6H17.6a4.8 4.8 0 01-4.8-4.8V16.2h-8a1.602 1.602 0 00-1.6 1.6v1.6h6.4zM75.2 3.406h-4.8V40.2a1.6 1.6 0 01-1.6 1.6H57.6V53l6.4 4.8V45h11.2a1.6 1.6 0 001.6-1.6V5.006a1.6 1.6 0 00-1.6-1.6z\" class=\"svg-brand-primary-fill\" opacity=\".3\"/><path d=\"M62.4 16.2h-32a1.6 1.6 0 100 3.2h32a1.6 1.6 0 100-3.2zm0 12.8h-32a1.6 1.6 0 100 3.2h32a1.6 1.6 0 100-3.2z\" class=\"svg-brand-primary-fill\"/><path d=\"M75.2.206H17.6a4.8 4.8 0 00-4.8 4.8V13h-8A4.8 4.8 0 000 17.8v38.394a4.8 4.8 0 004.8 4.8h8v9.6a3.203 3.203 0 003.214 3.206 3.152 3.152 0 001.906-.645l16.212-12.16c1.164 0 27.876.067 29.024-.121.27.081.549.126.83.133A3.203 3.203 0 0067.2 57.8v-9.6h8a4.8 4.8 0 004.8-4.8V5.006a4.8 4.8 0 00-4.8-4.8zM33.058 57.8l-.846.634L16 70.594V57.8h-3.2l-8-.006a1.6 1.6 0 01-1.6-1.6V17.8a1.602 1.602 0 011.6-1.6h8v27.2a4.8 4.8 0 004.8 4.8h28.267l12.8 9.6h-25.61zM76.8 43.4a1.6 1.6 0 01-1.6 1.6H64v12.8L47.788 45.64l-.854-.64H17.6a1.6 1.6 0 01-1.6-1.6V5.006a1.6 1.6 0 011.6-1.6h57.6a1.6 1.6 0 011.6 1.6V43.4z\" class=\"svg-brand-primary-fill\"/></g>",
    "attrs": {
      "width": "80",
      "height": "74",
      "viewBox": "0 0 80 74",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});