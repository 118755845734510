define("ember-lifeline-decorators/event-listener", ["exports", "@ember-decorators/utils/decorator", "@ember/debug", "ember-lifeline", "patch-method"], function (_exports, _decorator, _debug, _emberLifeline, _patchMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-empty-function
  function NOOP() {}
  function collapseProto(target) {
    // We must collapse the superclass prototype to make sure that the `actions`
    // object will exist. Since collapsing doesn't generally happen until a class is
    // instantiated, we have to do it manually.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (typeof target.constructor.proto === 'function') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      target.constructor.proto();
    }
  }
  var _default = (0, _decorator.decoratorWithRequiredParams)(function eventListener(target, _key, desc, _ref) {
    let [eventTarget, eventName, options] = _ref;
    (false && !(typeof desc.value === 'function') && (0, _debug.assert)("The '@eventListener' decorator can only be used on methods.", typeof desc.value === 'function'));
    collapseProto(target);
    (0, _patchMethod.afterMethod)(target.constructor, typeof target.constructor.prototype.didInsertElement === 'function' ? 'didInsertElement' : 'init', function () {
      (0, _emberLifeline.addEventListener)(this,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore https://github.com/ember-lifeline/ember-lifeline/pull/249
      typeof eventTarget === 'function' ? eventTarget.call(this, this) : eventTarget, eventName, desc.value, options);
    }, NOOP);
    return desc;
  });
  _exports.default = _default;
});