define("ember-svg-jar/inlined/grootLogo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FF4614\" d=\"M26.77 18.256h-3.644L23.12 6.31h3.645zM40.155 18.252l-9.175.005-.007-11.947h3.642l.005 9.088 5.533-.002zM56.416 18.257h-3.59l-.003-4.675-5.415.003.002 4.672h-3.598L43.805 6.31h3.598l.003 4.375 5.415-.002-.002-4.373h3.59zM69.468 18.257h-8.953L60.51 6.31h8.959l.001 2.368-5.378.004.002 2.191 4.942-.003.001 2.408-4.942.003.001 2.555 5.372-.003zM82.854 18.257h-9.175l-.006-11.945 3.642-.002.004 9.06 5.533-.004zM100 18.256h-3.396l-.003-6.965-3.252 4.015h-.285l-3.266-4.011.005 6.96-3.292.002-.007-11.947H89.6l3.642 4.44 3.652-4.44h3.1zM4.431 18.478h3.973L3.984 6.267H0l4.431 12.21zm7.044 0L7.045 6.267h3.982l2.44 6.723 4.35-11.988L21.793 1l-6.341 17.478h-3.976z\"/><g transform=\"translate(13.274 1)\"><mask id=\"grootLogo_svg__a\" fill=\"#fff\"><use xlink:href=\"#grootLogo_svg__path-1\"/></mask><path mask=\"url(#grootLogo_svg__a)\" d=\"M4.433.002L.104 11.95l1.974 5.47L8.387 0z\"/></g></g>",
    "attrs": {
      "width": "100",
      "height": "20",
      "viewBox": "0 0 100 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});