define("ember-svg-jar/inlined/xInCircle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#CFD3DA\" class=\"x-in-circle-icon\" fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(1 1)\"><circle cx=\"6.5\" cy=\"6.5\" r=\"6.5\"/><path d=\"M9 4L4 9M4 4l5 5\" stroke-linecap=\"round\"/></g></g>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});