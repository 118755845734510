define("ember-svg-jar/inlined/action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#F99400\" fill=\"none\" class=\"svg-highlight-stroke\" fill-rule=\"evenodd\"><circle cx=\"10\" cy=\"10\" r=\"9\" stroke-width=\"2\"/><path d=\"M10 11.237c-.395 0-.671-.316-.684-.698l-.119-4.684C9.184 5.342 9.5 5 10 5s.803.342.803.855l-.119 4.684c-.013.382-.29.698-.684.698zm.013 3.737c-.566 0-1.013-.435-1.013-.987 0-.553.447-1 1.013-1 .553 0 .987.447.987 1a.977.977 0 01-.987.987z\" stroke-width=\".5\" fill=\"#F99400\" class=\"svg-highlight-fill\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});