define("ember-svg-jar/inlined/sad-face", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M46.942 8.258c8.587 10.969 7.637 26.621-2.213 36.471-9.85 9.85-25.502 10.8-36.47 2.213A27.569 27.569 0 1046.941 8.258z\" class=\"svg-brand-primary-fill\" fill=\"#BFD2F5\" fill-rule=\"nonzero\" opacity=\".3\"/><path d=\"M30 .005c-16.569 0-30 13.431-30 30 0 16.568 13.431 30 30 30 16.569 0 30-13.432 30-30a30 30 0 00-30-30zm0 57.6c-15.243 0-27.6-12.357-27.6-27.6 0-15.243 12.357-27.6 27.6-27.6 15.243 0 27.6 12.357 27.6 27.6-.017 15.236-12.364 27.582-27.6 27.6z\" fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" fill-rule=\"nonzero\"/><circle class=\"svg-brand-primary-fill\" fill=\"#2B6CDE\" fill-rule=\"nonzero\" cx=\"21.6\" cy=\"26.405\" r=\"2.4\"/><circle class=\"svg-brand-primary-fill\" fill=\"#2B6CDE\" fill-rule=\"nonzero\" cx=\"38.4\" cy=\"26.405\" r=\"2.4\"/><path d=\"M23.157 39.731a1.2 1.2 0 101.685 1.706 8.226 8.226 0 0110.315 0 1.2 1.2 0 001.685-1.707 10.581 10.581 0 00-13.685.001z\" class=\"svg-brand-primary-fill\" fill=\"#2B6CDE\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "viewBox": "0 0 60 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});