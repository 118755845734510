define("ember-svg-jar/inlined/thingsLogo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M2 69.364h132.443\" stroke=\"#31EC79\" class=\"svg-things-stroke\" stroke-width=\"3.228\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><g transform=\"translate(92.01 8.707)\"><rect stroke=\"#31EC79\" stroke-width=\"3.324\" fill=\"#17203D\" class=\"svg-brand-secondary-fill svg-things-stroke\" stroke-linecap=\"round\" stroke-linejoin=\"round\" x=\"1.01\" width=\"30.313\" height=\"59.72\" rx=\"3.874\"/><path d=\"M12.125 4.88h8.084M1.01 9.236h30.313M1.01 49.432h30.313\" stroke=\"#31EC79\" class=\"svg-things-stroke\" stroke-width=\"3.228\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><ellipse fill=\"#31EC79\" class=\"svg-things-fill\" cx=\"16.167\" cy=\"54.743\" rx=\"2.021\" ry=\"1.991\"/></g><path d=\"M58.206 68.85V36.272h11.718c.7 0 .867-.408.375-.906l-32.97-33.4a1.247 1.247 0 00-1.778-.01l-9.918 10.048V6.13H13.4v17.852L2.274 35.369c-.488.5-.305.899.397.892l10.074-.092h0v32.68\" stroke-linecap=\"round\" stroke=\"#31EC79\" stroke-width=\"3.228\" stroke-linejoin=\"round\"/><g transform=\"translate(30.888 32.416)\" stroke=\"#31EC79\" stroke-width=\"3.228\" fill=\"#17203D\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M63.283 30.883h8.686V23.47h-2.57c.058-.403.088-.816.088-1.235 0-4.766-3.873-8.648-8.65-8.648H6.204v9.883H0v7.412h16.712c0-3.411 2.777-6.177 6.204-6.177 3.426 0 6.204 2.766 6.204 6.177h21.755c0-3.411 2.777-6.177 6.204-6.177 3.426 0 6.204 2.766 6.204 6.177z\"/><ellipse cx=\"57.079\" cy=\"30.883\" rx=\"6.204\" ry=\"6.177\"/><ellipse cx=\"22.916\" cy=\"30.883\" rx=\"6.204\" ry=\"6.177\"/><path d=\"M9.927 0h38.955l6.956 13.588H9.927z\"/></g></g>",
    "attrs": {
      "width": "137",
      "height": "72",
      "viewBox": "0 0 137 72",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});