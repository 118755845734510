define("ember-svg-jar/inlined/icon-password-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M49.92 25.6h-6.4v26.88c0 .707-.573 1.28-1.28 1.28H11.52v6.4c0 .707.573 1.28 1.28 1.28h37.12c.707 0 1.28-.573 1.28-1.28V26.88c0-.707-.573-1.28-1.28-1.28z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M49.92 25.6h-3.84V14.08C46.08 6.304 39.776 0 32 0S17.92 6.304 17.92 14.08V25.6h-3.84a3.84 3.84 0 00-3.84 3.84v30.72A3.84 3.84 0 0014.08 64h35.84a3.84 3.84 0 003.84-3.84V29.44a3.84 3.84 0 00-3.84-3.84zM20.48 14.08c0-6.362 5.158-11.52 11.52-11.52 6.362 0 11.52 5.158 11.52 11.52V25.6H20.48V14.08zm29.44 47.36c.707 0 1.28-.573 1.28-1.28V29.44c0-.707-.573-1.28-1.28-1.28H14.08c-.707 0-1.28.573-1.28 1.28v30.72c0 .707.573 1.28 1.28 1.28h35.84z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M30.242 36.074A6.818 6.818 0 0132 35.84a6.374 6.374 0 014.168 11.221l2.166 5.014a1.28 1.28 0 01-1.214 1.685H26.88a1.28 1.28 0 01-1.212-1.686l2.166-5.014a6.37 6.37 0 01-1.944-6.762 6.295 6.295 0 014.352-4.224zM28.657 51.2h6.686l-2.147-4.954 1.296-1.12a3.778 3.778 0 001.348-2.886A3.844 3.844 0 0032 38.4c-.371 0-.74.05-1.099.146a3.72 3.72 0 00-2.558 2.483 3.831 3.831 0 001.165 4.096l1.295 1.12-2.146 4.955z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});