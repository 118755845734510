define("ember-lazy-mount/components/lazy-mount/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w1+0ib+F",
    "block": "[[[41,[33,1],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"isLoading\",\"error\"],[true,null]]]]],[1,\"\\n\"]],[]],[[[41,[33,4],[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"isLoading\",\"error\"],[false,[33,4]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[46,[28,[37,6],[[33,7]],[[\"model\"],[[33,8]]]],null,null,null],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"isLoading\",\"yield\",\"hash\",\"error\",\"component\",\"-mount\",\"loadedName\",\"model\"]]",
    "moduleName": "ember-lazy-mount/components/lazy-mount/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});