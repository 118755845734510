define("ember-svg-jar/inlined/hintIcon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><ellipse stroke=\"#D6D6D6\" stroke-width=\"2\" fill=\"#D6D6D6\" cx=\"10.933\" cy=\"10.921\" rx=\"10.933\" ry=\"10.921\"/><path d=\"M9.543 13.406v-.605c0-.524.112-.98.336-1.367.223-.388.633-.8 1.227-1.236.573-.41.951-.742 1.134-.999.183-.256.274-.543.274-.859 0-.355-.13-.625-.393-.81-.262-.186-.627-.279-1.096-.279-.819 0-1.752.268-2.8.803L7.333 6.26a7.798 7.798 0 013.872-1.023c1.124 0 2.017.27 2.68.81.663.54.994 1.26.994 2.161 0 .6-.136 1.119-.409 1.555-.273.437-.79.928-1.555 1.473-.524.388-.855.682-.994.884-.14.202-.209.467-.209.794v.491H9.543zm-.262 2.8c0-.459.123-.805.369-1.04.245-.235.602-.352 1.072-.352.453 0 .803.12 1.051.36.249.24.373.584.373 1.031 0 .431-.126.771-.377 1.02-.25.248-.6.372-1.047.372-.459 0-.813-.122-1.064-.364-.251-.243-.377-.586-.377-1.028z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});