define("ember-svg-jar/inlined/protection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 3.75A.75.75 0 01.75 3c2.663 0 5.258-.943 7.8-2.85a.75.75 0 01.9 0C11.992 2.057 14.587 3 17.25 3a.75.75 0 01.75.75V9c0 .338-.014.67-.04.996a6.467 6.467 0 00-1.465-.684c.003-.103.005-.207.005-.312V4.478c-2.577-.152-5.08-1.09-7.5-2.8-2.42 1.71-4.923 2.648-7.5 2.8V9c0 4.149 2.332 7.221 7.125 9.285a6.506 6.506 0 001.005 1.52l-.355.143a.75.75 0 01-.55 0C2.958 17.676 0 14 0 9V3.75zM20 15.5a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm-2.146-2.354a.5.5 0 00-.708 0L13.5 16.793l-1.646-1.647a.5.5 0 00-.708.708l2 2a.5.5 0 00.708 0l4-4a.5.5 0 000-.708z\" fill=\"#203151\"/>",
    "attrs": {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});