define("ember-css-modules-active-route/services/css-modules-active-route", ["exports", "@ember/application", "@ember/debug", "@ember/object", "@ember/service", "ember-css-modules-active-route/utils/events", "ember-css-modules-active-route/utils/owner"], function (_exports, _application, _debug, _object, _service, _events, _owner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function resolveElement(elementOrSelector) {
    const element = typeof elementOrSelector === 'string' ? document.querySelector(elementOrSelector) : elementOrSelector;
    (false && !(element instanceof Element) && (0, _debug.assert)("'".concat(elementOrSelector, "' is not an element."), element instanceof Element));
    return element;
  }
  let CSSModulesActiveRouteService = (_class = class CSSModulesActiveRouteService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "router", (() => {
        const owner = (0, _application.getOwner)(this);
        const rootOwner = (0, _owner.getRootOwner)(this);

        // If this service is owned by the host application, use the real root
        // `RouterService`.
        if (rootOwner === owner) return owner.lookup('service:router');

        // If this service is owned by a routable engine, use the fallback
        // implementation of a would-be `EngineRouterService`. Once we get a real
        // version of that service, we can drop this.
        // @see https://github.com/ember-engines/ember-engines/issues/587
        return owner.lookup('service:css-modules-active-route/engine-router');
      })());
      /**
       * The magic pseudo selectors are rewritten to regular class names, so that we
       * can resolve them here.
       *
       * This mapping is inherited from the `CSSModulesActiveRouteService` of the
       * root application, if present.
       */
      _defineProperty(this, "targetElements", (() => {
        const rootOwner = (0, _owner.getRootOwner)(this);
        if (rootOwner !== (0, _application.getOwner)(this)) {
          const rootService = rootOwner.lookup('service:css-modules-active-route');
          if (rootService) return rootService.targetElements;
        }
        return {
          'css-modules-active-route-app': resolveElement(rootOwner.rootElement),
          'css-modules-active-route-document': document.documentElement
        };
      })());
      /**
       * This map is used to keep track of the currently applied class names, so
       * that we can diff it with the new class names, without interacting with the
       * DOM.
       */
      _defineProperty(this, "currentClassNames", {});
    }
    init() {
      super.init();
      (0, _events.addListener)(this.router, 'routeWillChange', this.handleRouteChange);
      (0, _events.addListener)(this.router, 'routeDidChange', this.handleRouteChange);
    }
    willDestroy() {
      super.willDestroy();
      (0, _events.removeListener)(this.router, 'routeWillChange', this.handleRouteChange);
      (0, _events.removeListener)(this.router, 'routeDidChange', this.handleRouteChange);
    }

    /**
     * Called when a transition begins or ends.
     *
     * Resolves the target route hierarchy into styles and then gets the class
     * names from these styles, which are then applied to the corresponding target
     * elements.
     */
    handleRouteChange(transition) {
      const routeNames = this.getRouteNamesFromRouteInfo(transition.to);
      const styles = routeNames.map(name => this.resolveStylesForRoute(name)).filter(Boolean);
      this.updateClassNames(styles);
    }

    /**
     * Updates all elements listed in `targetElements` with the respective class
     * names from `styles`.
     */
    updateClassNames(styles) {
      for (const [magicClassName, element] of Object.entries(this.targetElements)) {
        const newClassNames = this.getClassNamesFromStyles(styles, magicClassName);
        const oldClassNames = this.currentClassNames[magicClassName] || [];
        const staleClassNames = oldClassNames.filter(name => !newClassNames.includes(name));
        this.currentClassNames[magicClassName] = newClassNames;

        // IE11 does not support multiple arguments, so we need to iterate.
        // https://caniuse.com/#search=classlist
        for (const name of staleClassNames) element.classList.remove(name);
        for (const name of newClassNames) element.classList.add(name);
      }
    }

    /**
     * Returns all route names for a given `RouteInfo` object. The order is from
     * parent to child.
     *
     * @example 'foo.bar.index' => [
     *   'application',
     *   'foo',
     *   'foo.bar',
     *   'foo.bar.index'
     * ]
     */
    getRouteNamesFromRouteInfo(routeInfo) {
      const routeNames = [];
      for (let currentRouteInfo = routeInfo; currentRouteInfo; currentRouteInfo = currentRouteInfo.parent) {
        routeNames.push(currentRouteInfo.name);
      }

      // `.reverse()` so that they are ordered from parent to child
      return routeNames.reverse();
    }

    /**
     * Resolves the respective `styles.css` for a given route name.
     * Returns `undefined`, if no associated `styles.css` file exists.
     *
     * @note This method assumes that no non-default templates for routes are
     * rendered.
     * @see https://github.com/emberjs/rfcs/blob/master/text/0418-deprecate-route-render-methods.md
     */
    resolveStylesForRoute(routeName) {
      var _owner$resolveRegistr;
      const owner = (0, _application.getOwner)(this);
      const styles = (_owner$resolveRegistr = owner.resolveRegistration("styles:".concat(routeName))) !== null && _owner$resolveRegistr !== void 0 ? _owner$resolveRegistr : owner.resolveRegistration("style:".concat(routeName));
      return styles;
    }

    /**
     * Returns an array of class names to be applied to the root element.
     */
    getClassNamesFromStyles(styles, magicClassName) {
      return [].concat(
      // ES 5 `.flatMap()`
      ...styles
      // One class name may map to multiple class names, when using `composes`
      // for instance, so we split them up to get a flat array of single class
      // names.
      .map(classNames => (classNames[magicClassName] || '').split(' '))).filter(Boolean);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "handleRouteChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRouteChange"), _class.prototype)), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
  _exports.default = CSSModulesActiveRouteService;
});