define("ember-cli-resolve-asset/utils/resolve-asset", ["exports", "ember-get-config", "ember-cli-resolve-asset/-private/asset-map-loader"], function (_exports, _emberGetConfig, _assetMapLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.resolveAsset = resolveAsset;
  _exports.resolveAssetSync = resolveAssetSync;
  /**
   * Returns the resolved asset path for the given input `path`. If the asset is
   * not listed in the asset map, this function will throw an error.
   *
   * This function assumes, that the asset map has already been loaded.
   *
   * @param {string} path
   * @param {boolean} withoutPrepend
   */
  function getAssetPath(path) {
    let withoutPrepend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (_assetMapLoader.assetMap.enabled === false) {
      return "".concat(_emberGetConfig.default.rootURL).concat(path);
    }
    const resolved = _assetMapLoader.assetMap.assets[path];
    if (!resolved) {
      if (false /* DEBUG */) {
        // eslint-disable-next-line no-console
        console.info("ember-cli-resolve-asset: List of known asset paths:", Object.keys(_assetMapLoader.assetMap.assets));
      }
      throw new Error("ember-cli-resolve-asset: Could not find '".concat(path, "' in the asset map."));
    }
    if (withoutPrepend) return "".concat(_emberGetConfig.default.rootURL).concat(resolved);
    return "".concat(_assetMapLoader.assetMap.prepend || _emberGetConfig.default.rootURL).concat(resolved);
  }

  /**
   * Asynchronously resolves the given `path` from the asset map.
   *
   * If the asset map is not loaded yet, this function will wait for it.
   * If loading the asset map fails, the returned `Promise` will reject.
   * If the path is not listed in the asset map, the returned `Promise` will
   * reject.
   *
   * @param {string} path
   * @param {boolean} withoutPrepend
   */
  async function resolveAsset(path) {
    let withoutPrepend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    await (0, _assetMapLoader.load)();
    return getAssetPath(path, withoutPrepend);
  }
  var _default = resolveAsset;
  /**
   * Synchronous version of `resolveAsset`.
   *
   * Synchronously resolves the given `path` from the asset map.
   *
   * If the asset map is not loaded yet, this function will throw.
   * If the path is not listed in the asset map, this function will throw.
   *
   * @param {string} path
   * @param {boolean} withoutPrepend
   */
  _exports.default = _default;
  function resolveAssetSync(path) {
    let withoutPrepend = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (!_assetMapLoader.assetMap.assets) {
      throw new Error("ember-cli-resolve-asset: Could not resolve '".concat(path, "', because the asset map is not loaded yet. You might want to use the async 'resolveAsset' instead."));
    }
    return getAssetPath(path, withoutPrepend);
  }
});