define("ember-svg-jar/inlined/kfz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Budicon-Shady/Travel/vintage-car</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M29.937 13.951c.065.205.1.418.103.633 0 1.22-.989 2.208-2.208 2.208a2.182 2.182 0 01-.633-.103 2.202 2.202 0 102.738-2.738zM10.801 13.951c.065.205.1.418.103.633a2.208 2.208 0 01-2.208 2.208 2.182 2.182 0 01-.633-.103 2.202 2.202 0 102.738-2.738zM3.544 13.848H7.96v1.472H3.544z\" fill=\"#BFD2F5\"/><path fill=\"#BFD2F5\" d=\"M12.376 13.848h14.72v1.472h-14.72zM31.512 13.848h4.416v1.472h-4.416zM10.904 2.072v4.416h-6.32a7.568 7.568 0 016.32-4.416z\"/><path d=\"M37.377 14.272l-.57-3.993a4.416 4.416 0 00-4.372-3.791h-2.952a2.207 2.207 0 01-1.562-.647l-3.948-3.948A4.416 4.416 0 0020.851.6h-9.947C6.488.6 2.072 5.016 2.072 10.904v4.416h-.736a.736.736 0 100 1.472h5.226a3.68 3.68 0 007.212 0H25.7a3.68 3.68 0 007.211 0h2.282a2.208 2.208 0 002.185-2.52zm-25.001-12.2h8.475a2.926 2.926 0 012.082.862l3.553 3.554h-14.11V2.072zm-1.472 0v4.416h-6.32a7.568 7.568 0 016.32-4.416zm-.736 16.192a2.208 2.208 0 110-4.416 2.208 2.208 0 010 4.416zm19.136 0a2.208 2.208 0 110-4.416 2.208 2.208 0 010 4.416zm6.443-3.198a.727.727 0 01-.555.254H32.91a3.68 3.68 0 00-7.211 0H13.774a3.68 3.68 0 00-7.211 0H3.544v-4.416c0-.998.15-1.99.447-2.944h28.444a2.959 2.959 0 012.915 2.528l.57 3.992a.728.728 0 01-.173.586z\" fill=\"#2B6CDE\"/><path d=\"M20.472 10.904h-2.944a.736.736 0 100 1.472h2.944a.736.736 0 100-1.472z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "38",
      "height": "20",
      "viewBox": "0 0 38 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});