define("ember-svg-jar/inlined/money-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.28.22A.75.75 0 00.22 1.28L1.958 3.02A2.25 2.25 0 000 5.25v7.5A2.25 2.25 0 002.25 15h11.69l1.494 1.495c-.06.003-.122.005-.184.005H2.401A2.999 2.999 0 005 18h10.25c.51 0 1-.08 1.46-.229l2.01 2.01a.75.75 0 101.06-1.061L1.28.22zM12.44 13.5H4.5v-.75a2.25 2.25 0 00-2.25-2.25H1.5v-3h.75a2.25 2.25 0 002.231-1.958l1.636 1.636a3 3 0 004.206 4.206l2.116 2.116zM7.196 8.257l2.046 2.046a1.5 1.5 0 01-2.046-2.046zM1.5 5.25a.75.75 0 01.75-.75H3v.75a.75.75 0 01-.75.75H1.5v-.75zm.75 8.25a.75.75 0 01-.75-.75V12h.75a.75.75 0 01.75.75v.75h-.75zm12.5-3c-.315 0-.615.065-.887.182L15.18 12h.319v.32l1.318 1.317c.117-.272.182-.572.182-.887v-7.5A2.25 2.25 0 0014.75 3H6.18l1.5 1.5h4.82v.75a2.25 2.25 0 002.25 2.25h.75v3h-.75zm.75-5.25V6h-.75a.75.75 0 01-.75-.75V4.5h.75a.75.75 0 01.75.75zm2.562 9.631l1.085 1.085c.538-.77.853-1.706.853-2.716V8c0-1.11-.603-2.08-1.5-2.599v7.849c0 .595-.16 1.152-.438 1.631z\" fill=\"#203151\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});