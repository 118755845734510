define("ember-svg-jar/inlined/gift", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.333 2.667a4.333 4.333 0 013.652 6.667h3.348c.92 0 1.667.745 1.667 1.666v4.667c0 .806-.572 1.478-1.332 1.633l-.001 7.7a4.334 4.334 0 01-4.088 4.326l-.246.007H9.667a4.334 4.334 0 01-4.327-4.087L5.333 25v-7.7A1.667 1.667 0 014 15.667V11c0-.92.746-1.667 1.667-1.667l3.348.001a4.333 4.333 0 016.986-5.102 4.319 4.319 0 013.332-1.565zM15 17.332H7.333V25c0 1.224.943 2.228 2.142 2.326l.192.007H15V17.332zm9.667 0H17v10.001h5.333a2.333 2.333 0 002.326-2.142l.008-.191v-7.668zM15 11.333H6v4l9-.001v-3.999zm11 4v-4h-9v3.999l9 .001zM19.333 4.667A2.333 2.333 0 0017 7v2.332h2.365l.16-.006a2.334 2.334 0 00-.192-4.66zm-6.666 0a2.333 2.333 0 00-.192 4.659l.16.006H15V7l-.008-.191a2.334 2.334 0 00-2.325-2.142z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});