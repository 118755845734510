define("ember-svg-jar/inlined/trailer-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M14 24a3 3 0 100-6 3 3 0 000 6zm0 1a4 4 0 100-8 4 4 0 000 8z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M14 14.5c-3.127 0-5.5 2.096-5.5 4.5a.5.5 0 01-1 0c0-3.12 3-5.5 6.5-5.5s6.5 2.38 6.5 5.5a.5.5 0 01-1 0c0-2.404-2.373-4.5-5.5-4.5z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M4 12a.5.5 0 00-.5.5V20a.5.5 0 00.5.5h4.5a.5.5 0 010 1H4A1.5 1.5 0 012.5 20v-7.5A1.5 1.5 0 014 11h21a1.5 1.5 0 011.5 1.5v8h4a.5.5 0 010 1H19a.5.5 0 010-1h6.5v-8a.5.5 0 00-.5-.5H4z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M2.5 22.5V20h1v2.5a.5.5 0 01-1 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});