define("ember-file-upload/modifiers/update-queue", ["exports", "ember-modifier", "@ember/utils"], function (_exports, _emberModifier, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const updateQueue = (_element, _ref, _ref2) => {
    let [queue] = _ref;
    let {
      multiple,
      disabled,
      onFileAdd
    } = _ref2;
    if ((0, _utils.isPresent)(multiple)) queue.multiple = multiple;
    if ((0, _utils.isPresent)(disabled)) queue.disabled = disabled;
    if ((0, _utils.isPresent)(onFileAdd)) queue.onFileAdd = onFileAdd;
  };
  var _default = (0, _emberModifier.modifier)(updateQueue);
  _exports.default = _default;
});