define("ember-lifeline-decorators/debounce", ["exports", "@ember-decorators/utils/decorator", "@ember/debug", "ember-lifeline", "ember-lifeline-decorators/utils/private-alias"], function (_exports, _decorator, _debug, _emberLifeline, _privateAlias) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _decorator.decoratorWithRequiredParams)(function debounce(target, key, desc, _ref) {
    let [wait, immediate = false] = _ref;
    (false && !(typeof desc.value === 'function') && (0, _debug.assert)("The '@debounce' decorator can only be used on methods.", typeof desc.value === 'function'));
    return (0, _privateAlias.default)(target, key, desc, alias => function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return (0, _emberLifeline.debounceTask)(this, alias, ...args, wait, immediate);
    });
  });
  _exports.default = _default;
});