define("ember-svg-jar/inlined/savings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M27.126 15.271c.048.383.074.79.074 1.222 0 2.456-.743 4.107-1.457 5.133-.36.516-.715.88-.975 1.112a4.165 4.165 0 01-.378.3l-.014.01a.8.8 0 00-.376.678V26.4a.8.8 0 01-.8.8h-2.214a.186.186 0 01-.186-.186c0-.987-.8-1.787-1.786-1.787h-2.828c-.986 0-1.786.8-1.786 1.787a.186.186 0 01-.186.186H12a.8.8 0 01-.8-.8v-1.359a.799.799 0 00-.546-.759 3.303 3.303 0 01-.434-.24c-.35-.228-.906-.65-1.65-1.404a7.978 7.978 0 01-1.596-2.38c-.295-.677-.91-1.268-1.734-1.408a.528.528 0 01-.44-.52v-2.411c0-.227.164-.421.388-.459.878-.148 1.502-.821 1.746-1.564.209-.636.584-1.473 1.203-2.101a9.017 9.017 0 011.746-1.356 9.15 9.15 0 01.784-.422l.04-.018.009-.004a.8.8 0 00.484-.735V5.902c.405.354.877.733 1.385 1.073.696.466 1.522.904 2.372 1.063a7.23 7.23 0 01.77-1.516l-.295-.03c-.568-.058-1.25-.373-1.956-.846a13.537 13.537 0 01-1.784-1.465 1.234 1.234 0 00-1.323-.249c-.4.162-.769.563-.769 1.127v3.703c-.154.08-.338.183-.542.306-.584.351-1.35.884-2.06 1.603-.868.88-1.34 1.982-1.585 2.727-.093.285-.303.453-.492.484a2.066 2.066 0 00-1.72 2.037v2.41c0 1.04.749 1.926 1.773 2.099.198.034.414.195.534.47.323.74.92 1.848 1.923 2.864.813.825 1.458 1.323 1.918 1.622.09.059.175.11.251.154v.862a2.4 2.4 0 002.4 2.4h2.214C15.2 28.8 16 28 16 27.014c0-.103.083-.187.186-.187h2.828c.103 0 .186.084.186.187 0 .986.8 1.786 1.786 1.786H23.2a2.4 2.4 0 002.4-2.4v-2.27c.07-.056.148-.122.232-.197.34-.303.784-.76 1.225-1.394.887-1.276 1.743-3.242 1.743-6.046 0-1.161-.162-2.202-.469-3.131a7.22 7.22 0 01-1.205 1.91z\"/><path d=\"M26.883 8.37a5.6 5.6 0 01-1.874 6.667c-.686.5-1.594.464-2.379.14l-4.863-2.015c-.784-.325-1.452-.942-1.584-1.78a5.597 5.597 0 0110.7-3.012zm-2.815 5.373a3.997 3.997 0 10-6.305-2.611.45.45 0 00.133.237c.103.109.266.225.483.315l4.864 2.014c.217.09.414.123.563.118a.45.45 0 00.262-.073zM10.8 15.2a1.2 1.2 0 100-2.4 1.2 1.2 0 000 2.4z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});