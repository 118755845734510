define("ember-svg-jar/inlined/messenger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>messenger-icon</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M25.583 4.42a3.004 3.004 0 00-3.008-3.003H11.092A3.008 3.008 0 008.083 4.42v6.804a2.996 2.996 0 002.997 3.005h4.87l5.579 2.837V14.23h1.054c1.657 0 3-1.347 3-3.005V4.421z\" stroke=\"#FFF\" stroke-width=\"1.5\" fill=\"#2B6CDE\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M1.417 7.754A3.004 3.004 0 014.425 4.75h11.483a3.008 3.008 0 013.009 3.004v6.805a2.996 2.996 0 01-2.997 3.004h-4.87L5.47 20.4v-2.837H4.417c-1.657 0-3-1.347-3-3.004V7.754z\" stroke=\"#FFF\" stroke-width=\"1.5\" fill=\"#2B6CDE\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><g fill=\"#FFF\"><path d=\"M6.25 12.25c.687 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25C5.562 9.75 5 10.313 5 11c0 .688.562 1.25 1.25 1.25zM14.583 12.25c.688 0 1.25-.563 1.25-1.25 0-.688-.562-1.25-1.25-1.25-.687 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25zM10.417 12.25c.687 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25-.688 0-1.25.563-1.25 1.25 0 .688.562 1.25 1.25 1.25z\"/></g></g>",
    "attrs": {
      "width": "27",
      "height": "22",
      "viewBox": "0 0 27 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});