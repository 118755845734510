define("ember-svg-jar/inlined/customer-satisfaction-rate-5", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5zm0-1.5a9 9 0 100-18 9 9 0 000 18z\" fill=\"#0439D7\"/><path d=\"M7.5 13.5c.75 2.25 2.397 3.75 4.5 3.75s3.75-1.5 4.5-3.75\" stroke=\"#0439D7\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M10.5 9c0 1.243-.84 0-1.875 0C7.589 9 6.75 10.243 6.75 9s.84-1.875 1.875-1.875c1.036 0 1.875.632 1.875 1.875zM17.25 9c0 1.243-.84 0-1.875 0-1.036 0-1.875 1.243-1.875 0s.84-1.875 1.875-1.875c1.035 0 1.875.632 1.875 1.875z\" fill=\"#0439D7\"/><path d=\"M8.262 13.233s-.961-.448-1.41.513M17.148 13.746s-.448-.96-1.41-.513\" stroke=\"#0439D7\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});