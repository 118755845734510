define("ember-svg-jar/inlined/icon-file-extension-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.751 10.837a.491.491 0 01.027.132c0 .005.001.01.003.014.001.006.003.011.003.017v9.5c0 .276-.222.5-.495.5h-2.476v2.5c0 .276-.221.5-.495.5H3.485a.498.498 0 01-.495-.5V21H.515a.498.498 0 01-.495-.5V11c0-.006.001-.011.003-.017a.492.492 0 01.03-.146l.004-.015a.495.495 0 01.107-.175L2.99 7.792V.5c0-.276.222-.5.495-.5h12.377c.006 0 .01.001.016.003l.013.003a.48.48 0 01.131.026l.013.004.032.012a.491.491 0 01.145.099l4.456 4.5a.495.495 0 01.097.146l.013.034.004.013a.494.494 0 01.028.143.06.06 0 01.003.017v2.793l2.826 2.854a.497.497 0 01.096.143c.005.01.008.022.012.033l.004.014zm-2.938-.337h1.28l-1.28-1.293V10.5zm-1.69-6h-2.765V1.707L19.123 4.5zM15.367 1H3.98v9.5h15.843v-5h-3.96a.498.498 0 01-.496-.5V1zM2.99 9.207V10.5H1.71l1.28-1.293zM3.98 23h15.843v-2H3.98v2zm18.814-3H1.01v-8.5h21.784V20zm-14.28-6.933c.202.056.383.155.543.293.16.139.292.322.395.549.104.226.156.51.156.85 0 .306-.046.573-.139.803-.092.23-.214.42-.366.574a1.436 1.436 0 01-.522.34c-.196.073-.398.11-.606.11h-.707V19h-.993v-6.018h1.65c.19 0 .387.029.589.085zm-.093 2.435a.75.75 0 01-.58.251h-.573v-1.912h.573c.235 0 .429.073.58.22.152.148.228.383.228.706 0 .323-.076.568-.228.736zM16.25 19H15.23v-6.018h2.668v.918H16.24v1.64h1.532v.876H16.25V19zm-4.31-6.018h-1.498V19h1.49c.769 0 1.351-.262 1.747-.786.395-.524.593-1.262.593-2.214 0-.953-.198-1.694-.593-2.223-.396-.53-.975-.795-1.738-.795zm.952 4.599c.117-.176.209-.395.273-.66.065-.263.097-.57.097-.921 0-.357-.032-.669-.097-.935a2.047 2.047 0 00-.273-.663 1.186 1.186 0 00-.421-.395 1.097 1.097 0 00-.53-.133h-.48v4.234h.48c.19 0 .367-.044.53-.133.162-.087.303-.219.42-.395z\" fill=\"inherit\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "#C3CBD9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});