define("ember-svg-jar/inlined/animation-vertrage-fallback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.112 12.612c0 6.903-5.596 12.5-12.5 12.5s-12.5-5.597-12.5-12.5 5.596-12.5 12.5-12.5c1.29 0 2.535.196 3.706.56l-.297.956a11.465 11.465 0 00-3.409-.516c-6.34 0-11.5 5.16-11.5 11.5 0 6.341 5.16 11.5 11.5 11.5 6.341 0 11.5-5.159 11.5-11.5 0-3.17-1.29-6.045-3.372-8.128l.711-.71a12.464 12.464 0 013.661 8.838zm-12.5 7.5c-4.135 0-7.5-3.364-7.5-7.5s3.365-7.5 7.5-7.5c.774 0 1.52.118 2.223.336l.297-.956a8.5 8.5 0 103.49 2.11l-.71.71a7.477 7.477 0 012.2 5.3c0 4.136-3.364 7.5-7.5 7.5zm2.474-9.973a3.489 3.489 0 011.026 2.474c0 1.93-1.57 3.5-3.5 3.5s-3.5-1.57-3.5-3.5 1.57-3.5 3.5-3.5c.362 0 .704.071 1.032.173l.295-.95a4.453 4.453 0 00-1.328-.223 4.5 4.5 0 103.182 1.318l-.707.708z\" fill=\"#01051C\"/><circle cx=\"19\" cy=\"2\" r=\"2\" fill=\"#0439D7\"/><circle cx=\"16.875\" cy=\"5.875\" r=\".875\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});