define("ember-svg-jar/inlined/message-bubble", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<mask id=\"a\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"32\" height=\"32\"><path fill=\"#C4C4C4\" d=\"M0 0h56v56H0z\"/></mask><g mask=\"url(#a)\" fill=\"#0439D7\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 1h28a2.002 2.002 0 012 2v21.333a2.002 2.002 0 01-2 2h-3.333v3.999a1.333 1.333 0 01-2.14 1.061l-6.569-4.921a.678.678 0 00-.407-.139H2a2.002 2.002 0 01-2-2V3a2.002 2.002 0 012-2zm28 24a.668.668 0 00.667-.667V3A.668.668 0 0030 2.333H2A.668.668 0 001.333 3v21.333c0 .368.299.666.667.667h15.55c.438 0 .863.144 1.212.408l6.567 4.921.004-4.396c.001-.515.419-.932.934-.933H30z\"/><path d=\"M9.333 15.667a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667zM16 15.667A1.333 1.333 0 1016 13a1.333 1.333 0 000 2.667zM22.667 15.667a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667z\"/></g>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});