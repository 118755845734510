define("ember-lifeline-decorators/destructor", ["exports", "@ember-decorators/utils/decorator", "@ember/debug", "@ember/destroyable", "patch-method"], function (_exports, _decorator, _debug, _destroyable, _patchMethod) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _decorator.decoratorWithParams)(function destructor(target, _key, desc) {
    (false && !(typeof desc.value === 'function') && (0, _debug.assert)("The '@disposable' decorator can only be used on methods.", typeof desc.value === 'function'));
    (0, _patchMethod.afterMethod)(target.constructor, 'init', function () {
      // `.bind` is required because `@ember/destroyable` does not set the correct context
      (0, _destroyable.registerDestructor)(this, desc.value.bind(this));
    });
    return desc;
  });
  _exports.default = _default;
});