define("ember-svg-jar/inlined/dock-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M27.521 3.84h-3.2v22.4a.64.64 0 01-.64.64H5.121v3.2c0 .353.287.64.64.64h21.76a.64.64 0 00.64-.64V4.48a.64.64 0 00-.64-.64zM8.321 16l-3.2-1.92V1.28h3.2V16z\" fill=\"#BFD2F5\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.319 16.64a.634.634 0 01-.328-.092L4.48 14.08.97 16.548a.639.639 0 01-.97-.549V1.92C0 .86.86 0 1.92 0h5.12C8.1 0 8.96.86 8.96 1.92v14.079a.64.64 0 01-.641.641zM1.28 1.92v12.845l2.464-1.732a1.28 1.28 0 011.472 0l2.464 1.732V1.92a.64.64 0 00-.64-.64H1.92a.64.64 0 00-.64.64z\" fill=\"#2B6CDE\"/><path d=\"M27.52 2.56H12.16a.64.64 0 000 1.28h15.36a.64.64 0 01.64.64v25.6a.64.64 0 01-.64.64H5.76a.64.64 0 01-.64-.64V18.56a.64.64 0 10-1.28 0v11.52c0 1.06.86 1.92 1.92 1.92h21.76c1.06 0 1.92-.86 1.92-1.92V4.48c0-1.06-.86-1.92-1.92-1.92z\" fill=\"#2B6CDE\"/><path d=\"M20.48 27.52c0 .353.287.64.64.64h3.84a.64.64 0 000-1.28h-3.84a.64.64 0 00-.64.64zm-9.602-7.04h11.52a.64.64 0 000-1.28h-11.52a.64.64 0 100 1.28zm2.562-5.12h8.96a.64.64 0 000-1.28h-8.96a.64.64 0 100 1.28zm0-5.12h8.96a.64.64 0 000-1.28h-8.96a.64.64 0 100 1.28z\" fill=\"#2B6CDE\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});