define("ember-svg-jar/inlined/consult", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" fill=\"#F5F6FA\"/><path d=\"M19.81 23.498a6.477 6.477 0 01.71-1.5h-5.77a2.25 2.25 0 00-2.25 2.25v.577c0 .893.319 1.757.899 2.436 1.412 1.654 3.49 2.552 6.191 2.713l.451-1.48c-2.502-.08-4.319-.822-5.502-2.207A2.25 2.25 0 0114 24.825v-.578a.75.75 0 01.75-.749h5.06zM20.499 10a5.001 5.001 0 110 10.002 5.001 5.001 0 010-10.002zm0 1.5a3.5 3.5 0 100 7.002 3.5 3.5 0 000-7.002zm11.001 14a5.501 5.501 0 01-8.168 4.813l-2.187.665a.5.5 0 01-.624-.624l.666-2.187A5.501 5.501 0 1131.5 25.498zM23.998 24a.5.5 0 100 1H28a.5.5 0 100-1h-4zm-.5 2.5a.5.5 0 00.5.5h2a.5.5 0 100-1h-2a.5.5 0 00-.5.5z\" fill=\"#203151\"/><rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#CFD3DA\"/>",
    "attrs": {
      "width": "41",
      "height": "40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});