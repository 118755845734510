define("ember-svg-jar/inlined/shieldTicked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><mask id=\"shieldTicked_svg__c\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"-3.762\" y=\"-3.762\" width=\"28.491\" height=\"27.78\"><path fill=\"#fff\" d=\"M-3.762-3.762h28.491v27.78H-3.762z\"/><use xlink:href=\"#shieldTicked_svg__a\"/></mask><mask id=\"shieldTicked_svg__d\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"-3.762\" y=\"-3.762\" width=\"14.86\" height=\"12.973\"><path fill=\"#fff\" d=\"M3.182 3.809h14.86v12.973H3.182z\"/><use xlink:href=\"#shieldTicked_svg__b\"/></mask><ellipse id=\"shieldTicked_svg__a\" cx=\"10.483\" cy=\"10.128\" rx=\"10.483\" ry=\"10.128\"/><path id=\"shieldTicked_svg__b\" d=\"M6.944 10.352l2.412 2.669 4.924-5.45\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M18.783 1.664c-4.48 4.093-9.777 5.949-16.691 5.949 0 10.658-2.347 25.927 16.69 32.127 19.04-6.2 16.693-21.469 16.693-32.127-6.915 0-12.213-1.856-16.692-5.95z\" stroke=\"#D6D6D6\" stroke-width=\"2.574\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><g transform=\"translate(8 25.664)\"><use fill=\"#FFF\" xlink:href=\"#shieldTicked_svg__a\"/><use stroke=\"#FFF\" mask=\"url(#shieldTicked_svg__c)\" stroke-width=\"7.524\" stroke-linecap=\"round\" stroke-linejoin=\"round\" xlink:href=\"#shieldTicked_svg__a\"/><use stroke=\"#4679D9\" class=\"svg-brand-primary-stroke\" stroke-width=\"2.574\" stroke-linecap=\"round\" stroke-linejoin=\"round\" xlink:href=\"#shieldTicked_svg__a\"/></g><g transform=\"translate(8 25.664)\"><use fill=\"#FFF\" xlink:href=\"#shieldTicked_svg__b\"/><use stroke=\"#FFF\" mask=\"url(#shieldTicked_svg__d)\" stroke-width=\"7.524\" stroke-linecap=\"round\" stroke-linejoin=\"round\" xlink:href=\"#shieldTicked_svg__b\"/><use stroke=\"#4679D9\" class=\"svg-brand-primary-stroke\" stroke-width=\"2.574\" stroke-linecap=\"round\" stroke-linejoin=\"round\" xlink:href=\"#shieldTicked_svg__b\"/></g></g>",
    "attrs": {
      "width": "37",
      "height": "50",
      "viewBox": "0 0 37 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});