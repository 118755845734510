define("ember-svg-jar/inlined/lock-secure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 12.5a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0v-2.25A.75.75 0 018 12.5z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.5 7.25V5a4.5 4.5 0 019 0v2.25H14a1.5 1.5 0 011.5 1.5V20a1.5 1.5 0 01-1.5 1.5H2A1.5 1.5 0 01.5 20V8.75A1.5 1.5 0 012 7.25h1.5zM11 5v2.25H5V5a3 3 0 016 0zM2 8.75V20h12V8.75H2z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "16",
      "height": "22",
      "viewBox": "0 0 16 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});