define("ember-routing-utils/services/routing-utils", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let RoutingUtilsService = (_class = class RoutingUtilsService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "router", _descriptor, this);
    }
    /**
     * Retrieves all parameters for a `RouteInfo` object and its parents in
     * correct oder, so that you can pass them to e.g.
     * `transitionTo(routeName, ...params)`.
     *
     * @param routeInfo
     */
    getParameters(routeInfo) {
      let allParameters = [];
      let current = routeInfo;
      do {
        const {
          params,
          paramNames
        } = current;
        const currentParameters = paramNames.map(n => params[n]);
        allParameters = [...currentParameters, ...allParameters];
        // eslint-disable-next-line no-cond-assign
      } while (current = current.parent);
      return allParameters;
    }

    /**
     * Same as `getParameters`, but also includes the final `{ queryParams }` for
     * convenience.
     *
     * Retrieves all parameters for a `RouteInfo` object and its parents in
     * correct oder, so that you can pass them to e.g.
     * `transitionTo(routeName, ...params)`.
     *
     * @param routeInfo
     */
    getParametersWithQueryParameters(routeInfo) {
      return [...this.getParameters(routeInfo), {
        queryParams: routeInfo.queryParams
      }];
    }

    /**
     * Builds the URL for a `RouteInfo` object and its parents.
     *
     * @param routeInfo
     *
     * {@link https://github.com/emberjs/rfcs/issues/658}
     */
    getURLFromRouteInfo(routeInfo) {
      const {
        name,
        queryParams
      } = routeInfo;
      const orderedParameters = this.getParameters(routeInfo);
      const url = this.router.urlFor(name, ...orderedParameters, {
        queryParams
      });
      return url;
    }

    /**
     * Removes the `rootURL` from a URL, so that it can be used with
     * `transitionTo()`, because Ember handles this inconsistently. 🤡
     *
     * If the URL does not start with the `rootURL` or the app has no `rootURL`,
     * this just returns the original `url`.
     *
     * {@link https://github.com/emberjs/ember.js/issues/18422#issuecomment-538668443}
     */
    removeRootURL(url) {
      const {
        rootURL
      } = this.router;
      return rootURL && url.startsWith(rootURL) ? url.slice(rootURL.length - 1) : url;
    }

    /**
     * Prefixes the `rootURL` to a URL.
     *
     * If the URL already starts with the `rootURL` or the app has no `rootURL`,
     * this just returns the original `url`.
     *
     * {@link https://github.com/emberjs/ember.js/issues/18422#issuecomment-538668443}
     */
    prefixRootURL(url) {
      const {
        rootURL
      } = this.router;
      return rootURL && !url.startsWith(rootURL) ? this.joinPathSegments(rootURL, url) : url;
    }
    joinPathSegments(prefix, suffix) {
      if (!prefix) {
        return suffix;
      }
      if (!suffix) {
        return prefix;
      }
      if (prefix.endsWith('/')) {
        if (suffix.startsWith('/')) {
          return "".concat(prefix).concat(suffix.slice(1));
        }
        return "".concat(prefix).concat(suffix);
      }
      if (suffix.startsWith('/')) {
        return "".concat(prefix).concat(suffix);
      }
      return "".concat(prefix, "/").concat(suffix);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class); // DO NOT DELETE: this is how TypeScript knows how to look up your services.
  _exports.default = RoutingUtilsService;
});