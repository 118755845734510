define("ember-svg-jar/inlined/company-pension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M28.924 7.428H2.607c-.48 0-.731.19-.88.396a1.297 1.297 0 00-.227.712v18.75c0 .746.23 1.142.449 1.353.232.223.506.29.658.29h26.25c.84 0 1.186-.14 1.354-.315.173-.18.289-.532.289-1.328V8.536c0-.338-.086-.543-.185-.675a.939.939 0 00-.43-.305c-.35-.133-.735-.13-.96-.128zm2.192-.166c.247.33.384.754.384 1.274v18.75c0 .81-.098 1.53-.568 2.02-.474.494-1.2.623-2.075.623H2.607a2 2 0 01-1.35-.568C.806 28.929.5 28.254.5 27.286V8.536c0-.348.1-.859.416-1.297.333-.463.885-.81 1.691-.81H28.91c.212-.004.79-.014 1.332.193.308.118.63.314.875.64z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M10.47 2.652c-.292.308-.399.708-.399 1.062V6.93h-1V3.714c0-.538.162-1.21.673-1.75.52-.548 1.335-.893 2.506-.893h8.036c.437 0 1.081.127 1.631.523.575.414 1.012 1.1 1.012 2.12V6.93h-1V3.714c0-.694-.278-1.08-.596-1.308a1.895 1.895 0 00-1.047-.335H12.25c-.972 0-1.496.28-1.78.58zM3 14.533a153.6 153.6 0 00-2.243-1.274l.486-.875c.878.489 1.626.92 2.3 1.308 1.201.692 2.17 1.25 3.231 1.76 1.632.782 3.47 1.441 6.657 2.239 2.6.518 4.022.412 5.652.005a64.275 64.275 0 006.166-2.189c1.14-.475 2.244-.987 3.191-1.508.954-.525 1.72-1.045 2.206-1.531l.708.707c-.586.585-1.45 1.16-2.431 1.7-.99.544-2.129 1.071-3.288 1.555a65.26 65.26 0 01-6.28 2.228l-.01.003-.01.003c-1.792.448-3.367.554-6.112.005l-.011-.002-.012-.003c-3.235-.809-5.144-1.489-6.858-2.311-1.094-.525-2.114-1.113-3.342-1.82z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M13.357 20.857V18.18h1v2.678a.7.7 0 00.12.378c.068.094.186.194.452.194h2.678a.7.7 0 00.378-.12c.094-.069.194-.186.194-.452V18.18h1v2.678c0 .592-.258 1.01-.61 1.263a1.697 1.697 0 01-.962.309H14.93c-.592 0-1.01-.258-1.263-.61a1.696 1.696 0 01-.309-.962z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});