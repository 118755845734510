define("ember-svg-jar/inlined/icon-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M21.245 0C22.215 0 23 .786 23 1.755v6.62c-.007.817-.09 1.178-.488 1.576l-9.61 9.61a1.497 1.497 0 01-2.117 0l-7.346-7.345a1.497 1.497 0 010-2.118l9.61-9.61c.43-.43.82-.492 1.775-.488zm-6.427 1.396c-.55-.003-.73.026-.782.078l-9.61 9.61a.101.101 0 000 .145l7.346 7.346c.04.04.104.04.144 0l9.61-9.611c.053-.053.08-.226.078-.78V1.756a.36.36 0 00-.359-.36h-3.17l-3.12.001h-.137zm4.102.858a1.827 1.827 0 110 3.653 1.827 1.827 0 010-3.653zm0 1.116a.71.71 0 100 1.42.71.71 0 000-1.42z\"/>",
    "attrs": {
      "width": "26",
      "height": "20",
      "viewBox": "0 0 26 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});