define("ember-svg-jar/inlined/icon-nav-concierge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path d=\"M16.648 1.504a10.176 10.176 0 01-6.349 18.992 10.193 10.193 0 106.349-18.992z\" fill=\"#BFD2F5\"/><path d=\"M29.823 22.883l-5.026-5.026a11.405 11.405 0 10-19.254-.34L.177 22.883a.6.6 0 00.378 1.023l3.701.285a.6.6 0 01.552.552l.285 3.702a.6.6 0 001.023.379l6.22-6.22a11.12 11.12 0 005.44.112l6.108 6.108a.6.6 0 001.022-.378l.285-3.703a.6.6 0 01.552-.552l3.702-.285a.6.6 0 00.378-1.023zM6.19 27.053l-.185-2.403a1.791 1.791 0 00-1.657-1.656l-2.4-.184 4.284-4.285a11.416 11.416 0 004.826 3.66L6.19 27.053zM5.073 11.6c0-5.633 4.567-10.2 10.2-10.2 5.634 0 10.2 4.567 10.2 10.2 0 5.633-4.566 10.2-10.2 10.2-5.63-.007-10.193-4.57-10.2-10.2zm20.578 11.394a1.791 1.791 0 00-1.656 1.656l-.185 2.403-4.721-4.721a11.412 11.412 0 004.992-3.495l3.972 3.972-2.402.185z\" fill=\"#2B6CDE\"/><path d=\"M15.273 5a6.6 6.6 0 100 13.2 6.6 6.6 0 000-13.2zm0 12a5.4 5.4 0 110-10.8 5.4 5.4 0 010 10.8z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "30",
      "height": "29",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});