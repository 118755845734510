define("ember-svg-jar/inlined/addPHVInsurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke-width=\"2\" stroke=\"#FFF\" class=\"add-phv-insurance-icon\" fill=\"none\" fill-rule=\"evenodd\"><g stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M14.051 17.48l-12.73 5.762v20.111l24.427 11.165h0l24.658-11.277V23.524l-13.239-5.982\"/><path d=\"M1.439 23.436l24.399 11.069L50.17 23.604M25.25 34.515v19.64\"/><path d=\"M25.514 2.176a7.425 7.425 0 01-5.65 2.577c-1.989 0-3.794-.771-5.67-2.016v14.747s1.642 6.842 11.31 9.418c9.672-2.576 11.31-9.418 11.31-9.418V2.737c-1.876 1.245-3.677 2.016-5.668 2.016-2.275 0-4.303-1.007-5.632-2.577z\"/></g><g transform=\"translate(43.806 10.5)\"><circle fill=\"#497CD6\" cx=\"11\" cy=\"11\" r=\"11\"/><g stroke-linecap=\"round\"><path d=\"M11.25 6.525v10M16.25 11.525h-10\"/></g></g></g>",
    "attrs": {
      "class": "add-phv-insurance",
      "width": "69",
      "height": "56",
      "viewBox": "0 0 69 56",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});