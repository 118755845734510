define("ember-ignore-children-helper/helpers/ignore-children", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.ignoreChildren = ignoreChildren;
  function ignoreChildren(_ref) {
    let [nextHandler] = _ref;
    return function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      let event = args[args.length - 1];
      if (event && event.target === event.currentTarget) {
        nextHandler.apply(this, args);
      }
    };
  }
  var _default = _ember.default.Helper.helper(ignoreChildren);
  _exports.default = _default;
});