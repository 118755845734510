define("ember-svg-jar/inlined/down-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>left-arrow</title><path d=\"M.37 2.32L1.887.526 6.87 6.416 11.853.527 13.37 2.32 6.87 10 .37 2.32z\" fill=\"#78787A\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "14",
      "height": "10",
      "viewBox": "0 0 14 10",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});