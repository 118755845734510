define("ember-svg-jar/inlined/arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.47 4.72a.75.75 0 011.06 0l6.75 6.75a.75.75 0 010 1.06l-6.75 6.75a.75.75 0 11-1.06-1.06l5.47-5.47H2.25a.75.75 0 010-1.5h17.69l-5.47-5.47a.75.75 0 010-1.06z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});