define("ember-svg-jar/inlined/bell-inactive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.561 12.232a7.943 7.943 0 0115.878 0l.088 2.826a.538.538 0 00.093.286l1.36 2.006c.978 1.445.085 3.433-1.681 3.604-2.147.208-5.372.476-7.799.476-2.427 0-5.652-.268-7.798-.476-1.766-.171-2.66-2.16-1.68-3.604l1.358-2.006.722.49-.722-.49a.54.54 0 00.093-.285l.088-2.826zm7.94-5.896a6.146 6.146 0 00-6.144 5.953l-.088 2.826a2.335 2.335 0 01-.401 1.237l-1.36 2.006c-.23.34-.013.77.367.808 2.143.207 5.293.467 7.625.467s5.482-.26 7.625-.467a.513.513 0 00.367-.808l-1.36-2.007a2.335 2.335 0 01-.4-1.236l-.09-2.826A6.146 6.146 0 0016.5 6.336zm-1.387 17.858c.346.302.841.47 1.386.47.545 0 1.04-.168 1.386-.47.326-.284.59-.747.59-1.506h1.798c0 1.23-.455 2.205-1.207 2.86-.732.64-1.674.913-2.567.913-.893 0-1.834-.274-2.567-.912-.752-.656-1.206-1.63-1.206-2.861h1.797c0 .76.264 1.222.59 1.506z\" fill=\"#203151\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});