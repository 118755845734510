define("ember-svg-jar/inlined/document-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M29.318 8.812L21.072.562A1.92 1.92 0 0019.715 0H4.92C3.86 0 3 .86 3 1.92v28.16C3 31.14 3.86 32 4.92 32h13.44a.64.64 0 100-1.28H4.92a.64.64 0 01-.64-.64V1.92a.64.64 0 01.64-.64h14.688l.027 7.048a1.923 1.923 0 001.92 1.912H28.6v7.12c0 .354.277.64.63.64.354 0 .65-.287.65-.64v-7.191a1.92 1.92 0 00-.562-1.357zm-1.662.148h-6.101a.64.64 0 01-.64-.637l-.024-6.132 6.766 6.769z\" fill=\"var(--i-document-warning, currentColor)\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M30.7 26a4.7 4.7 0 11-9.4 0 4.7 4.7 0 019.4 0zm1.3 0a6 6 0 11-12 0 6 6 0 0112 0zm-6.05 3.06a.827.827 0 01-.818-.827c0-.455.38-.819.817-.819a.823.823 0 110 1.646zm.055-6.438c.438 0 .771.345.771.795v2.39c0 .45-.333.784-.782.784a.76.76 0 01-.772-.783v-2.391c0-.45.334-.795.783-.795z\" fill=\"var(--i-document-warning--exclamation, var(--color-cardinal))\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 13.35a.65.65 0 01.65-.65h9.7a.65.65 0 110 1.3h-9.7a.65.65 0 01-.65-.65zM8 17.35a.65.65 0 01.65-.65h4.7a.65.65 0 110 1.3h-4.7a.65.65 0 01-.65-.65zM8 21.35a.65.65 0 01.65-.65h4.7a.65.65 0 110 1.3h-4.7a.65.65 0 01-.65-.65z\" fill=\"var(--i-document-warning, currentColor)\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});