define("ember-svg-jar/inlined/action-file-claim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.443 24.893c-.11.518.557.828.883.411L20.368 12.46a.5.5 0 00-.394-.808H15.3a.125.125 0 01-.12-.16l2.63-8.85A.5.5 0 0017.33 2h-5.59a.5.5 0 00-.474.342l-3.99 11.94a.5.5 0 00.577.649l3.667-.765a.125.125 0 01.147.148l-2.223 10.58zm9.506-12.241l-8.085 10.34 1.781-8.472a1.125 1.125 0 00-1.33-1.333l-2.815.587L12.1 3h4.56l-2.44 8.206a1.125 1.125 0 001.079 1.446h3.65z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});