define("ember-svg-jar/inlined/checkMark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Icon / Checkmark</title><defs><path d=\"M13.985.373a1.118 1.118 0 011.67.002c.46.5.46 1.308-.002 1.807l-8.746 9.445a1.118 1.118 0 01-1.661.007L.354 6.44a1.353 1.353 0 01-.016-1.806 1.118 1.118 0 011.67-.017l4.058 4.308 7.92-8.552z\" id=\"checkMark_svg__a\"/></defs><g transform=\"translate(1 .667)\" fill=\"none\" fill-rule=\"evenodd\"><mask id=\"checkMark_svg__b\" fill=\"#fff\"><use xlink:href=\"#checkMark_svg__a\"/></mask><use stroke=\"#2B6CDE\" stroke-width=\".2\" fill=\"#979797\" fill-rule=\"nonzero\" xlink:href=\"#checkMark_svg__a\"/><g mask=\"url(#checkMark_svg__b)\" fill=\"#2B6CDE\"><path d=\"M-8-10.667h32v32H-8z\"/></g></g>",
    "attrs": {
      "width": "18",
      "height": "13",
      "viewBox": "0 0 18 13",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});