define("ember-css-modules-active-route/services/css-modules-active-route/engine-router", ["exports", "@ember/application", "@ember/debug", "@ember/object", "@ember/object/evented", "@ember/service", "ember-css-modules-active-route/utils/owner"], function (_exports, _application, _debug, _object, _evented, _service, _owner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  /**
   * This is an incomplete implementation of a would-be `RouterService` scoped to
   * engines.
   *
   * @see https://github.com/ember-engines/ember-engines/issues/587
   */
  let EngineRouterService = (_dec = (0, _object.computed)('rootApplication'), (_class = class EngineRouterService extends _service.default.extend(_evented.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "engine", (0, _application.getOwner)(this));
      _defineProperty(this, "rootApplication", (0, _owner.getRootOwner)(this));
    }
    init() {
      super.init();
      this.externalRouter.on('routeWillChange', this.onRouteWillChange);
      this.externalRouter.on('routeDidChange', this.onRouteDidChange);
    }
    destroy() {
      this.externalRouter.off('routeWillChange', this.onRouteWillChange);
      this.externalRouter.off('routeDidChange', this.onRouteDidChange);
      return super.destroy();
    }
    onRouteWillChange(transition) {
      this.trigger('routeWillChange', this.buildInternalTransition(transition));
    }
    onRouteDidChange(transition) {
      this.trigger('routeDidChange', this.buildInternalTransition(transition));
    }
    get mountPoint() {
      return this.engine.mountPoint;
    }
    get externalRouter() {
      return this.rootApplication.lookup('service:router');
    }
    belongsToEngine(externalRouteName) {
      return externalRouteName === this.mountPoint || externalRouteName.startsWith("".concat(this.mountPoint, "."));
    }
    getRelativeInternalRouteName(externalRouteName) {
      if (externalRouteName === this.mountPoint) {
        return 'application';
      }
      (false && !(this.belongsToEngine(externalRouteName)) && (0, _debug.assert)("'".concat(externalRouteName, "' is not a sub-route of '").concat(this.mountPoint, "'"), this.belongsToEngine(externalRouteName)));
      return externalRouteName.slice(this.mountPoint.length + 1);
    }
    buildInternalTransition(transition) {
      return {
        from: transition.from && this.buildInternalRouteInfo(transition.from),
        to: this.buildInternalRouteInfo(transition.to)
      };
    }
    buildInternalRouteInfo(routeInfo) {
      if (!this.belongsToEngine(routeInfo.name)) return null;
      return {
        name: this.getRelativeInternalRouteName(routeInfo.name),
        parent: routeInfo.parent && this.buildInternalRouteInfo(routeInfo.parent)
      };
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onRouteWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRouteWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRouteDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRouteDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "externalRouter", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "externalRouter"), _class.prototype)), _class));
  _exports.default = EngineRouterService;
});