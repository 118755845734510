define("ember-svg-jar/inlined/add-square-multi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M25.6 8.428V20.8a4.8 4.8 0 01-4.8 4.8H8.428a3.199 3.199 0 002.772 1.6h9.6a6.4 6.4 0 006.4-6.4v-9.6a3.199 3.199 0 00-1.6-2.772zM18.4 15.2a.8.8 0 000-1.6h-3.2v-3.2a.8.8 0 00-1.6 0v3.2h-3.2a.8.8 0 000 1.6h3.2v3.2a.8.8 0 001.6 0v-3.2h3.2zm2.4-10.4A3.2 3.2 0 0124 8v12.8a3.2 3.2 0 01-3.2 3.2H8a3.2 3.2 0 01-3.2-3.2V8A3.2 3.2 0 018 4.8h12.8zM22.4 8a1.6 1.6 0 00-1.6-1.6H8A1.6 1.6 0 006.4 8v12.8A1.6 1.6 0 008 22.4h12.8a1.6 1.6 0 001.6-1.6V8z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});