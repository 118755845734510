define("ember-svg-jar/inlined/tick-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Budicon-Shady/Interface/tick-sign</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M46.942 8.258c8.587 10.969 7.637 26.621-2.213 36.471-9.85 9.85-25.502 10.8-36.47 2.213A27.569 27.569 0 1046.941 8.258z\"/><path d=\"M41.126 21.977L28.379 35.522l-7.175-6.472a1.2 1.2 0 10-1.608 1.78l8.048 7.26a1.2 1.2 0 001.678-.067l13.552-14.4a1.2 1.2 0 00-1.748-1.645z\"/><path d=\"M30 0C13.431 0 0 13.431 0 30c0 16.569 13.431 30 30 30 16.569 0 30-13.431 30-30A30 30 0 0030 0zm0 57.6C14.757 57.6 2.4 45.243 2.4 30 2.4 14.757 14.757 2.4 30 2.4c15.243 0 27.6 12.357 27.6 27.6-.017 15.236-12.364 27.583-27.6 27.6z\"/></g>",
    "attrs": {
      "width": "60",
      "height": "60",
      "viewBox": "0 0 60 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});