define("ember-svg-jar/inlined/addHRInsurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke-width=\"2\" stroke=\"#FFF\" class=\"add-hr-insurance-icon\" fill=\"none\" fill-rule=\"evenodd\"><g stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M11.25 29.822v26.494h38.445v-26.41h9.988c.55 0 .674-.31.282-.69L31.995 2.052a1.036 1.036 0 00-1.428.005l-8.418 8.175V5.472H11.805v14.472l-9.442 9.262c-.395.387-.264.697.277.692l8.61-.076z\"/><path d=\"M23.465 56.151v-25.49h15.392v25.655\"/></g><g transform=\"translate(46.806 11.5)\"><circle fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" cx=\"11\" cy=\"11\" r=\"11\"/><g stroke-linecap=\"round\"><path d=\"M11.25 6.525v10M16.25 11.525h-10\"/></g></g></g>",
    "attrs": {
      "class": "add-hr-insurance",
      "width": "72",
      "height": "58",
      "viewBox": "0 0 72 58",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});