define("ember-svg-jar/inlined/calendar-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#0439D7\" d=\"M20.333 9.5V6.17l-2.5-.001v.829a.833.833 0 11-1.666 0v-.83l-8.334-.001v.83a.833.833 0 11-1.666 0v-.83H3.67c-.001 0-.002 1.406-.002 3.333h16.664zm0 1.667H3.668l-.001 9.163c0 .002 16.662.003 16.662.003.002 0 .003-5.09.004-9.166zm-2.5-6.667h2.496c.923 0 1.671.746 1.671 1.67v14.16A1.67 1.67 0 0120.33 22H3.67A1.67 1.67 0 012 20.33V6.17c0-.922.748-1.67 1.67-1.67h2.497V2.836a.833.833 0 111.666 0V4.5h8.334V2.836a.833.833 0 111.666 0V4.5z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});