define("ember-svg-jar/inlined/laptop-smartphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.213 21.053c.343 0 .62-.295.62-.658 0-.364-.277-.658-.62-.658H2.463V1.974c0-.364.278-.658.62-.658h24.815c.343 0 .62.294.62.658v5.263c0 .363.278.658.62.658.343 0 .621-.295.621-.658V1.974C29.76.884 28.926 0 27.9 0H3.083c-1.028.001-1.86.884-1.861 1.974v17.763h-.62c-.343 0-.62.294-.62.658v1.316c0 1.816 1.388 3.289 3.1 3.289h16.13c.343 0 .62-.294.62-.658 0-.363-.277-.658-.62-.658H3.083c-1.027-.001-1.86-.884-1.86-1.974v-.657h17.99z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.315 9.21h7.444c.686 0 1.241.59 1.241 1.316v13.158c0 .727-.555 1.316-1.24 1.316h-7.445c-.685 0-1.24-.59-1.24-1.316V10.526c0-.726.555-1.315 1.24-1.315zm0 14.474h7.444V10.526h-7.444v13.158z\" fill=\"currentColor\"/><path d=\"M25.417 22.369h1.24c.343 0 .62-.295.62-.658 0-.364-.277-.658-.62-.658h-1.24c-.343 0-.62.294-.62.658 0 .363.277.658.62.658z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "31",
      "height": "25",
      "viewBox": "0 0 31 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});