define("@clarksource/ember-brand-config/index", ["exports", "@clark-utils/enums-and-types"], function (_exports, _enumsAndTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const config = {
    brand: 'clark',
    brandFull: 'Clark',
    locale: {
      languageTag: 'de-at',
      countryCode: 'at',
      dialCode: '+43'
    },
    enableNewLMFeatures: false,
    contactableByEmail: true,
    contactableByPhone: true,
    logoName: 'clarkLogoNew',
    // null for clark as they use the ark icon for loading pages
    loadingIcon: null,
    secondaryLogo: null,
    relativeURLs: false,
    avatar: {
      name: 'clavitar',
      type: 'svg'
    },
    arkIcon: true,
    categoryIdentsLowMarginRuleMatrix: ['875823e3',
    // Reiseversicherung
    '5bfa54ce',
    // Rechtsschutzversicherung
    '03b12732',
    // Privathaftpflicht
    'smartphone',
    // Handyversicherung
    '5dea25ae' // Fahrradversicherung
    ],

    categoryIdentsLowMarginAutomated: ['58680af3',
    // KFZ-Absicherung (umbrella)
    '15f6b555',
    // KFZ-Versicherung
    'f2a9c2e0',
    // Haushaltsversicherung
    '47a1b441' // Eigenheimversicherung
    ],

    categoryIdentsLowMarginManual: ['cf064be0',
    // Unfallversicherung
    'f729a5c6',
    // Anhängerversicherung
    '7afbebb8',
    // Bauherren-Haftpflicht
    '73499856',
    // Begräbniskostenversicherung
    '35196803',
    // Berufs- und Organhaftpflichtversicherung
    '5ad32b87',
    // Cyberversicherung
    'a4d0a641',
    // Drohnen- und Kopterhaftpflichtversicherung
    '465dc897',
    // Jugend- und Studentenversicherung
    'c1f180cd',
    // Mofa- und Mopedversicherung
    '360b6021',
    // Motorradversicherung
    '08e4af50',
    // Tierkrankenversicherung
    '0218c56d',
    // Tierhalterhaftpflichtversicherung
    'dfb81341',
    // Wohnhausgesamtversicherung
    '975ad2bd' // Wohnwagen- und Wohnmobilversicherung
    ],

    // access to everything apart from initial set creds screens
    disabledSections: [_enumsAndTypes.Section.password_reset, _enumsAndTypes.Section.gkv],
    companyIdent: 'clarkCompany',
    managesOwnDocuments: true,
    messenger: true,
    manager: {
      optimisation: {
        initialSlide: 1
      }
    },
    automatedOffers: {
      gkv: false,
      kfz: false
    },
    productDetails: {
      expertAdvices: true,
      companyRatings: true,
      offerCta: true,
      renewalTime: true,
      questionnaireStatus: true,
      messages: true,
      giveFeedback: true,
      canDoQuestionnaire: true,
      contractEndDate: true,
      salesFee: false,
      insuranceQualityBanner: false
    },
    contracts: {
      autoSwitch: undefined,
      details: {
        features: {
          offers: false
        },
        selfEntry: undefined
      },
      documentScan: {
        imageExtentions: ['png', 'jpg', 'jpeg', 'jpe', 'jif', 'jfif', 'jfi', 'webp', 'gif', 'tiff', 'tif']
      },
      selfManagedAlwaysKeeper: false,
      productCard: {
        selfManagedAlwaysFirst: false,
        questionnaireStatus: true,
        adviceStatus: true,
        insuranceQualityBanner: false
      },
      agent: {
        name: 'Alexander Schecher',
        role: 'CLARK-Experte',
        image: '/engines-dist/@clarksource/engine-cockpit-retirement/consultants/alex-schecher.png'
      }
    },
    inquiryDetails: {
      alwaysBlacklist: false,
      pickUpService: true,
      companyRatings: true,
      showWaitingTime: true
    },
    categoryDetails: {
      qualityStandards: false,
      clarkService: true
    },
    rating: {
      threshold: 3,
      showApp: true,
      showWeb: true,
      webUrl: 'https://de.trustpilot.com/evaluate/clark.de',
      positiveRatedFirstReminder: 90,
      positiveRatedSecondReminder: 180,
      notRatedReminder: 180,
      negativeRatedReminder: 360
    },
    recommendation: {
      agent: {
        name: 'Alexander Schecher',
        role: 'CLARK-Experte',
        image: '/engines-dist/@clarksource/engine-cockpit-retirement/consultants/alex-schecher.png'
      }
    },
    retirement: {
      questionnaireSource: 'austria',
      canDoQuestionnaire: true,
      skipInputWizardIdents: ['vorsorgeprivat'],
      canViewRecommendations: true,
      skipAddProduct: true,
      hideInputDetailsTab: true,
      prefillTargetingCategory: 'Altersvorsorge',
      thirdpartyCalculation: true,
      agent: {
        name: 'Isabella Haider',
        profession: 'retirement',
        role: 'Clark Experte',
        image: '/engines-dist/@clarksource/engine-cockpit-retirement/consultants/isabella-haider.png'
      },
      appointments: true,
      disableGavInitialCta: true,
      retirementcheck: {
        questions: 6,
        mins: 3
      }
    },
    inviteFriends: {
      forMoney: true
    },
    healthConsentRequired: true,
    demandcheck: {
      questions: 9,
      mins: 2,
      source: 'austria'
    },
    offer: {
      qualityStandards: null,
      clarkService: true,
      showComparisonDocument: true,
      checkout: {
        avatar: true
      },
      generateDocuments: false,
      preferCustomMessage: false
    },
    offerDetails: {
      checkoutButtons: true,
      planNames: false,
      offerDataContractDetails: false,
      skipCheckout: false,
      consultantMessage: true
    },
    questionnaires: {
      appointments: true,
      finanzenWidgetFlow: undefined,
      offerModals: true,
      showLegalInformation: false
    },
    passwordReset: false,
    customColours: false,
    pushNotifications: true,
    modals: {
      nps: true,
      numberOneRec: true,
      demandCheckReminder: true
    },
    automaticDocumentProcessing: true,
    hasAgents: false,
    legal: {
      privacy: {
        url: '/de/datenschutz',
        pdf: 'privacy_pdf'
      },
      terms: {
        url: '/de/agb',
        pdf: 'terms_pdf'
      },
      'about-us': {
        url: '/de/ueber-uns',
        pdf: 'about_us_pdf'
      },
      imprint: {
        url: '/de/impressum'
      },
      'refer-a-friend': {
        url: '/de/teilnahmebedingungen/kwk'
      }
    },
    header: {
      firstInformation: false,
      legal: ['imprint', 'privacy', 'terms']
    },
    agent: {
      displayName: true,
      teamName: null,
      name: 'Felicitas',
      lastName: 'Kittinger',
      role: 'Clark Experte'
    },
    serviceHoursForAppointment: {
      1: {
        from: '08:00',
        to: '19:00'
      },
      2: {
        from: '08:00',
        to: '19:00'
      },
      3: {
        from: '08:00',
        to: '19:00'
      },
      4: {
        from: '08:00',
        to: '19:00'
      },
      5: {
        from: '08:00',
        to: '19:00'
      },
      6: undefined,
      7: undefined
    },
    serviceHoursForSameDayAppointment: {
      1: undefined,
      2: undefined,
      3: undefined,
      4: undefined,
      5: undefined,
      6: undefined,
      7: undefined
    },
    serviceHoursForMessenger: {
      1: {
        from: '08:00',
        to: '19:00'
      },
      2: {
        from: '08:00',
        to: '19:00'
      },
      3: {
        from: '08:00',
        to: '19:00'
      },
      4: {
        from: '08:00',
        to: '19:00'
      },
      5: {
        from: '08:00',
        to: '19:00'
      },
      6: undefined,
      7: undefined
    },
    directCallNumber: null,
    trustIcons: {
      pages: {
        managerAppointmentConfirmation: false,
        managerCategoryDetails: false,
        mandateConfirming: false,
        mandateConfirmingPopup: false,
        mandatePhoneVerification: false,
        offerBottom: false
      },
      show: {
        trustpilot: false
      }
    },
    mandate: {
      voucher: true,
      acceptTerms: null,
      profiling: {
        showCookiesSettings: true,
        showEmailField: true,
        additionToAddress: true
      },
      mandateTermsMap: [{
        intro: true,
        points: 0,
        outro: false
      }, {
        intro: true,
        points: 6,
        outro: true
      }],
      signatureSample: false
    },
    backendExperiments: [],
    experiments: {
      '2022Q2ContractsDetailsEvaluationPriceInsights': {
        control: 0.5,
        v1: 0.5
      },
      '2022Q2ContractsDetailsEvaluationClaimInsights': {
        control: 0.5,
        v1: 0.5
      },
      '2022Q2ContractsDetailsEvaluationCustomerRatings': {
        control: 0.5,
        v1: 0.5
      },
      '2022Q2ContractsOverviewHealthCheckReminder': {
        control: 0.5,
        v1: 0.5
      }
    },
    nativeApp: {
      android: {
        minimumVersion: '3.1.3',
        storeLink: 'https://play.google.com/store/apps/details?id=de.clark.app'
      },
      ios: {
        minimumVersion: '2.0.8',
        storeLink: 'https://itunes.apple.com/de/app/der-versicherungsmanager/id1054790721'
      }
    },
    adjust: {
      appInstallLink: 'https://app.adjust.com/16jy76c_vzrme58'
    },
    auth: {
      thirdPartyLoginProviders: []
    },
    allowSentryUserIds: true,
    highMarginCategoryIdents: ['f6304763', 'e19db46d', '7619902c', '1ded8a0f', '3d439696',
    // bu
    'c1bfed3a', '4ecfab68', '8d5803cf', 'c187d55b', 'ce2b05c5', '179efb93', 'vorsorgeprivat',
    // Vorsorgeprivat
    'bd03dbe5', 'eb577458'],
    automatedLowMarginCategoryIdents: ['3659e48a',
    // gkv
    '03b12732',
    // Phv
    '5bfa54ce',
    // Rechtsschutz
    'cf064be0',
    // Unfallversicherung
    '875823e3',
    // travel insurance
    'e251294f',
    // Hausrat
    '47a1b441' // Wohngebaeude
    ],

    // These popular category idents are used in the mandate funnel
    popularCategoryIdents: ['f2a9c2e0',
    // Haushaltsversicherung
    '15f6b555',
    // Kfz-Versicherung
    '5bfa54ce',
    // Rechtsschutzversicherung
    'cf064be0',
    // Unfallversicherung
    '47a1b441' // Eigenheimversicherung
    ],

    // These offer popular category idents are used in offers composite
    offerPopularCategoryIdents: ['5bfa54ce',
    // Rechtsschutzversicherung
    'f2a9c2e0',
    // Haushaltsversicherung
    'cf064be0',
    // Unfallversicherung
    '3d439696',
    // Berufsunfähigkeitsversicherung
    '875823e3',
    // Reiseversicherung
    'vorsorgeprivat' // Altersvorsorge & Vermögensaufbau
    ],

    customer: {
      phoneVerification: {
        logPhoneNumberToSentry: false
      }
    },
    recommendations: {
      overview: {
        retirement: {
          requiresRetirementcheck: false
        }
      },
      stories: {
        [_enumsAndTypes.Story.BuEducationStory]: true,
        [_enumsAndTypes.Story.ClarkExpertJourney]: true,
        [_enumsAndTypes.Story.PavEducationStory]: false,
        [_enumsAndTypes.Story.BuAppointmentEducation]: false
      }
    },
    benefitsWorldLink: 'https://goclark.at/vorteilswelt',
    articles: {
      pkv_vs_gkv: undefined
    },
    products: {
      [_enumsAndTypes.HMProduct.BU]: undefined
    }
  };
  var _default = config;
  _exports.default = _default;
});