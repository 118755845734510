define("ember-svg-jar/inlined/emailIcon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(1.4 1.395)\" stroke=\"#78787A\" stroke-width=\"1.2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\"><path d=\"M21.6 1.2h0c-.695.485-9.238 6.449-9.664 6.745-.426.297-.724.336-1.136.336-.412 0-.71-.039-1.136-.336C9.238 7.65.696 1.685 0 1.2\"/><rect stroke-linejoin=\"round\" width=\"21.6\" height=\"15.601\" rx=\"1.2\"/></g>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18"
    }
  };
  _exports.default = _default;
});