define("ember-svg-jar/inlined/checklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" fill=\"#F5F6FA\"/><path d=\"M15.28 12.78a.75.75 0 10-1.06-1.06l-1.97 1.97-.47-.47a.75.75 0 10-1.06 1.06l1 1a.75.75 0 001.06 0l2.5-2.5zm14.47 13.227h-11.5l-.102.007a.75.75 0 00.102 1.493h11.5l.102-.007a.75.75 0 00-.102-1.493zm0-6.507h-11.5l-.102.007A.75.75 0 0018.25 21h11.5l.102-.007a.75.75 0 00-.102-1.493zm0-6.5h-11.5l-.102.007a.75.75 0 00.102 1.493h11.5l.102-.007A.75.75 0 0029.75 13zM15.28 25.78a.75.75 0 10-1.06-1.06l-1.97 1.97-.47-.47a.75.75 0 10-1.06 1.06l1 1a.75.75 0 001.06 0l2.5-2.5zm0-7.56a.75.75 0 010 1.06l-2.5 2.5a.75.75 0 01-1.06 0l-1-1a.75.75 0 111.06-1.06l.47.47 1.97-1.97a.75.75 0 011.06 0z\" fill=\"#203151\"/><rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#CFD3DA\"/>",
    "attrs": {
      "width": "41",
      "height": "40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});