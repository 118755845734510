define("ember-svg-jar/inlined/facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.702 6.245V3.96c0-.7.763-.864 1.12-.864h2.13V.012L6.51 0C3.179 0 2.418 2.3 2.418 3.774v2.47H0v3.178h2.418V18h3.284V9.422H8.49L9 6.245H5.702z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "9",
      "height": "18",
      "viewBox": "0 0 9 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});