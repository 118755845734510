define("ember-svg-jar/inlined/lightbulbSimple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke-width=\"2\" stroke=\"#2B6CDE\" class=\"svg-brand-primary-stroke\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\"><path d=\"M12.379 17.323c.29-1.672 1.046-3.363 2.248-5.029a6.971 6.971 0 001.22-5.293h0c-.511-2.996-2.929-5.361-6.014-5.887-2.278-.387-4.567.221-6.281 1.671C1.93 4.157 1 6.137 1 8.218c0 1.368.404 2.7 1.167 3.849 1.175 1.769 1.953 3.543 2.31 5.273\" stroke-linejoin=\"round\"/><path d=\"M5.018 21h6.964M6.372 24h4.018M8.4 17.75V8.145\"/></g>",
    "attrs": {
      "width": "17",
      "height": "25",
      "viewBox": "0 0 17 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});