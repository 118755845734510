define("ember-svg-jar/inlined/red-cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M48 12c19.882 0 36 16.118 36 36S67.882 84 48 84 12 67.882 12 48s16.118-36 36-36z\" fill=\"#F26363\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M58.537 63.063a3.2 3.2 0 104.526-4.526L52.525 48l10.538-10.537a3.2 3.2 0 10-4.526-4.526L48 43.475 37.463 32.936a3.2 3.2 0 10-4.526 4.526L43.475 48 32.936 58.537a3.2 3.2 0 104.526 4.526L48 52.525l10.537 10.538z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "96",
      "height": "96",
      "viewBox": "0 0 96 96",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});