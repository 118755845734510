define("ember-svg-jar/inlined/checkboxorange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g transform=\"translate(1 .818)\" fill=\"none\" fill-rule=\"evenodd\"><ellipse class=\"something\" stroke=\"#7ED321\" stroke-width=\"1.26\" fill=\"#7ED321\" cx=\"9.47\" cy=\"9.286\" rx=\"9.47\" ry=\"9.286\"/><path stroke=\"#FFF\" stroke-width=\"1.512\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M5.828 10.69l2.26 2.256 4.842-5.803\"/></g>",
    "attrs": {
      "width": "21",
      "height": "20",
      "viewBox": "0 0 21 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});