define("ember-svg-jar/inlined/add-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#add-insurance_svg__clip0_663_4344)\"><path d=\"M6.71 17.55l13.24-1.4\" stroke=\"#000\" stroke-width=\".93\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M29 56.27c8.36-1 15.34-1.63 25.67-14.47-9-5.89-13.52-20.42-16.52-34.39l-37.55 4C4.6 30.2 11.73 57.69 29 56.25\" fill=\"#fff\"/><path d=\"M29 56.27c8.36-1 15.34-1.63 25.67-14.47-9-5.89-13.52-20.42-16.52-34.39l-37.55 4C4.6 30.2 11.73 57.69 29 56.25\" stroke=\"#000\" stroke-width=\".93\" stroke-miterlimit=\"10\"/><path d=\"M10.48 22.01l23.87-2.55M12.17 26.71l23.86-2.55M13.98 30.81l23.86-2.55M16.56 35.44l23.87-2.55M20.57 40.27l23.86-2.55\" stroke=\"#000\" stroke-width=\".93\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M38.39 18.86c5.158 0 9.34-4.222 9.34-9.43 0-5.208-4.182-9.43-9.34-9.43-5.158 0-9.34 4.222-9.34 9.43 0 5.208 4.182 9.43 9.34 9.43z\" fill=\"#0439D7\"/><path d=\"M38.39 15.28V5.07M33.34 10.18h10.11\" stroke=\"#fff\" stroke-width=\"1.13\" stroke-miterlimit=\"10\"/><path d=\"M78.56 39.16c-3.83 6.7-9.38 12.72-14 13.32L29 56.27c3.9-.63 13.21-5.09 15.9-14.06l33.66-3.05z\" fill=\"#000\" stroke=\"#000\" stroke-width=\".93\" stroke-linejoin=\"round\"/></g><defs><clipPath id=\"add-insurance_svg__clip0_663_4344\"><path fill=\"#fff\" d=\"M0 0h79v57H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "79",
      "height": "57",
      "viewBox": "0 0 79 57",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});