define("ember-svg-jar/inlined/shared-contract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#0439D7\" fill-rule=\"evenodd\" d=\"M7.185 41.248A11.668 11.668 0 0115.5 37.75h21a11.66 11.66 0 018.315 3.498 12.003 12.003 0 013.435 8.419V55a1.25 1.25 0 11-2.5 0v-5.333c0-2.504-.98-4.901-2.716-6.666a9.168 9.168 0 00-6.534-2.75h-21A9.168 9.168 0 008.966 43a9.503 9.503 0 00-2.716 6.666V55a1.25 1.25 0 11-2.5 0v-5.333c0-3.154 1.233-6.183 3.435-8.42zM26.5 11.25a9.25 9.25 0 100 18.5 9.25 9.25 0 000-18.5zM14.75 20.5c0-6.489 5.26-11.75 11.75-11.75s11.75 5.261 11.75 11.75c0 6.49-5.26 11.75-11.75 11.75S14.75 26.99 14.75 20.5zm35.041 18.181a1.25 1.25 0 011.528-.89 11.903 11.903 0 016.44 4.33 12.309 12.309 0 012.491 7.427V55a1.25 1.25 0 11-2.5 0v-5.45a9.812 9.812 0 00-1.982-5.917 9.404 9.404 0 00-5.087-3.424 1.25 1.25 0 01-.89-1.528zM39.79 9.685a1.25 1.25 0 011.526-.894 11.876 11.876 0 016.444 4.306 12.22 12.22 0 012.49 7.403 12.22 12.22 0 01-2.49 7.404 11.876 11.876 0 01-6.444 4.306 1.25 1.25 0 11-.632-2.42 9.376 9.376 0 005.087-3.401A9.72 9.72 0 0047.75 20.5a9.72 9.72 0 00-1.979-5.888 9.376 9.376 0 00-5.087-3.402 1.25 1.25 0 01-.893-1.525z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "64",
      "height": "64",
      "fill": "none",
      "viewBox": "0 0 64 64"
    }
  };
  _exports.default = _default;
});