define("ember-svg-jar/inlined/money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path d=\"M44.72 8.56v23.92H6.24v3.12h37.44a4.16 4.16 0 004.16-4.16V8.56h-3.12z\" fill=\"#BFD2F5\"/><path d=\"M42.64 1.28H41.6v27.04a1.04 1.04 0 01-1.04 1.04H1.04v1.04c0 .574.466 1.04 1.04 1.04h40.56a1.04 1.04 0 001.04-1.04V2.32a1.04 1.04 0 00-1.04-1.04z\" fill=\"#BFD2F5\"/><path d=\"M49.92 6.48h-4.16V3.36A3.12 3.12 0 0042.64.24H3.12A3.12 3.12 0 000 3.36V30.4a3.12 3.12 0 003.12 3.12h3.12v3.12c0 1.72 2.44 3.12 4.16 3.12h39.52c1.72 0 2.08-1.4 2.08-3.12V9.6c0-1.72-.36-3.12-2.08-3.12zM3.12 31.44a1.04 1.04 0 01-1.04-1.04V3.36c0-.574.466-1.04 1.04-1.04h39.52c.574 0 1.04.466 1.04 1.04V30.4a1.04 1.04 0 01-1.04 1.04H3.12zm46.8 6.24H10.4c-.574 0-2.08-.466-2.08-1.04v-3.12h34.32a3.12 3.12 0 003.12-3.12V8.56h4.16c.574 0 0 .466 0 1.04v27.04c0 .574.574 1.04 0 1.04z\" fill=\"#2B6CDE\"/><path d=\"M23.92 8.56a8.32 8.32 0 100 16.64 8.32 8.32 0 000-16.64zm0 14.56a6.24 6.24 0 116.24-6.24 6.247 6.247 0 01-6.24 6.24zM40.56 4.4H36.4a1.04 1.04 0 000 2.08h4.16a1.04 1.04 0 000-2.08zM9.36 27.28H5.2a1.04 1.04 0 000 2.08h4.16a1.04 1.04 0 000-2.08z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "52",
      "height": "40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});