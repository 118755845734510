define("ember-svg-jar/inlined/household", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#household_svg__clip0)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M8 18.5a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h4a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5H8zm.5 4v-3h3v3h-3z\"/><path d=\"M16.328 1.623a.5.5 0 00-.67.011l-15 14a.5.5 0 10.683.732L4 13.884V28c0 .396.087.895.404 1.305C4.74 29.74 5.271 30 6 30h20.536c.224.001.667.004 1.005-.334.34-.34.459-.9.459-1.666V13.115l3.172 2.762a.5.5 0 10.656-.754l-15.5-13.5zM27 12.243l-10.988-9.57L4.947 13H5v15c0 .27.063.522.196.695.115.15.333.305.804.305h9.5v-8.889c0-.27.08-.66.335-.996.271-.36.712-.615 1.332-.615h4.666c.27 0 .667.073 1.012.31.372.255.655.683.655 1.301V29h3c.29 0 .316-.023.334-.041.035-.035.166-.225.166-.959V12.244zM22.5 29h-6v-8.889c0-.1.036-.265.132-.393.078-.103.22-.218.535-.218h4.666c.12 0 .306.038.447.135.114.078.22.205.22.476V29z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});