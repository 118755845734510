define("ember-svg-jar/inlined/rocket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.208 10.282a3.197 3.197 0 114.52 4.523 3.197 3.197 0 01-4.52-4.523zm3.39 1.133a1.597 1.597 0 10-2.26 2.257 1.597 1.597 0 002.26-2.257z\"/><path d=\"M18.798 23.208a2.4 2.4 0 01-2.725-.472l-.975-.975-1.172 1.952a.8.8 0 01-1.251.154l-4.53-4.529a.8.8 0 01.155-1.252l1.953-1.17-.976-.976a2.401 2.401 0 01-.472-2.725l-1.792-1.792a.8.8 0 010-1.131L8.71 8.596a4.805 4.805 0 015.46-.942l1.5-1.5a10.071 10.071 0 0110.129-2.49 3.89 3.89 0 012.551 2.55 10.071 10.071 0 01-2.49 10.13l-1.5 1.5a4.805 4.805 0 01-.942 5.46L21.721 25a.8.8 0 01-1.131 0l-1.792-1.792zM25.32 5.19a8.471 8.471 0 00-8.52 2.094L15.497 8.59l.006.006-1.132 1.131-.005-.006-3.396 3.396.006.005-.644.645a.8.8 0 00.077 1.042l6.796 6.795a.8.8 0 001.044.076l.643-.642.005.005 3.395-3.395-.005-.006 1.131-1.13.006.005 1.305-1.305a8.471 8.471 0 002.094-8.52A2.29 2.29 0 0025.32 5.19zm-5.292 16.984l1.128 1.127 1.13-1.13a3.202 3.202 0 00.831-3.087l-3.09 3.09zm-7.1-13.28a3.202 3.202 0 00-3.088.832l-1.13 1.13 1.128 1.128 3.09-3.09zm1.003 11.7l-2.512-2.512-1.417.849 3.08 3.08.85-1.417zM9.284 23.86a.8.8 0 00-1.131-1.132l-2.83 2.83a.8.8 0 001.132 1.13l2.83-2.828zM7.02 20.464a.8.8 0 010 1.131L5.886 22.73a.8.8 0 11-1.132-1.13l1.134-1.135a.8.8 0 011.132 0zM11.549 26.124a.8.8 0 10-1.131-1.131l-1.135 1.134a.8.8 0 001.132 1.132l1.134-1.135z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});