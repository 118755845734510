define("ember-svg-jar/inlined/documentNew", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Shape</title><path d=\"M26.728 12.34a.554.554 0 00-.03-.148c-.006-.018-.01-.036-.018-.053a.556.556 0 00-.108-.16v-.002l-3.179-3.21V5.625c0-.012-.006-.023-.007-.035a.559.559 0 00-.028-.145c-.007-.018-.011-.036-.02-.053a.556.556 0 00-.108-.165L18.217.165a.552.552 0 00-.164-.11c-.016-.008-.033-.013-.05-.019a.542.542 0 00-.147-.03c-.011 0-.022-.006-.033-.006H3.9a.56.56 0 00-.557.563v8.204l-3.179 3.21v.001a.556.556 0 00-.108.16c-.008.018-.012.036-.018.054a.554.554 0 00-.03.148c0 .012-.007.023-.007.035v10.688c0 .31.25.562.557.562h2.785v2.813c0 .31.25.562.557.562h18.937a.56.56 0 00.557-.563v-2.812h2.785a.56.56 0 00.557-.563V12.376c0-.012-.006-.023-.007-.035zm-1.895-.527h-1.44v-1.455l1.44 1.454zm-3.342-6.75h-3.11V1.92l3.11 3.143zM4.456 1.124h12.81v4.5c0 .31.25.563.557.563h4.456v5.625H4.456V1.124zm-1.114 9.233v1.454h-1.44l1.44-1.454zm18.937 15.517H4.456v-2.25h17.823v2.25zm3.342-3.375H1.114v-9.563h24.507V22.5z\" fill-rule=\"nonzero\" fill=\"#2B6CDE\"/>",
    "attrs": {
      "width": "27",
      "height": "27",
      "viewBox": "0 0 27 27",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});