define("ember-svg-jar/inlined/insurance-badge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 24h24V.003H0V24z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.425 17.615V6.423c0-.903-.38-1.198-1.235-1.198H9.625v1.236h.285c.475 0 .665.18.665.731v10.423h2.85zm1.52 0h2.756V9.168c0-.855-.38-1.197-1.235-1.197h-1.52v9.644zm-5.89 0V7.971h-1.52c-.856 0-1.236.342-1.236 1.197v8.447h2.755zm11.877-5.624c0 5.235-3.801 9.055-8.932 9.055s-8.932-3.82-8.932-9.055c0-5.236 3.801-9.056 8.932-9.056 5.13 0 8.931 3.82 8.931 9.056zm1.805 0c0-6.129-4.561-10.728-10.737-10.728-6.081 0-10.737 4.599-10.737 10.728 0 6.147 4.656 10.746 10.737 10.746 6.176 0 10.737-4.599 10.737-10.746z\" fill=\"#575970\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});