define("ember-svg-jar/inlined/doc-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M40.5 80c22.091 0 40-17.909 40-40S62.591 0 40.5 0 .5 17.909.5 40s17.909 40 40 40z\" fill=\"#0439D7\"/><path d=\"M46 40a5.5 5.5 0 110 11 5.5 5.5 0 010-11zm0 2l-.09.008a.5.5 0 00-.402.402l-.008.09V45h-2.502l-.09.009a.5.5 0 00-.402.402l-.008.09.008.09a.5.5 0 00.402.402l.09.008H45.5v2.503l.008.09a.5.5 0 00.402.402l.09.008.09-.008a.5.5 0 00.402-.402l.008-.09V46h2.504l.09-.007a.5.5 0 00.401-.402l.009-.09-.009-.09a.5.5 0 00-.402-.402l-.09-.008H46.5v-2.5l-.008-.09a.5.5 0 00-.402-.403L46 42zm-3.575-11.496a2.25 2.25 0 011.94 1.11l.814 1.387h2.071a3.25 3.25 0 013.25 3.25v4.56a6.52 6.52 0 00-1.499-1.077L49 36.25a1.75 1.75 0 00-1.75-1.75h-2.5a.75.75 0 01-.647-.37l-1.032-1.757a.75.75 0 00-.646-.37h-3.803a.75.75 0 00-.574.268l-.065.09-1.094 1.781a.75.75 0 01-.639.358h-2.5A1.75 1.75 0 0032 36.25v9.5c0 .966.783 1.75 1.75 1.75h6.063c.173.533.412 1.037.709 1.5H33.75a3.25 3.25 0 01-3.25-3.25v-9.5A3.25 3.25 0 0133.75 33h2.08l.875-1.424a2.25 2.25 0 011.917-1.073h3.803zM40.5 36a4.502 4.502 0 014.283 3.114c-.5.094-.98.247-1.433.449a2.999 2.999 0 00-5.85.937c0 1.43 1 2.625 2.338 2.927a6.446 6.446 0 00-.31 1.468A4.501 4.501 0 0140.5 36z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "81",
      "height": "80",
      "viewBox": "0 0 81 80",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});