define("ember-file-upload/services/file-queue", ["exports", "@ember/debug", "@ember/service", "@ember/destroyable", "ember-file-upload/queue"], function (_exports, _debug, _service, _destroyable, _queue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DEFAULT_QUEUE = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const DEFAULT_QUEUE = Symbol('DEFAULT_QUEUE');

  /**
   * The file queue service is a global file
   * queue that manages all files being uploaded.
   *
   * This service can be used to query the current
   * upload state when a user leaves the app,
   * asking them whether they want to cancel
   * the remaining uploads.
   */
  _exports.DEFAULT_QUEUE = DEFAULT_QUEUE;
  class FileQueueService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "queues", new Map());
    }
    /**
     * Returns a queue with the given name
     *
     * @param name The name of the queue to find
     * @returns The queue if it exists
     */
    find(name) {
      return this.queues.get(name);
    }

    /**
     * Create a new queue with the given name.
     *
     * @param name The name of the queue to create
     * @returns The new queue.
     */
    create(name) {
      (false && !(!this.queues.has(name)) && (0, _debug.assert)("Queue names are required to be unique. \"".concat(String(name), "\" has already been reserved."), !this.queues.has(name)));
      const queue = new _queue.default({
        name,
        fileQueue: this
      });
      (0, _destroyable.registerDestructor)(queue, () => {
        this.queues.delete(name);
      });
      this.queues.set(name, queue);
      return queue;
    }
    findOrCreate(name) {
      var _this$find;
      return (_this$find = this.find(name)) !== null && _this$find !== void 0 ? _this$find : this.create(name);
    }

    //
    // @TODO
    // Everything below this line should be deprecated ?
    // -------------------------------------------------
    //

    /**
     * The list of all files in queues. This automatically gets
     * flushed when all the files in the queue have settled.
     *
     * @remarks
     * Note that files that have failed need to be manually
     * removed from the queue. This is so they can be retried
     * without resetting the state of the queue, orphaning the
     * file from its queue. Upload failures can happen due to a
     * timeout or a server response. If you choose to use the
     * `abort` method, the file will fail to upload, but will
     * be removed from the requeuing proccess, and will be
     * considered to be in a settled state.
     *
     * @defaultValue []
     * @deprecated use a named queue instead
     */
    get files() {
      return [...this.queues.values()].reduce((acc, queue) => {
        return [...acc, ...queue.files];
      }, []);
    }

    /**
     * The total size of all files currently being uploaded in bytes.
     *
     * @defaultValue 0
     * @deprecated use a named queue instead
     */
    get size() {
      return this.files.reduce((acc, _ref) => {
        let {
          size
        } = _ref;
        return acc + size;
      }, 0);
    }

    /**
     * The number of bytes that have been uploaded to the server.
     *
     * @defaultValue 0
     * @deprecated use a named queue instead
     */
    get loaded() {
      return this.files.reduce((acc, _ref2) => {
        let {
          loaded
        } = _ref2;
        return acc + loaded;
      }, 0);
    }

    /**
     * The current progress of all uploads, as a percentage in the
     * range of 0 to 100.
     *
     * @defaultValue 0
     * @deprecated use a named queue instead
     */
    get progress() {
      const percent = this.loaded / this.size || 0;
      return Math.floor(percent * 100);
    }
  }
  _exports.default = FileQueueService;
});