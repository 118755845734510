define("ember-svg-jar/inlined/action-add-documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 3.5H7c-.8 0-1.5.7-1.5 1.5v18c0 .8.7 1.5 1.5 1.5h14c.8 0 1.5-.7 1.5-1.5V5c0-.8-.7-1.5-1.5-1.5zm.5 19.5c0 .3-.2.5-.5.5H7c-.3 0-.5-.2-.5-.5V5c0-.3.2-.5.5-.5h14c.3 0 .5.2.5.5v18zm-12-13c0-.3.2-.5.5-.5h4c.3 0 .5.2.5.5s-.2.5-.5.5h-4c-.3 0-.5-.2-.5-.5zm9 4c0 .3-.2.5-.5.5h-8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8c.3 0 .5.2.5.5zm0 4c0 .3-.2.5-.5.5h-8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8c.3 0 .5.2.5.5zM3.5 1v2c0 .3-.2.5-.5.5H1C.7 3.5.5 3.3.5 3s.2-.5.5-.5h1.5V1c0-.3.2-.5.5-.5s.5.2.5.5zm0 24v2c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-1.5H1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2c.3 0 .5.2.5.5zm21-22V1c0-.3.2-.5.5-.5s.5.2.5.5v1.5H27c.3 0 .5.2.5.5s-.2.5-.5.5h-2c-.3 0-.5-.2-.5-.5zm3 22c0 .3-.2.5-.5.5h-1.5V27c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-2c0-.3.2-.5.5-.5h2c.3 0 .5.2.5.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});