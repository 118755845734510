define("ember-svg-jar/inlined/devk-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#00884E\" class=\"devk-logo-fill\" fill-rule=\"evenodd\"><path d=\"M11.088 30.306l1.968.02h1.721c1.265-.045 2.58-.045 3.843-.382 4.202-.946 5.213-3.697 5.667-6.587.253-1.896.355-3.258.355-5.109 0-1.892-.053-3.79-.406-5.684-.253-1.564-.807-3.179-1.871-4.314-1.97-2.085-5.058-2.04-7.234-2.04h-4.043v24.096zM0 36.999V0h16.884c4.083 0 10.491.055 14.196 3.981C34.684 7.861 35 14.621 35 18.393 35 34.213 26.57 37.054 15.55 37H0zM68 37H42V0h24.715v6.278H52.411v7.883h13.836v6.147h-13.84v9.39H68V37M99.4 37H83.597L73 0h11.278l5.882 23.373c.696 2.672.86 3.403 1.447 6.654.72-3.454.958-4.048 1.601-6.39L99.675 0H110L99.4 37M137.04 13.524L150 37h-10.936l-8.559-15.724-4.603 5.52V37H115V0h10.902v15.353L137.683 0h10.706l-11.35 13.524\"/></g>",
    "attrs": {
      "width": "150",
      "height": "37",
      "viewBox": "0 0 150 37",
      "xmlns": "http://www.w3.org/2000/svg",
      "class": "devk-logo"
    }
  };
  _exports.default = _default;
});