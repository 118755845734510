define("ember-svg-jar/inlined/government", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M20.16 18.48v-.84c0-.504-.336-.84-.84-.84v-5.88c.504 0 .84-.336.84-.84v-.84c0-.504-.336-.84-.84-.84h-.924c-.588-3.696-3.696-6.468-7.476-6.72V.42c0-.252-.168-.42-.42-.42s-.42.168-.42.42v1.26C6.3 1.848 3.192 4.704 2.604 8.4H1.68c-.504 0-.84.336-.84.84v.84c0 .504.336.84.84.84v5.88c-.504 0-.84.336-.84.84v.84c-.504 0-.84.336-.84.84v.84c0 .504.336.84.84.84h19.32c.504 0 .84-.336.84-.84v-.84c0-.504-.336-.84-.84-.84zM10.5 2.52c3.528 0 6.384 2.52 7.056 5.88H3.528c.588-3.36 3.444-5.88 6.972-5.88zM1.68 9.24h17.64v.84H1.68v-.84zm16.8 1.68v5.88h-.84v-5.88h.84zm-1.68 0v5.88h-2.52v-5.88h2.52zm-3.36 0v5.88h-.84v-5.88h.84zm-1.68 0v5.88H9.24v-5.88h2.52zm-3.36 0v5.88h-.84v-5.88h.84zm-1.68 0v5.88H4.2v-5.88h2.52zm-3.36 0v5.88h-.84v-5.88h.84zm-1.68 6.72h17.64v.84H1.68v-.84zm18.48 2.52H.84v-.84h19.32v.84z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});