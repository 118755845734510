define("ember-svg-jar/inlined/marked-shield-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.184 8.03c6.787 0 11.636-4.848 11.636-4.848S19.668 8.03 26.456 8.03v12.606c0 7.758-11.636 12.121-11.636 12.121S3.184 28.394 3.184 20.637V8.03z\" fill=\"#DADDEC\"/><path d=\"M14.533 29.808c-.402.176-1.035.432-1.897.768C4.88 27.666 1 23.626 1 18.454V5.848c4.525 0 8.404-1.616 11.636-4.848 3.233 3.232 7.111 4.848 11.637 4.848\" stroke=\"#2B6CDE\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M13.363 9s-3.88 1.94-7.273 1.94v6.787c0 2.088 1.007 3.971 3.02 5.65\" stroke=\"#2B6CDE\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.544 27.909a8.727 8.727 0 100-17.455 8.727 8.727 0 000 17.455z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.544 27.909a8.727 8.727 0 110-17.455 8.727 8.727 0 010 17.455zm3.634-11.69a.887.887 0 00-1.276.132l-3.533 4.46-1.238-1.304a.887.887 0 00-1.283-.012.958.958 0 00-.011 1.324l1.947 2.049a.888.888 0 001.349-.063l4.173-5.268c.317-.4.26-.99-.128-1.317z\" fill=\"#2B6CDE\"/><mask id=\"marked-shield-1_svg__a\" maskUnits=\"userSpaceOnUse\" x=\"14\" y=\"10\" width=\"19\" height=\"18\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.544 27.909a8.727 8.727 0 110-17.455 8.727 8.727 0 010 17.455zm3.634-11.69a.887.887 0 00-1.276.132l-3.533 4.46-1.238-1.304a.887.887 0 00-1.283-.012.958.958 0 00-.011 1.324l1.947 2.049a.888.888 0 001.349-.063l4.173-5.268c.317-.4.26-.99-.128-1.317z\" fill=\"#fff\"/></mask><g mask=\"url(#marked-shield-1_svg__a)\"><path fill=\"#F99400\" d=\"M14.816 10.454H32.27v17.454H14.816z\"/></g>",
    "attrs": {
      "viewBox": "0 0 33 33",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});