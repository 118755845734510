define("ember-svg-jar/inlined/preloadingCard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.189 29c-.13 0-.261-.023-.386-.072C4.854 26.016 1 22.419 1 17.926V4.15c0-.283.136-.552.371-.73.235-.18.543-.251.84-.194 1.265.242 2.958.257 4.648.036 2.812-.37 4.83-1.249 5.594-1.962.076-.076.165-.14.265-.19a1.07 1.07 0 01.86-.038c.142.055.266.139.365.242.777.711 2.786 1.58 5.579 1.948 1.689.221 3.383.207 4.647-.036.296-.057.606.014.84.193.235.18.372.448.372.731v13.776c0 4.496-3.859 8.094-11.794 10.998a1.083 1.083 0 01-.398.076z\" stroke=\"#D6D6D6\" stroke-width=\"1.75\" fill=\"#FFF\"/>",
    "attrs": {
      "width": "27",
      "height": "30",
      "viewBox": "0 0 27 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});