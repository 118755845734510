define("ember-file-upload/system/upload", ["exports", "@ember/debug", "ember-file-upload/system/http-request", "rsvp", "@ember/test-waiters"], function (_exports, _debug, _httpRequest, _rsvp, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.upload = upload;
  const uploadWaiter = (0, _testWaiters.buildWaiter)('ember-file-upload:upload');
  function clone(object) {
    return object ? {
      ...object
    } : {};
  }
  function normalizeOptions(file, url, options) {
    if (typeof url === 'object') {
      options = url;
      url = null;
    }
    options = clone(options);
    options.url = options.url || url;
    options.method = options.method || 'POST';
    options.accepts = options.accepts || ['application/json', 'text/javascript'];
    if (!Object.prototype.hasOwnProperty.call(options, 'contentType')) {
      options.contentType = file.type;
    }
    options.headers = clone(options.headers);
    options.data = clone(options.data);
    options.fileKey = options.fileKey || 'file';
    if (options.headers.Accept == null) {
      if (!Array.isArray(options.accepts)) {
        options.accepts = [options.accepts];
      }
      options.headers.Accept = options.accepts.join(',');
    }

    // Set Content-Type in the data payload
    // instead of the headers, since the header
    // for Content-Type will always be multipart/form-data
    if (options.contentType) {
      options.data['Content-Type'] = options.contentType;
    }
    options.data[options.fileKey] = file.file;
    options.withCredentials = options.withCredentials || false;
    return options;
  }
  function upload(file, url, opts, uploadFn) {
    if (['queued', 'failed', 'timed_out', 'aborted'].indexOf(file.state) === -1) {
      (false && !(false) && (0, _debug.assert)("The file ".concat(file.id, " is in the state \"").concat(file.state, "\" and cannot be requeued.")));
    }
    const options = normalizeOptions(file, url, opts);
    const request = new _httpRequest.default({
      withCredentials: options.withCredentials,
      label: "".concat(options.method, " ").concat(file.name, " to ").concat(options.url)
    });
    request.open(options.method, options.url);
    Object.keys(options.headers).forEach(function (key) {
      request.setRequestHeader(key, options.headers[key]);
    });
    if (options.timeout) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      request.timeout = options.timeout;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    request.onprogress = function (evt) {
      if (!evt.lengthComputable || evt.total === 0) return;
      file.loaded = evt.loaded;
      file.size = evt.total;
      file.progress = evt.loaded / evt.total * 100;
    };
    request.ontimeout = function () {
      file.state = 'timed_out';
    };
    request.onabort = function () {
      file.state = 'aborted';
    };
    file.state = 'uploading';
    const token = uploadWaiter.beginAsync();
    return uploadFn(request, options).then(function (result) {
      file.state = 'uploaded';
      return result;
    }, function (error) {
      file.state = 'failed';
      return _rsvp.default.reject(error);
    }).finally(() => uploadWaiter.endAsync(token));
  }
});