define("ember-svg-jar/inlined/market-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect x=\".5\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" fill=\"#F5F6FA\"/><path d=\"M21 14.25a1 1 0 11-2 0 1 1 0 012 0zm.032-3.925a1.75 1.75 0 00-2.064 0l-7.421 5.416c-.978.713-.473 2.26.736 2.26h.217v5.8a2.75 2.75 0 00-1.5 2.45v1.5c0 .413.336.75.75.75h7.416a4.754 4.754 0 01-.16-1.5H12.5v-.75c0-.691.56-1.25 1.25-1.25h5.816a4.772 4.772 0 011.268-1.5h-.084V18h1.75v4.665a4.758 4.758 0 011.25-.166H24V18h2v4.5h1.25c.084 0 .167.003.25.007V18h.217c1.21 0 1.714-1.546.736-2.26l-7.421-5.415zm-1.18 1.211a.25.25 0 01.295 0L26.95 16.5h-13.9l6.803-4.964zM19.25 18v5.5H17.5V18h1.75zM14 23.5V18h2v5.5h-2zm17 3.75a3.75 3.75 0 00-3.75-3.75l-.102.007A.75.75 0 0027.25 25l.154.006a2.25 2.25 0 01-.154 4.494l-.003.005-.102.007a.75.75 0 00.108 1.493V31l.2-.005A3.75 3.75 0 0031 27.25zm-6.5-3a.75.75 0 00-.75-.75l-.2.006a3.75 3.75 0 00.2 7.494l.102-.006a.75.75 0 00-.102-1.494l-.154-.005A2.25 2.25 0 0123.75 25l.102-.006a.75.75 0 00.648-.744zm3.5 3a.75.75 0 00-.75-.75h-3.5l-.102.007A.75.75 0 0023.75 28h3.5l.102-.006A.75.75 0 0028 27.25z\" fill=\"#203151\"/><rect x=\".5\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#CFD3DA\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});