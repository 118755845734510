define("ember-svg-jar/inlined/medicine", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.502 11.523H9.275v-1.227c0-.246-.163-.41-.409-.41H7.23c-.245 0-.409.164-.409.41v1.227H5.594c-.245 0-.409.164-.409.409v1.636c0 .245.164.41.409.41h1.227v1.226c0 .246.164.41.41.41h1.635c.246 0 .41-.164.41-.41v-1.227h1.226c.246 0 .41-.164.41-.409v-1.636c0-.245-.164-.41-.41-.41zm-.409 1.636H8.457v1.636H7.64V13.16H6.003v-.818H7.64v-1.636h.818v1.636h1.636v.818zm4.663-5.645l-.981-.654c-.246-.164-.328-.41-.328-.655V4.978h.41c.654 0 1.227-.572 1.227-1.227V2.115c0-.654-.573-1.227-1.227-1.227H2.404c-.655 0-1.228.573-1.228 1.227v1.636c0 .655.573 1.227 1.228 1.227h.409v1.227c0 .246-.164.491-.328.655l-.981.654C.849 8.005.44 8.741.44 9.56v10.554c0 .654.573 1.227 1.227 1.227h13.09c.654 0 1.226-.573 1.226-1.227V9.559c-.163-.818-.572-1.554-1.227-2.045zM2.322 4.16c-.246 0-.41-.163-.41-.409V2.115c0-.245.164-.409.41-.409h2.045v1.227c0 .246.164.41.409.41.245 0 .409-.164.409-.41V1.706h2.454v1.227c0 .246.164.41.41.41.245 0 .408-.164.408-.41V1.706h2.454v1.227c0 .246.164.41.41.41.245 0 .409-.164.409-.41V1.706h2.045c.245 0 .409.164.409.41V3.75c0 .246-.164.41-.41.41H2.323zm12.68 13.907H3.14c-.246 0-.41.164-.41.41 0 .245.164.409.41.409h11.862v1.227c0 .245-.164.409-.41.409H1.505c-.246 0-.41-.164-.41-.41V9.56c0-.572.246-1.063.737-1.39l.982-.655c.49-.327.736-.818.736-1.39V4.897h8.999v1.227c0 .572.245 1.063.736 1.39l.981.655c.491.327.737.818.737 1.39v8.508z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});