define("ember-svg-jar/inlined/noInsurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><ellipse fill=\"#2B6CDE\" cx=\"50.8\" cy=\"51\" rx=\"50.8\" ry=\"51\" class=\"svg-brand-primary-fill\"/><g stroke=\"#FFF\" stroke-width=\"3\"><path fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M95.256 13.895L65.976 10.3l-16.55-2.032-1.89 15.39-4.71 38.36 45.83 5.627z\"/><path fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M14.826 24.895l29.28-3.595 16.55-2.032 1.89 15.39 4.71 38.36-45.83 5.627z\"/><path fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M28.901 30.559h49.631V86.266h-49.63z\"/><g transform=\"translate(40.307 43.083)\"><ellipse cx=\"14.292\" cy=\"14.075\" rx=\"14.292\" ry=\"14.075\"/><g stroke-linecap=\"round\"><path d=\"M20.682 14.112l-12.858-.098M14.204 7.634l.098 12.858\"/></g></g></g></g>",
    "attrs": {
      "width": "102",
      "height": "102",
      "viewBox": "0 0 102 102",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});