define("ember-svg-jar/inlined/starStroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.5 16.754L4.629 20l1.121-6.874L1 8.257l6.564-1.003L10.5 1l2.936 6.254L20 8.257l-4.75 4.869L16.371 20z\" stroke-width=\"2\" stroke=\"currentColor\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "class": "svg-cta-color",
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});