define("ember-svg-jar/inlined/megaphone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke-width=\"3.75\" stroke=\"#FFF\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M94.862 19.018L50.55 30.41H41.9a1.585 1.585 0 00-.198 0c-.971.122-1.707 1.107-1.702 2.278v18.228c0 1.258.85 2.278 1.9 2.278h8.748l5.819 1.496v7.864c.004 1.013.641 1.974 1.464 2.207l21.534 6.076c1.108.312 2.33-.843 2.335-2.208v-7.436l12.904 3.322c.558.143 1.14-.023 1.584-.45.445-.428.707-1.073.712-1.757V21.296c.004-.657-.228-1.284-.638-1.72-.41-.437-.956-.64-1.5-.558zM50.875 30.392v22.645\"/><path d=\"M120.886 27.277l-15.399 6.529M120.886 56.83L105.487 50.3\" stroke-linecap=\"round\" stroke-linejoin=\"round\" fill=\"#D8D8D8\"/><path d=\"M105.64 42.164h15.282\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M56.467 54.696l25.436 6.53\"/></g>",
    "attrs": {
      "width": "161",
      "height": "131",
      "viewBox": "0 0 161 131",
      "xmlns": "http://www.w3.org/2000/svg",
      "class": "megaphone-icon"
    }
  };
  _exports.default = _default;
});