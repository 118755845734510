define("ember-svg-jar/inlined/progress-bar-current", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>In Progress</title><defs><filter x=\"-187.5%\" y=\"-187.5%\" width=\"475%\" height=\"475%\" filterUnits=\"objectBoundingBox\" id=\"a\"><feGaussianBlur stdDeviation=\"10\" in=\"SourceGraphic\"/></filter></defs><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M12 12a8 8 0 110 16 8 8 0 010-16z\" fill=\"#2B6CDE\" filter=\"url(#a)\" transform=\"translate(18 10)\"/><path d=\"M30 10c6.627 0 12 5.372 12 12s-5.373 12-12 12-12-5.372-12-12 5.373-12 12-12z\" fill=\"#FFF\"/><path d=\"M29.997 26A4 4 0 0130 18a4 4 0 110 8h-.003z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "viewBox": "0 0 60 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});