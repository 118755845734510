define("ember-svg-jar/inlined/power", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.375 0L0 23h14.375L2.872 46l28.75-28.75H17.25L34.497 0z\" fill=\"#F8AC21\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "35",
      "height": "46",
      "viewBox": "0 0 35 46",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});