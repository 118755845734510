define("ember-svg-jar/inlined/chevronRounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.268-1.406L5.406 4.43l4.862 5.837\" stroke-width=\"2.5\" stroke=\"#2B6CDE\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "9",
      "height": "15",
      "viewBox": "0 0 15 9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});