define("ember-svg-jar/inlined/doc-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#doc-pdf_svg__clip0_219_4362)\"><rect x=\".5\" width=\"40\" height=\"40\" rx=\"20\" fill=\"#F1EEF8\"/><path d=\"M16.003 21.002a.5.5 0 00-.5.5v3a.5.5 0 101 0v-.5H17a1.5 1.5 0 100-3h-.997zm.997 2h-.497v-1H17a.5.5 0 110 1zm6.498-1.5a.5.5 0 01.5-.5h1.505a.5.5 0 110 1h-1.006l-.001 1.002h1.007a.5.5 0 010 1h-1.007l.002.497a.5.5 0 01-1 .002l-.003-.998v-.002l.003-2.002zm-3.498-.5a.5.5 0 00-.5.5v3a.5.5 0 00.5.5h.498a2 2 0 000-4H20zm.5 3v-2a1 1 0 010 2zm8 3.998v-1.164c.591-.281 1-.884 1-1.582V20.75c0-.698-.409-1.3-1-1.582v-1.34a2 2 0 00-.586-1.414l-5.829-5.828c-.015-.015-.032-.028-.049-.04a.617.617 0 01-.036-.03 2.072 2.072 0 00-.219-.18c-.025-.017-.053-.03-.08-.044a1.112 1.112 0 01-.098-.053c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 00-.624-.138.832.832 0 01-.059-.007.605.605 0 00-.082-.007H14.5a2 2 0 00-2 2v7.168c-.591.281-1 .884-1 1.582v4.504c0 .698.409 1.3 1 1.582V28a2 2 0 002 2h12a2 2 0 002-2zm-2 .5h-12a.5.5 0 01-.5-.5v-.996h13V28a.5.5 0 01-.5.5zM27 18v1H14v-7a.5.5 0 01.5-.5h6V16a2 2 0 002 2H27zm-1.122-1.5H22.5a.5.5 0 01-.5-.5v-3.379l3.878 3.879zm-12.628 4h14.5a.25.25 0 01.25.25v4.504a.25.25 0 01-.25.25h-14.5a.25.25 0 01-.25-.25V20.75a.25.25 0 01.25-.25z\" fill=\"#212121\"/></g><defs><clipPath id=\"doc-pdf_svg__clip0_219_4362\"><rect x=\".5\" width=\"40\" height=\"40\" rx=\"20\" fill=\"#fff\"/></clipPath></defs>",
    "attrs": {
      "width": "41",
      "height": "40",
      "viewBox": "0 0 41 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});