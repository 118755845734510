define("ember-lifeline-decorators/schedule", ["exports", "@ember-decorators/utils/decorator", "@ember/debug", "ember-lifeline"], function (_exports, _decorator, _debug, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Scheduling in the `afterRender` queue is bad for performance.
   *
   * @see https://github.com/ember-lifeline/ember-lifeline/blob/9842853ae600c0652531962f679a0900ba289eec/addon/run-task.ts#L128-L131
   */
  var _default = (0, _decorator.decoratorWithRequiredParams)(function schedule(target, _key, desc, _ref) {
    let [queue] = _ref;
    (false && !(typeof desc.value === 'function') && (0, _debug.assert)("The '@schedule' decorator can only be used on methods.", typeof desc.value === 'function'));
    return {
      ...desc,
      value() {
        for (var _len = arguments.length, args = new Array(_len), _key2 = 0; _key2 < _len; _key2++) {
          args[_key2] = arguments[_key2];
        }
        return (0, _emberLifeline.scheduleTask)(this, queue, desc.value.bind(this, ...args), ...args);
      }
    };
  });
  _exports.default = _default;
});