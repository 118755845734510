define("ember-svg-jar/inlined/list-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path opacity=\".3\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M.917 11C.917 16.57 5.43 21.083 11 21.083S21.083 16.57 21.083 11 16.57.917 11 .917.917 5.43.917 11z\" fill=\"#CFD3DA\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.725 7.73a1.025 1.025 0 011.474-.152c.448.378.514 1.06.148 1.523l-4.822 6.085a1.026 1.026 0 01-1.558.073l-2.25-2.366a1.107 1.107 0 01.013-1.53 1.024 1.024 0 011.482.014l1.431 1.505 4.082-5.152z\" fill=\"#575970\"/>",
    "attrs": {
      "width": "22",
      "height": "22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});