define("ember-svg-jar/inlined/gkvAppointmentIcon3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#477CD9\" fill-rule=\"evenodd\"><text stroke=\"#477CD9\" stroke-width=\"2\" font-family=\"simple-line-icons\" font-size=\"24\"><tspan x=\"38\" y=\"51\">&#xe081;</tspan></text><path d=\"M3.04 41.953V45h33.44v3H3.04c-.823 0-1.536-.297-2.138-.89C.301 46.515 0 45.812 0 45v-4.5c0-.844.507-1.555 1.52-2.133l1.52-.867 13.348-6.234a11.567 11.567 0 01-2.708-3.024 19.816 19.816 0 01-1.781-3.633c-.46-1.25-.784-2.46-.974-3.632-.19-1.172-.285-2.164-.285-2.977v-7.5c0-1.25.396-2.5 1.188-3.75.791-1.25 1.836-2.375 3.135-3.375 1.298-1 2.762-1.812 4.393-2.438A13.77 13.77 0 0124.32 0c1.678 0 3.333.312 4.964.938 1.63.625 3.087 1.437 4.37 2.437 1.282 1 2.327 2.125 3.135 3.375C37.596 8 38 9.25 38 10.5V18c0 .906-.095 1.953-.285 3.14a20.185 20.185 0 01-.95 3.633 17.761 17.761 0 01-1.758 3.563 12.322 12.322 0 01-2.66 2.977l4.038 1.828-.76 3-4.56-2.11a3.069 3.069 0 01-1.188-.96 2.914 2.914 0 01-.57-1.43 3.404 3.404 0 01.238-1.5c.19-.47.49-.875.902-1.22 1.647-1.25 2.81-3.023 3.492-5.32.68-2.296 1.021-4.163 1.021-5.601v-7.5c0-.813-.317-1.656-.95-2.531-.633-.875-1.449-1.68-2.446-2.414a14.319 14.319 0 00-3.396-1.828A10.695 10.695 0 0024.32 3c-1.298 0-2.58.242-3.848.727a14.295 14.295 0 00-3.42 1.851c-1.013.75-1.828 1.563-2.446 2.438-.617.875-.926 1.703-.926 2.484V18c0 1.469.372 3.344 1.116 5.625.744 2.281 1.908 4.047 3.492 5.297.411.312.72.71.926 1.195.206.485.277.992.213 1.524-.063.5-.253.96-.57 1.382a3.069 3.069 0 01-1.187.961L4.322 40.22c-.19.062-.45.218-.783.468s-.499.672-.499 1.266z\"/></g>",
    "attrs": {
      "width": "63",
      "height": "53",
      "viewBox": "0 0 63 53",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});