define("ember-svg-jar/inlined/documents-fan", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.365 15.132l6.08-2.966a.25.25 0 00.115-.334L8.737 6.044 5.558 4.918l-4.31 1.735a.25.25 0 00-.13.341l3.913 8.023a.25.25 0 00.334.115z\" fill=\"#fff\"/><path d=\"M8.737 6.044L6.66 7.058l-1.102-2.14m3.18 1.126l2.822 5.788a.25.25 0 01-.115.334l-6.08 2.966a.25.25 0 01-.334-.115L1.118 6.994a.25.25 0 01.13-.341l4.31-1.735m3.18 1.126l-3.18-1.126\" stroke=\"#575970\"/><path d=\"M7.316 14.743l8.125-2.178a.25.25 0 00.164-.344l-3.92-8.713-2.007-1.006-5.352 1.01a.25.25 0 00-.195.31L7.01 14.566a.25.25 0 00.306.177z\" fill=\"#fff\" stroke=\"#575970\"/><path d=\"M8.567 15.5h10.3a.35.35 0 00.35-.35V5L16 1H8.567a.35.35 0 00-.35.35v13.8c0 .193.156.35.35.35z\" fill=\"#fff\"/><path d=\"M19.217 5l-3.4.457a.25.25 0 01-.282-.275L16 1m3.217 4v10.15a.35.35 0 01-.35.35h-10.3a.35.35 0 01-.35-.35V1.35a.35.35 0 01.35-.35H16m3.217 4L16 1\" stroke=\"#575970\" stroke-width=\"1.25\"/>",
    "attrs": {
      "width": "20",
      "height": "17",
      "viewBox": "0 0 20 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});