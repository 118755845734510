define("ember-svg-jar/inlined/document-needs-customer-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M49 18.328V54.52c0 1.856-1.633 3.48-3.5 3.48h-42C1.633 58 0 56.376 0 54.52V3.48C0 1.624 1.633 0 3.5 0h27.067c.933 0 1.866.464 2.566.928l14.934 14.847c.466.928.933 1.625.933 2.553zM32.667 3.944V15.08c0 .696.466 1.16 1.166 1.16h11.2L32.667 3.944zm14 14.616H33.833c-1.866 0-3.5-1.624-3.5-3.48V2.32H3.5c-.7 0-1.167.464-1.167 1.16v51.04c0 .696.467 1.16 1.167 1.16h42c.7 0 1.167-.464 1.167-1.16V18.56z\" fill=\"#BDC0CF\"/><rect x=\"7.538\" y=\"6.997\" width=\"9.692\" height=\"9.688\" rx=\"4.844\" fill=\"#BDC0CF\"/><rect x=\"15.943\" y=\"32.795\" width=\"6.377\" height=\"2.572\" rx=\"1.286\" fill=\"#BDC0CF\"/><rect x=\"8.291\" y=\"32.795\" width=\"6.377\" height=\"2.572\" rx=\"1.286\" fill=\"#0439D7\"/><rect x=\"23.596\" y=\"32.795\" width=\"5.102\" height=\"2.572\" rx=\"1.286\" fill=\"#0439D7\"/><rect x=\"27.422\" y=\"39.225\" width=\"8.928\" height=\"2.572\" rx=\"1.286\" fill=\"#BDC0CF\"/><rect x=\"8.291\" y=\"39.225\" width=\"8.928\" height=\"2.572\" rx=\"1.286\" fill=\"#BDC0CF\"/><rect x=\"18.494\" y=\"39.225\" width=\"7.653\" height=\"2.572\" rx=\"1.286\" fill=\"#D51E50\"/><rect x=\"8.291\" y=\"45.655\" width=\"3.826\" height=\"2.572\" rx=\"1.286\" fill=\"#0439D7\"/><rect x=\"13.392\" y=\"45.655\" width=\"14.03\" height=\"2.572\" rx=\"1.286\" fill=\"#BDC0CF\"/><rect x=\"28.698\" y=\"45.655\" width=\"10.204\" height=\"2.572\" rx=\"1.286\" fill=\"#D51E50\"/>",
    "attrs": {
      "width": "49",
      "height": "58",
      "viewBox": "0 0 49 58",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});