define("ember-svg-jar/inlined/gkvAppointmentIcon2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M37 4.207C37 2.988 36.009 2 34.784 2H4.216A2.209 2.209 0 002 4.207v21.476c0 1.22.988 2.208 2.212 2.208h4.112V35l6.89-7.14 19.577.028A2.201 2.201 0 0037 25.683V4.207z\" stroke=\"#477CD9\" stroke-width=\"2.3\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><text font-family=\"OpenSans-Bold, Open Sans\" font-size=\"20\" font-weight=\"bold\" fill=\"#477CD9\" transform=\"translate(2 1)\"><tspan x=\"13\" y=\"21\">?</tspan></text><g><path d=\"M31 12.217c0-1.225.989-2.217 2.208-2.217h35.584c1.22 0 2.208.992 2.208 2.217v25.38c0 1.224-.99 2.217-2.208 2.217h-5.02V48L55.9 39.779l-22.69.032A2.21 2.21 0 0131 37.597v-25.38z\" stroke=\"#477CD9\" stroke-width=\"2.3\" fill=\"#477CD9\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><text font-family=\"OpenSans-Bold, Open Sans\" font-size=\"22\" font-weight=\"bold\" fill=\"#FFF\" transform=\"translate(31 8)\"><tspan x=\"17\" y=\"24\">!</tspan></text></g></g>",
    "attrs": {
      "width": "73",
      "height": "50",
      "viewBox": "0 0 73 50",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});