define("ember-svg-jar/inlined/fallback-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#fallback-category_svg__clip0)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M6 7h7a.5.5 0 010 1H7v9.944c.329 1.445 1.15 3.62 2.55 5.48 1.413 1.88 3.378 3.391 5.986 3.577a.5.5 0 11-.072.998c-2.992-.214-5.194-1.953-6.714-3.973-1.517-2.017-2.392-4.361-2.738-5.918L6 18.055V7z\"/><path d=\"M2 3h27v12c0 .545-.034 1.439-.135 2.368-.098.916-.266 1.911-.55 2.641-.806 2.305-2.2 5.109-4.216 7.432-2.014 2.321-4.68 4.194-8.022 4.554-.66.133-1.653.033-2.773-.295-1.152-.339-2.495-.936-3.852-1.853-2.717-1.838-5.492-4.962-6.898-9.867-.166-.527-.288-1.48-.376-2.405-.09-.95-.15-1.934-.178-2.553V3zm1 1v10.989c.027.606.085 1.567.173 2.49.09.95.207 1.799.336 2.205l.003.007.002.007c1.336 4.666 3.96 7.604 6.498 9.32 1.272.86 2.521 1.413 3.574 1.722 1.071.314 1.887.362 2.31.271l.025-.005.027-.003c3.023-.319 5.482-2.012 7.396-4.218 1.915-2.207 3.255-4.895 4.029-7.114l.003-.01.004-.008c.236-.601.394-1.49.491-2.392.096-.89.129-1.749.129-2.261V4H3z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
  _exports.default = _default;
});