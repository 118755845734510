define("ember-svg-jar/inlined/attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.5 3a3 3 0 00-3 3v9.75a1.5 1.5 0 003 0V6A.75.75 0 0115 6v9.75a3 3 0 11-6 0V6a4.5 4.5 0 119 0v10.5a6 6 0 01-12 0V9a.75.75 0 011.5 0v7.5a4.5 4.5 0 109 0V6a3 3 0 00-3-3z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});