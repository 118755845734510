define("ember-file-upload/helpers/file-queue", ["exports", "@ember/component/helper", "@ember/destroyable", "@ember/service", "ember-file-upload/services/file-queue"], function (_exports, _helper, _destroyable, _service, _fileQueue) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  /**
   * `file-queue` helper is one of the core primitives of ember-file-upload.
   *
   * @example
   *
   * using the default queue (no `name` property)
   *
   * ```hbs
   * {{#with (file-queue) as |queue|}}
   *   {{queue.progress}}%
   * {{/with}}
   * ```
   *
   * @example
   *
   * named queue:
   *
   * ```hbs
   * {{#with (file-queue name="photos") as |queue|}}
   *   {{queue.progress}}%
   * {{/with}}
   * ```
   */
  let FileQueueHelper = (_class = class FileQueueHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "fileQueue", _descriptor, this);
    }
    compute(_positional, args) {
      var _args$name;
      this.args = args;
      const queue = this.fileQueue.findOrCreate((_args$name = args.name) !== null && _args$name !== void 0 ? _args$name : _fileQueue.DEFAULT_QUEUE);
      queue.addListener(this);
      (0, _destroyable.registerDestructor)(this, () => {
        queue.removeListener(this);
      });
      return queue;
    }
    fileAdded(file) {
      var _this$args$fileAdded, _this$args;
      (_this$args$fileAdded = (_this$args = this.args).fileAdded) === null || _this$args$fileAdded === void 0 ? void 0 : _this$args$fileAdded.call(_this$args, file);
    }
    fileRemoved(file) {
      var _this$args$fileRemove, _this$args2;
      (_this$args$fileRemove = (_this$args2 = this.args).fileRemoved) === null || _this$args$fileRemove === void 0 ? void 0 : _this$args$fileRemove.call(_this$args2, file);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fileQueue", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FileQueueHelper;
});