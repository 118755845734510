define("ember-svg-jar/inlined/icon-messenger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M5.27 18.638l4.889-2.433a.562.562 0 01.25-.059h5.802a1.68 1.68 0 001.678-1.684v-8.32c0-.93-.754-1.684-1.685-1.684H2.81c-.926 0-1.685.757-1.685 1.684v8.32c0 .93.752 1.684 1.68 1.684h1.902c.31 0 .563.252.563.563v1.929zM23.204 0c1.547 0 2.81 1.26 2.81 2.809v8.32a2.807 2.807 0 01-2.805 2.809h-1.34v2.274a.563.563 0 01-.829.496l-1.985-1.067a.56.56 0 01-.214-.204 2.804 2.804 0 01-2.63 1.834h-5.67L4.959 20.05a.563.563 0 01-.813-.503V17.27h-1.34A2.807 2.807 0 010 14.462v-8.32a2.814 2.814 0 012.81-2.809h4.222A.562.562 0 017 3.147v-.337A2.81 2.81 0 019.81 0zm0 1.125H9.81c-.93 0-1.685.754-1.685 1.684v.337c0 .066-.011.13-.032.188h8.11a2.81 2.81 0 012.81 2.808v8.32c0 .075-.002.149-.008.222a.555.555 0 01.583-.034l1.156.622v-1.897c0-.31.252-.562.563-.562h1.902c.928 0 1.68-.754 1.68-1.684v-8.32c0-.927-.759-1.684-1.685-1.684zm-17.7 10.27c.702 0 1.277-.562 1.277-1.25 0-.687-.575-1.25-1.278-1.25-.703 0-1.278.563-1.278 1.25 0 .688.575 1.25 1.278 1.25zm8.518 0c.703 0 1.278-.562 1.278-1.25 0-.687-.575-1.25-1.278-1.25-.703 0-1.278.563-1.278 1.25 0 .688.575 1.25 1.278 1.25zm-4.26 0c.703 0 1.278-.562 1.278-1.25 0-.687-.575-1.25-1.277-1.25-.703 0-1.278.563-1.278 1.25 0 .688.575 1.25 1.277 1.25z\"/>",
    "attrs": {
      "width": "26",
      "height": "20",
      "viewBox": "0 0 26 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});