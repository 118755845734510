define("ember-svg-jar/inlined/primocoLogo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g font-size=\"24.105\" font-family=\"Helvetica\" fill=\"#47566F\"><text transform=\"translate(25.371 60.855)\"><tspan x=\"0\" y=\"24\">priotecs</tspan></text><text transform=\"translate(25.371 60.855)\"><tspan x=\"86.3\" y=\"24\">money</tspan></text></g><g font-size=\"24.268\" font-family=\"Helvetica\" fill=\"#47566F\"><text transform=\"translate(186.557 60.855)\"><tspan x=\"0\" y=\"24\">c</tspan></text><text transform=\"translate(186.557 60.855)\"><tspan x=\"29.3\" y=\"24\">n</tspan></text><text transform=\"translate(186.557 60.855)\"><tspan x=\"42.5\" y=\"24\">t</tspan></text><text transform=\"translate(186.557 60.855)\"><tspan x=\"50\" y=\"24\">r</tspan></text><text transform=\"translate(186.557 60.855)\"><tspan x=\"57.8\" y=\"24\">ol</tspan></text></g><g transform=\"translate(200 70)\" fill-rule=\"nonzero\" fill=\"#66C4F0\"><path d=\"M8.5.9C4.2.9.7 4.4.7 8.7s3.5 7.8 7.8 7.8 7.8-3.5 7.8-7.8S12.8.9 8.5.9zm0 14.6c-3.7 0-6.8-3-6.8-6.8 0-3.7 3-6.8 6.8-6.8 3.7 0 6.8 3 6.8 6.8 0 3.7-3.1 6.8-6.8 6.8z\"/><circle cx=\"8.5\" cy=\"8.7\" r=\"4.8\"/></g><text fill=\"#47566F\" font-family=\"Helvetica\" font-size=\"73.601\" transform=\"translate(-5 -20)\"><tspan x=\"199.391\" y=\"72.224\">co</tspan></text><g fill-rule=\"nonzero\" fill=\"#66C4F0\"><path d=\"M166.8 5.6c-15.4 0-27.9 12.5-27.9 27.9 0 15.4 12.5 27.9 27.9 27.9 15.4 0 27.9-12.5 27.9-27.9 0-15.4-12.5-27.9-27.9-27.9m0 52.2c-13.5 0-24.4-10.9-24.4-24.4 0-13.5 10.9-24.4 24.4-24.4 13.5 0 24.4 10.9 24.4 24.4 0 13.5-11 24.4-24.4 24.4\"/><path d=\"M149.5 33.5c0-9.5 7.7-17.3 17.3-17.3 9.5 0 17.3 7.7 17.3 17.3 0 9.5-7.7 17.3-17.3 17.3-9.6 0-17.3-7.8-17.3-17.3\"/></g><g font-size=\"73.601\" font-family=\"Helvetica\" fill=\"#47566F\"><text transform=\"translate(-4 -19.776)\"><tspan x=\"0\" y=\"72\">pri</tspan></text><text transform=\"translate(-4 -19.776)\"><tspan x=\"79.8\" y=\"72\">m</tspan></text></g></g>",
    "attrs": {
      "width": "269",
      "height": "91",
      "viewBox": "0 0 269 91",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});