define("ember-svg-jar/inlined/shieldStroke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.732 1.954C8.33 4.091 5.489 5.06 1.782 5.06c0 5.564-1.26 13.533 8.95 16.77 10.21-3.237 8.951-11.206 8.951-16.77-3.708 0-6.549-.968-8.951-3.105z\" stroke=\"#2B6CDE\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "21",
      "height": "23",
      "viewBox": "0 0 21 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});