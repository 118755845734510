define("ember-svg-jar/inlined/gkvAppointmentIcon1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g stroke=\"#477CD9\" fill=\"none\" fill-rule=\"evenodd\"><g stroke-linecap=\"round\" stroke-width=\"2.3\"><path d=\"M28 47H5.868C3.732 47 2 43.203 2 43.203V31m0 3V5.63C2 3.625 3.708 2 5.803 2H34a3 3 0 013 3v29\"/><path d=\"M14 21.405h8.775M14 13.405h3.778M14 28.405h12.768M14 35.25h9\" stroke-linejoin=\"round\"/></g><path d=\"M51.903 57.688a.63.63 0 01.093.41.817.817 0 01-.164.41.703.703 0 01-.327.246.601.601 0 01-.42.012l-3.766-.844-1.286 3.586a.676.676 0 01-.245.34.79.79 0 01-.386.152h-.07a.735.735 0 01-.386-.105.697.697 0 01-.27-.293l-3.904-7.149A5.896 5.896 0 0140 54.5a7.57 7.57 0 01-.842-.047l-3.835 7.149a.697.697 0 01-.269.293.735.735 0 01-.386.105h-.046a.814.814 0 01-.41-.152.676.676 0 01-.245-.34l-1.286-3.586-3.765.844a.656.656 0 01-.748-.281.692.692 0 01-.164-.387.63.63 0 01.093-.41l4.397-8.04a8.144 8.144 0 01-.725-3.398c0-1.14.214-2.21.643-3.21a8.318 8.318 0 011.765-2.626 8.297 8.297 0 012.62-1.77A8.012 8.012 0 0140 38c1.138 0 2.206.215 3.204.645a8.16 8.16 0 012.607 1.77 8.588 8.588 0 011.765 2.624c.437 1 .655 2.07.655 3.211a7.83 7.83 0 01-.187 1.723 9.4 9.4 0 01-.514 1.605l4.373 8.11zM34.35 59L37 53.91a7.767 7.767 0 01-2.241-1.137A7.76 7.76 0 0133.003 51L30 56.614 32.628 56a.633.633 0 01.497.068c.154.091.268.22.342.387L34.35 59zM33 47c0 .956.17 1.86.509 2.71a6.873 6.873 0 003.47 3.73 5.821 5.821 0 002.532.56c.89 0 1.73-.186 2.521-.559a6.691 6.691 0 002.069-1.507 7.076 7.076 0 001.39-2.224A7.25 7.25 0 0046 47c0-.956-.17-1.86-.509-2.71a7.076 7.076 0 00-1.39-2.224 6.691 6.691 0 00-2.069-1.507 5.844 5.844 0 00-2.52-.559c-.905 0-1.75.186-2.533.559a6.873 6.873 0 00-3.47 3.73A7.25 7.25 0 0033 47zm13.397 9.003L49 56.61 46.047 51a8.083 8.083 0 01-1.783 1.803A7.624 7.624 0 0142 53.953L44.69 59l.876-2.546a.815.815 0 01.339-.384.626.626 0 01.492-.067z\" stroke-width=\".5\" fill=\"#477CD9\"/></g>",
    "attrs": {
      "width": "53",
      "height": "63",
      "viewBox": "0 0 53 63",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});