define("ember-svg-jar/inlined/receipt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.8 8A3.2 3.2 0 018 4.8h11.2A3.2 3.2 0 0122.4 8v11.2h4.8v3.2a4.8 4.8 0 01-4.8 4.8H9.6a4.8 4.8 0 01-4.8-4.8V8zm17.6 12.8v4.8a3.2 3.2 0 003.2-3.2v-1.6h-3.2zm-1.6 4.8V8a1.6 1.6 0 00-1.6-1.6H8A1.6 1.6 0 006.4 8v14.4a3.2 3.2 0 003.2 3.2h11.2zM9.6 10.4a.8.8 0 01.8-.8h6.4a.8.8 0 010 1.6h-6.4a.8.8 0 01-.8-.8zm0 4.8a.8.8 0 01.8-.8h6.4a.8.8 0 010 1.6h-6.4a.8.8 0 01-.8-.8zm0 4.8a.8.8 0 01.8-.8h3.2a.8.8 0 010 1.6h-3.2a.8.8 0 01-.8-.8z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});