define("ember-svg-jar/inlined/phoneVerification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><ellipse class=\"svg-brand-primary-fill\" cx=\"50.8\" cy=\"51\" rx=\"50.8\" ry=\"51\"/><g stroke-width=\"2.472\" stroke=\"#FFF\"><g transform=\"translate(31 16)\"><path d=\"M0 1.995C0 .893.892 0 1.995 0h33.603c1.102 0 1.995.895 1.995 1.995v55.011H0V1.996zM0 57.037h37.593v10.966A1.995 1.995 0 0135.598 70H1.995A1.994 1.994 0 010 68.003V57.037z\" class=\"svg-brand-primary-fill\"/><circle fill=\"#FFF\" cx=\"19.444\" cy=\"63.519\" r=\"2.593\"/></g><path d=\"M56.926 55.387h20.74\" stroke-linecap=\"square\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M78.963 51v10.37l10.37-5.185z\"/><path d=\"M78.963 36.137h-20.74\" stroke-linecap=\"square\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M56.926 31.556v10.37l-10.37-5.185z\"/></g></g>",
    "attrs": {
      "width": "102",
      "height": "102",
      "viewBox": "0 0 102 102",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});